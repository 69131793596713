import { LinkButtonYellow } from '/components/buildingBlocks/Link'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'
import { ContainerLight } from '/components/home/Container'
import { PageLayoutPitchCases } from '/components/pageOnly/PageLayout'
import { routes } from '/routes'

import image from '/components/home/assets/CoolRabbit.png'

import styles from './Password.css'

export function Password({ password, onAuthorizeChange }) {
  const [passwordInput, setPasswordInput] = React.useState('')
  const [error, setError] = React.useState('')

  return (
    <PageLayoutPitchCases>
      <ContainerLight>
        <div className={styles.component}>
          <div className={styles.formWrapper}>
            <form onSubmit={handlePasswordSubmit} className={styles.form}>
              <h2>Om de cases te zien die we hebben samengesteld voor deze pitch, <br /> heb je een wachtwoord nodig:</h2>
              <div className={cx(styles.inputWrapper, error && styles.inputError)}>
                <label className={styles.label} htmlFor='input'>Wachtwoord*</label>
                <input
                  id='input'
                  type="password"
                  placeholder='Wachtwoord*'
                  value={passwordInput}
                  onChange={(e) => {
                    setPasswordInput(e.target.value)
                  }}
                  className={cx(styles.input, error && styles.inputError)}
                />
                {error && <span className={styles.errorMessage}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM9 5V11H11V5H9ZM10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14C11 13.7167 10.9042 13.4792 10.7125 13.2875C10.5208 13.0958 10.2833 13 10 13C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15Z" fill="black" />
                  </svg>
                  Het wachtwoord kun je vinden in onze pitch documentatie.</span>}
              </div>
              <LinkButtonYellow
                onClick={handlePasswordSubmit}
                to={routes.pitchCases.path}
                layoutClassName={styles.buttonLayout}
              >
                Ga verder
              </LinkButtonYellow>
            </form>
          </div>
          <div className={styles.imageWrapper}>
            <ImageObjectFit
              src={image}
              alt='Foto van een cool konijn'
              layoutClassName={styles.imageLayout}
            />
          </div>
        </div>
      </ContainerLight>
    </PageLayoutPitchCases>
  )

  function handlePasswordSubmit(e) {
    e.preventDefault()
    if (passwordInput === password) {
      onAuthorizeChange()
    } else {
      setError('true')
    }
  }
}

