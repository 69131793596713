import { ButtonCircle } from '/components/buildingBlocks/ButtonCircle'
import { IconRightArrow } from '/components/buildingBlocks/Icon'
import { Image } from '/components/Image'
import { LinkBlock } from '/components/buildingBlocks/Link'
import { useSanityClient } from '/machinery/SanityClient'
import { routes, determineDocumentPath } from '/routes'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'
import { animated, useSpring, config } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'

import styles from './NextItem.css'

export function NextItem({ previousItem, nextItem, type }) {
  const items = [previousItem, nextItem].filter(Boolean)
  const isMd = useMediaQuery(mediaStyles.viewportMd)
  const { ref: scrollProgressionRef, progression } = useAnimatedScrollProgression()

  return (
    <div className={styles.component} ref={scrollProgressionRef}>
      <div className={styles.headingWrapper}>
        <animated.h2
          className={styles.heading}
          style={{ transform: isMd &&  progression.to(x =>
            `translateX(${lerp({ start: 50, end: -10, input: x })}%)`)
          }}
        >
          Blijf Ontdekken
        </animated.h2>
      </div>
      {isMd
        ? <NextItemDesktop {...{ items, type }} />
        : <NextItemMobile {...{ items, type }} />
      }
    </div>
  )
}

function NextItemDesktop({ type, items }) {
  const [currentItem, setCurrentItem] = React.useState(null)

  const textProps = useSpring({
    opacity: currentItem ? 1 : 0,
    config: { tension: 120, friction: 14 }
  })

  return (
    <div className={styles.componentDesktop}>
      <div className={styles.layout}>
        {items.map((x, i) => {
          const link = determineDocumentPath({ document: { _type: type, slug: { current: x.slug.current } }, routes })
          return (
            <div
              key={i}
              onMouseOver={() => setCurrentItem(x)}
              onMouseLeave={() => setCurrentItem(null)}
              className={cx(styles.itemDesktop, (currentItem && currentItem !== x) && styles.notActiveLayout, (currentItem && currentItem === x) && styles.isActiveLayout)}
            >
              <LinkBlock to={link} layoutClassName={styles.linkLayout}>
                <NextItemImage
                  image={x.header.headerImage}
                  layoutClassName={cx(styles.imageLayout, (currentItem && currentItem !== x) && styles.notActiveLayout)}
                />
              </LinkBlock>
              {currentItem === x && (
                <animated.div style={textProps} className={cx(styles.text, styles.textDesktop)}>
                  <ButtonCircle layoutClassName={styles.buttonLayout}>
                    <IconRightArrow />
                  </ButtonCircle>
                  {currentItem?.header?.client && (
                    <div className={styles.client}>{currentItem.header.client}</div>
                  )}
                  <h3 className={styles.title}>{currentItem.title}</h3>
                </animated.div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function NextItemMobile({ type, items }) {
  return (
    <div className={styles.componentMobile}>
      <div className={styles.layout}>
        {items.map((x, i) => {
          const link = determineDocumentPath({ document: { _type: type, slug: { current: x.slug.current } }, routes })
          return (
            <div className={styles.itemMobile} key={i}>
              <div className={styles.content}>
                <ButtonCircle layoutClassName={styles.buttonLayout}>
                  <IconRightArrow />
                </ButtonCircle>
                <LinkBlock to={link} layoutClassName={styles.linkLayout}>
                  <div className={styles.text}>
                    <div className={styles.client}>{x.header.client}</div>
                    <h3 className={styles.title}>{x.title}</h3>
                  </div>
                </LinkBlock>
              </div>
              <NextItemImage
                image={x.header.headerImage}
                layoutClassName={styles.imageLayout}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

function NextItemImage({ image, layoutClassName }) {
  const { imageBuilder } = useSanityClient()

  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.coverImageLayout} />
    </div>
  )
}

function useAnimatedScrollProgression() {
  const [{ progression }, spring] = useSpring(() => ({ progression: 0, config: config.molasses }))

  const ref = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.top(0) },
    onChange(progression) {
      spring.start({ progression: easeOutQuint(progression) })
    }
  })

  return { ref, progression }
}

function easeOutQuint(x) {
  return 1 - Math.cos((x * Math.PI) / 2)
}
