import { routes, determineDocumentPath } from '/routes'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { LinkBlock } from '/components/buildingBlocks/Link'
import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import { ContainerLight } from '/components/home/Container'
import { Section } from '/components/home/pageOnly/Section'
import { PageLayout } from '/components/articles/pageOnly/PageLayout'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { Header } from '/components/articles/pageOnly/Header'
import { IconRightArrow } from '/components/buildingBlocks/Icon'
import { ButtonCircleDark } from '/components/buildingBlocks/ButtonCircle'
import { getPreviewForOverviewType } from '/machinery/groqSnippets'
import groq from 'groq'

import styles from './Articles.css'

const query = groq`
  ${getPreviewForOverviewType('article')} | order(documentOrder) {
    "slug": slug.current,
    "id": _id,
    "image": header.headerImage {..., asset->},
    title,
    contributors[]-> {
      _id,
      firstName,
      role,
    }
  }
`

Articles.meta = {
  title: 'Inspiratie',
  description: '',
  async fetchData(sanityClient, { isPreview = false }) {
    const articles = await sanityClient.fetch(query, { isPreview })
    return {
      data: { articles },
      status: 200
    }
  }
}

export function Articles() {
  useSetDocumentTitle(Articles.meta.title)
  const { isLoading, isError, data } = usePageRouteData()
  const { articles = [] } = data || {}

  return (
    <PageLayout>
      <Header
        title='Inspiratie'
        intro='Elke dag verkennen we de veranderende wereld om ons heen. Wat we van die ontdekkingsdrift leren? We delen het hier.'
      />
      <ContainerLight layoutClassName={styles.container}>
        <Section backgroundColor='white' color='black'>
          <CenteredContainer width='xl'>
            {isLoading && <p>Laden...</p>}
            {isError && <p>Er is een fout opgetreden</p>}
            {data && articles.length === 0 && <p>Geen resultaten</p>}
            {data && (
              <div className={styles.articles}>
                {articles.map(({ id, slug, title, client, image, contributors }) => (
                  <div key={id} className={styles.article} style={{ gridRowStart: getGridRowStart(image) }}>
                    <Card {...{ id, slug, title, client, image, articles, contributors }} />
                  </div>
                ))}
              </div>
            )}
          </CenteredContainer>
        </Section>
      </ContainerLight>
    </PageLayout>
  )

  function getGridRowStart(image) {
    const aspectRatio = image?.asset?.metadata?.dimensions?.aspectRatio ?? 1

    if (!image) return 'span 1'
    if (aspectRatio < (4 / 5)) return 'span 5'
    if (aspectRatio > (5 / 4)) return 'span 3'
    return 'span 4'
  }
}

function Card({ image, title, slug, contributors }) {
  const { imageBuilder } = useSanityClient()
  const link = determineDocumentPath({ document: { _type: 'article', slug: { current: slug } }, routes })
  const hasImage = image?.asset?.url

  return (
    <LinkBlock to={link} layoutClassName={styles.componentCard}>
      <div className={styles.coverImage}>
        {hasImage && (
          <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.image} />
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.titleAndInfo}>
          <Title {...{ title }} layoutClassName={styles.title} />
          {contributors && (
            <div className={styles.info}>
              {contributors && (
                <div className={styles.info}>
                  {contributors.map((x, index) => {
                    return (
                      <span key={x._id}>
                        {x.firstName}, {x.role} {index < contributors.length - 1 ? '• ' : ''}
                      </span>
                    )
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <ButtonCircleDark layoutClassName={styles.circleButton}>
          <IconRightArrow />
        </ButtonCircleDark>
      </div>
    </LinkBlock>
  )
}

function Title({ title, layoutClassName }) {
  return (
    <div className={cx(styles.componentTitle, layoutClassName)}>
      {title}
    </div>
  )
}
