import { useSpring, animated, config } from 'react-spring'
import { IconContainer } from '/components/buildingBlocks/IconContainer'
import { useHasMouse } from '/machinery/HasMouse'
import styles from './FollowCursor.css'

export function FollowCursor({ children, Cursor, onCursorMove = undefined }) {
  const { hasMouse } = useHasMouse()
  const componentRef = React.useRef()
  const [ { x, y }, setCursorPosition ] = React.useState({ x: 0, y: 0 })
  const [ isFollowing, setIsFollowing ] = React.useState(false)

  React.useEffect(
    () => onCursorMove && onCursorMove({ x, y }),
    [x, y, onCursorMove]
  )

  const mouseMoveHandler = React.useCallback(handleMouseMove, [hasMouse])
  const mouseLeaveHandler = React.useCallback(handleMouseLeave, [hasMouse])
  const mouseEnterHandler = React.useCallback(handleMouseEnter, [hasMouse])

  return (
    <div
      ref={componentRef}
      className={styles.component}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      onMouseMove={isFollowing ? mouseMoveHandler : undefined}
    >
      {children}
      <CursorPosition {...{ x, y, isFollowing }} layoutClassName={styles.cursorPosition}>
        <div className={styles.cursor}><Cursor {...{ isFollowing }} /></div>
      </CursorPosition>
    </div>
  )

  function handleMouseMove({ clientX, clientY }) {
    if (!hasMouse) return
    const { top, left, width, height } = componentRef.current.getBoundingClientRect()

    setCursorPosition({
      x: (clientX - left) - (width / 2),
      y: (clientY - top) - (height / 2)
    })
  }

  function handleMouseLeave() {
    setIsFollowing(false)
    setCursorPosition({ x: 0, y: 0 })
  }

  function handleMouseEnter() { setIsFollowing(true) }
}

function CursorPosition({ x, y, isFollowing, layoutClassName, children }) {
  const styleProps = useSpring({
    config: isFollowing ? { tension: 250, friction: 18 } : config.slow,
    to: { transform: `translate(${x}px, ${y}px)` },
  })
  return (
    <animated.div style={{ ...styleProps, willChange: 'transform' }} className={layoutClassName}>
      {children}
    </animated.div>
  )
}

export function FollowCursorCursor({ children, isFollowing, layoutClassName = undefined }) {
  return (
    <IconContainer isActive={isFollowing} {...{ layoutClassName }}>
      {children}
    </IconContainer>
  )
}
