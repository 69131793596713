import { useElementSize } from '@kaliber/use-element-size'

// TODO: variation for ImageCover, ImageContain

export function Image({ builder, image, fit = undefined, aspectRatio = undefined, draggable = undefined, alt = undefined, layoutClassName = undefined }) {
  const { src, srcSet } = useSrcSet({ builder, image, aspectRatio })
  const { size: { width = 0 }, ref } = useElementSize()

  return (
    <img
      key='image'
      className={layoutClassName}
      sizes={Math.max(1, width) + 'px'}
      alt={image.alt ?? alt}
      width={aspectRatio ? 100 : image.asset.metadata ? image.asset.metadata.dimensions.width : undefined}
      height={aspectRatio ? 100 / aspectRatio : image.asset.metadata ? image.asset.metadata.dimensions.height : undefined}
      draggable={draggable ? 'true' : 'false'}
      style={{
        objectFit: fit,
        ...(image?.hotspot && { objectPosition: `${image?.hotspot.x * 100}% ${image?.hotspot.y * 100}%` }),
      }}
      {...{ ref, src, srcSet }}
    />
  )
}

const defaultSizes = [320, 480, 720, 1024, 1440, 1920, 2440, 3660]

function useSrcSet({ builder, image, aspectRatio, sizes = defaultSizes }) {
  return React.useMemo(
    () => {
      const [src] = sizes.slice(-1).map(x => builder.image(image).width(x).auto('format').url())
      const thumb = image.asset.metadata?.lqip
        ? image.asset.metadata.lqip
        : builder.image(image).width(20).quality(0).blur(20).auto('format').url()

      const srcSet = aspectRatio
        ? sizes.map(x => `${builder.image(image).width(x).height(Math.round(x / aspectRatio)).quality(85).auto('format').url()} ${x}w`).join(', ')
        : sizes.map(x => `${builder.image(image).width(x).quality(85).auto('format').url()} ${x}w`).join(', ')

      return { src, srcSet: `${thumb} 1w, ${srcSet}` }
    },
    [image, aspectRatio, sizes, builder]
  )
}
