import { useLocation } from '@reach/router'
import qs from 'qs'

export function useIsPreview() {
  const { search } = useLocation()
  return React.useMemo(
    () => qs.parse(search.slice(1)).hasOwnProperty('preview'),
    [search]
  )
}
