import { Link as LinkBase } from '/components/buildingBlocks/Link'
import styles from './Link.css'
export { LinkText as Link } from '/components/buildingBlocks/Link'

export function LinkCampaign({ to, children }) {
  return <LinkBase className={styles.componentCampaign} target='_blank' {...{ to, children }} />
}

export function LinkSubscribe({ to, children }) {
  return <LinkBase className={styles.componentSubscribe} target='_blank' {...{ to, children }} />
}
