import { SetSiteHeaderColorToWhite, SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { BREAKPOINTS } from '/constants'
import styles from './PageHeader.css'

export const imageSizeMap = BREAKPOINTS.map(({ name }) => name).reduce(
  (res, x) => {
    res[x] = [parseInt(styles[`imageWidth${x}`], 10), parseInt(styles[`imageHeight${x}`], 10)]
    return res
  },
  {}
)

export function PageHeader({ children, isLight = false, componentMedia: Media }) {
  return (
    <SetSiteHeaderColorToWhite>
      <header className={styles.component}>
        <div className={styles.bgLeft} />
        <div className={styles.bgRight} />

        <MediaWrapper layoutClassName={styles.mediaWrapper} {...{ isLight }}>
          <Media />
        </MediaWrapper>

        <SetSiteHeaderColorToBlack layoutClassName={styles.whiteWrapper}>
          <div className={styles.white} />
        </SetSiteHeaderColorToBlack>
        <div className={styles.content}>
          {children}
        </div>
      </header>
    </SetSiteHeaderColorToWhite>
  )
}

function MediaWrapper({ isLight, layoutClassName, children }) {
  const El = isLight ? SetSiteHeaderColorToBlack : SetSiteHeaderColorToWhite
  return <El {...{ layoutClassName, children }} />
}
