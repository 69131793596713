import { useSanityClient } from '/machinery/SanityClient'
import { Image as SanityImage } from '/components/Image'
import { BackgroundVideo } from '/components/backgroundVideo/BackgroundVideo'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { animated, useSpring } from 'react-spring'
import { lerp } from '@kaliber/math'
import { routes, determineDocumentPath } from '/routes'

import styles from './SliderStack.css'

import iconArrowDown from '/components/pageOnly/assets/arrow-down.raw.svg'

export function SliderStack({ slides, layoutClassName }) {

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {slides.map((slide, i) => {
        const link = slide.link && determineDocumentPath({ document: { _type: slide.link.ref._type, slug: { current: slide.link.ref.slug.current } }, routes })
        const showArrow =  i + 1 !== slides.length
        return (
          <div className={styles.slide} key={i}>
            <Slide
              layoutClassName={styles.slideLayout}
              title={slide.title}
              description={slide.description}
              showVideo={slide.showVideo}
              image={slide.image}
              sources={slide.sources}
              currentSlide={i + 1}
              totalSlides={slides.length}
              {...{ link, showArrow }}
            />
          </div>
        )
      })}
    </div>
  )
}

function Slide({ layoutClassName, title, link, description, showVideo, image, sources, currentSlide, totalSlides, showArrow }) {
  const [videoStarted, setVideoStarted] = React.useState(false)
  const [style, spring] = useSpring(() => ({
    scale: 1.4,
    config: { tension: 500, friction: 35 }
  }))

  const trackedElementRef = useScrollProgression({
    start: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.75, -200) },
    onChange(progression) {
      spring.start({ scale: lerp({ start: 1, end: 1.2, input: easeOut(progression) }) })
    }
  })

  function easeOut(x) {
    return Math.sin((x * Math.PI) / 2)
  }

  return (
    <div className={cx(styles.componentSlide, layoutClassName)}>
      <div className={styles.layout}>
        <div className={styles.content}>
          {(link || title) && (
            <a href={link} className={styles.linkTitle}>
              <h3 className={styles.title}>{title}</h3>
            </a>
          )}
          <p className={styles.description}>{description}</p>
          {link && (
            <a href={link} className={styles.linkMobile}>
              <span className={styles.linkInner}>Bekijk project</span>
            </a>
          )}
          <div className={styles.indicator}>
            <div className={styles.indicatorInner}>
              {showArrow && (<Icon icon={iconArrowDown} layoutClassName={styles.iconLayout} />)}
              <span>{currentSlide}/{totalSlides}</span>
            </div>
          </div>
        </div>
        <div className={styles.media} ref={trackedElementRef}>
          <animated.div className={styles.inner} {...{ style }}>
            <Image layoutClassName={styles.imageLayout} {...{ image }} />
            {(showVideo && sources) && (
              <Video layoutClassName={styles.videoLayout} {...{ sources, handleVideoStart }}  />
            )}
          </animated.div>
        </div>
      </div>
    </div>
  )

  function handleVideoStart() {
    setVideoStarted(true)
  }
}

function Image({ image, layoutClassName }) {
  const { imageBuilder } = useSanityClient()

  return (
    <div className={cx(styles.componentImage, layoutClassName)}>
      <SanityImage fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.imageLayout} />
    </div>
  )
}

function Video({ sources, handleVideoStart, layoutClassName }) {
  return (
    <div className={cx(styles.componentVideo, layoutClassName)}>
      <BackgroundVideo layoutClassName={styles.videoLayout} onCanPlay={handleVideoStart} {...{ sources }} />
    </div>
  )

}


function Icon({ icon, layoutClassName = undefined }) {
  return (
    <span
      className={layoutClassName}
      role='presentation'
      dangerouslySetInnerHTML={{ __html: icon }}
    />
  )
}
