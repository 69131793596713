import { Introduction } from '/components/pageOnly/Introduction'
import styles from './Header.css'

export function Header({ title, children, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.layout}>
        <h1 className={styles.heading}>
          {title}
        </h1>
        <Introduction layoutClassName={styles.introductionLayout}>
          {children}
        </Introduction>
      </div>
    </div>
  )
}
