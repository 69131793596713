import { Heading } from './Heading'

import styles from './DoubleDiamond.css'

import doubleDiamond from '/components/inventors/assets/diamond.svg'

export function DoubleDiamond() {
  return (
    <section className={styles.component}>
      <div className={styles.layout}>
        <article className={styles.content}>
          <Heading
            title='Trust the process'
            layoutClassName={styles.titleLayout}
          />
          <p className={styles.text}>
            In Inventors-trajecten weten we op voorhand wat het vraagstuk is, maar nooit wat het resultaat gaat worden. Anything goes. We weten wel vooraf dat we door een bewezen route naar resultaat altijd met de beste oplossing komen.
          </p>
          <p className={styles.text}>
            In high impact sprints leggen we het vraagstuk bloot en werken we naar een tastbare oplossing in de vorm van een prototype, proof of concept of werkend product. Dit alles in gedoseerde co-creatie met opdrachtgever en werkveld.
          </p>
        </article>
        <figure className={styles.image}>
          <img src={doubleDiamond} alt='strategy' />
        </figure>
      </div>
    </section>
  )
}


