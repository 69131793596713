import styles from './IconContainer.css'
import cx from 'classnames'

export function IconContainer({ children, isActive, layoutClassName }) {
  return (
    <span className={cx(styles.component, isActive && styles.isActive, layoutClassName)}>
      {children}
    </span>
  )
}
