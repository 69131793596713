import { TextAddress, TextLink, TextKeyValuePairs } from '/components/legal/Text'
import { PageLayout, PageTitle } from '/components/legal/pageOnly/Page'
import { SectionTitle, Section } from '/components/legal/pageOnly/Section'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'

LegalPrivacy.meta = {
  title: 'Alles over privacy en cookies',
  description:
    'Kaliber verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.',
}

export function LegalPrivacy() {
  useSetDocumentTitle(LegalPrivacy.meta.title)
  return (
    <PageLayout>
      <header>
        <PageTitle>Alles over Privacy & Cookies</PageTitle>
        <p>
          Kaliber Interactive B.V. (Kaliber), gevestigd aan Zeedijk 19, 3513 DA Utrecht, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
        </p>
      </header>
      <Section>
        <SectionTitle>
          Contactgegevens
        </SectionTitle>
        <TextAddress>
          Kaliber Interactive B.V<br />
          Zeedijk 19<br />
          3513 DA Utrecht<br />
          <TextLink to='tel:+31302876400'>+31 30 287 64 00</TextLink>
        </TextAddress>
        <p>
          Onze Functionaris Gegevensbescherming is te bereiken via <TextLink target='_blank' to='mailto:privacy@kaliber.net'>privacy@kaliber.net</TextLink>
        </p>
      </Section>

      <Section>
        <SectionTitle>
          Persoonsgegevens die wij verwerken
        </SectionTitle>
        <p>
          Kaliber verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt.
        </p>
        <p>
          Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:
        </p>
        <ul>
          <li>Voor- en achternaam (bij inschrijving nieuwsbrief)</li>
          <li>E-mailadres (bij inschrijving nieuwsbrief)</li>
          <li>IP-adres (via access logs)</li>
          <li aria-describedby='personal-detail-explanation'>Gegevens over jouw surfgedrag over verschillende websites heen<sup aria-hidden>*</sup></li>
          <li>Internetbrowser en apparaat type</li>
        </ul>
        <p aria-hidden id='personal-detail-explanation'><sup>*</sup> enkel na acceptatie cookies</p>
      </Section>

      <Section>
        <SectionTitle>
          Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
        </SectionTitle>
        <p>
          Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via <TextLink target='_blank' to='mailto:privacy@kaliber.net'>privacy@kaliber.net</TextLink>, dan verwijderen wij deze informatie.
        </p>
      </Section>

      <Section>
        <SectionTitle>
          Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
        </SectionTitle>
        <p>
          Kaliber verwerkt jouw persoonsgegevens voor de volgende doelen:
        </p>
        <ul>
          <li>Verzenden van onze nieuwsbrief</li>
          <li>Je te informeren over wijzigingen van onze diensten en producten</li>
          <li>Kaliber analyseert jouw gedrag op de website om daarmee de website te verbeteren en het aanbod van producten en diensten af te stemmen op jouw voorkeuren.</li>
          <li>Partners van Kaliber volgen jouw surfgedrag over verschillende websites waarmee wij onze producten en diensten afstemmen op jouw behoefte.</li>
        </ul>
      </Section>

      <Section>
        <SectionTitle>
          Hoe lang we persoonsgegevens bewaren
        </SectionTitle>
        <p>
          Kaliber bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens:
        </p>
        <TextKeyValuePairs keyValuePairs={[
          ['Persoonsgegevens', 'IP-adres'],
          ['Bewaartermijn', '30 dagen'],
          ['Reden', 'InfoSec']
        ]} />
      </Section>

      <Section>
        <SectionTitle>
          Delen van persoonsgegevens met derden
        </SectionTitle>
        <p>
          Kaliber verkoopt jouw gegevens niet aan derden en zal deze uitsluitend verstrekken indien dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting. Met bedrijven die jouw gegevens verwerken in onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van jouw gegevens. Kaliber blijft verantwoordelijk voor deze verwerkingen.
        </p>
      </Section>

      <Section>
        <SectionTitle>
          Cookies, of vergelijkbare technieken, die wij gebruiken
        </SectionTitle>
        <p>
          Kaliber gebruikt functionele, analytische en tracking cookies. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de browser van je computer, tablet of smartphone. Kaliber gebruikt cookies met een puur technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en dat bijvoorbeeld jouw voorkeursinstellingen onthouden worden. Deze cookies worden ook gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast plaatsen we cookies die jouw surfgedrag bijhouden zodat we op maat gemaakte content en advertenties kunnen aanbieden.
        </p>
        <p>
          Bij jouw eerste bezoek aan onze website hebben wij je al geïnformeerd over deze cookies en hebben we je toestemming gevraagd voor het plaatsen ervan.
        </p>
        <p>
          Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.
        </p>
        <p>
          Zie voor een toelichting: <TextLink to='https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/' target='_blank'>https://veiliginternetten.nl/&shy;themes/situatie/&shy;cookies-wat-zijn-&shy;het-en-wat-doe-ik-ermee/</TextLink>
        </p>
        <p>
          Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld adverteerders en/of de sociale media-bedrijven. Hieronder een overzicht:
        </p>

        <TextKeyValuePairs keyValuePairs={[
          ['Tool:', 'Google Analytics'],
          ['Doel:', 'Analyse van gebruikersgedrag'],
          ['Policy:', <TextLink to='https://policies.google.com/privacy?hl=nl' target='_blank'>Link</TextLink>],
          ['Bewaartermijn:', 'Onbekend'],
        ]} />

        <TextKeyValuePairs keyValuePairs={[
          ['Tool:', <span aria-describedby='third-party-cookie-explanation'>Google DoubleClick / Google Ads<sup aria-hidden>*</sup></span>],
          ['Doel:', 'Analyse van gebruikersgedrag'],
          ['Policy:', <TextLink to='https://policies.google.com/privacy?hl=nl' target='_blank'>Link</TextLink>],
          ['Bewaartermijn:', 'Onbekend']
        ]} />

        <TextKeyValuePairs keyValuePairs={[
          ['Tool:', 'Google Tag Manager'],
          ['Doel:', 'Met Google Tag Manager kunnen marketeers scripts & tags toevoegen en bijwerken'],
          ['Policy:', <TextLink to='https://policies.google.com/privacy?hl=nl' target='_blank'>Link</TextLink>],
          ['Bewaartermijn:', 'Onbekend']
        ]} />

        <TextKeyValuePairs keyValuePairs={[
          ['Tool:', <span aria-describedby='third-party-cookie-explanation'>Facebook pixel<sup aria-hidden>*</sup></span>],
          ['Doel:', 'Opbouwen van doelgroepen voor het tonen van onze Facebookadvertenties aan gebruikers van onze website. Ook wordt deze cookie gebruikt voor het meten van effectiviteit van onze advertenties op Facebook.'],
          ['Policy:', <TextLink to='https://www.facebook.com/policies/cookies/' target='_blank'>Link</TextLink>],
          ['Bewaartermijn:', 'Onbekend']
        ]} />

        <p aria-hidden id='third-party-cookie-explanation'><sup>*</sup> enkel na acceptatie cookies</p>
      </Section>

      <Section>
        <SectionTitle>
          Gegevens inzien, aanpassen of verwijderen
        </SectionTitle>
        <p>
          Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Kaliber en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen.
        </p>
        <p>
          Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar <TextLink target='_blank' to='mailto:privacy@kaliber.net.'>privacy@kaliber.net</TextLink>.
        </p>
        <p>
          Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek.
        </p>
        <p>
          Kaliber wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: <TextLink target='_blank' to='https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons'>https://autoriteitpersoons&shy;gegevens.nl/nl/contact&shy;-met-de-autoriteit-&shy;persoonsgegevens/tip-ons</TextLink>
        </p>
      </Section>

      <Section>
        <SectionTitle>
          Hoe wij persoonsgegevens beveiligen
        </SectionTitle>
        <p>
          Kaliber neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via <TextLink target='_blank' to='mailto:privacy@kaliber.net'>privacy@kaliber.net</TextLink>
        </p>
      </Section>
    </PageLayout>
  )
}
