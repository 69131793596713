import groq from 'groq'
import isEmpty from 'lodash/isEmpty'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { HeaderFromSanity as Header, HeaderHeading, HeaderParagraph, HeaderLogo, HeaderMeta, HeaderVerticalSpaceBetween, HeaderHighlightedParagraph } from '/components/case/pageOnly/Header'
import { PageLayout } from '/components/case/pageOnly/PageLayout'
import { FlexibleContentGeneric } from '/components/pageOnly/FlexibleContentGeneric'
import { LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { getSingletonTypeBySlug } from '/machinery/groqSnippets'
import { Section } from '/components/home/pageOnly/Section'
import { ContainerDark } from '/components/home/Container'
import { NextItem } from '/components/pageOnly/NextItem'
import { themeColors } from '/machinery/Colors.js'

import styles from './Case.css'

const query = groq`
  ${getSingletonTypeBySlug('case')} {
    "id": _id,
    title,
    header {
      ...,
      logo {
        ...,
        asset->
      },
      headerImage {
        ...,
        asset->
      }
    },
    body,
    meta,
    contact,
    documentOrder,
    flexibleContent[]{
      ...,
      _type == 'caseVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'mobileVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'textWithMedia' => {
        ...,
        mediaType == 'video' => {
          "videoSrc": videoSrc.asset->url
        }
      }
    },
    "previousItem": coalesce(
      *[_type == "case" && hidden != true && (^.documentOrder > documentOrder)] | order(documentOrder desc)[0],
      *[_type == "case" && hidden != true && (^.documentOrder < documentOrder)] | order(documentOrder desc)[0],
    ){
      header {
        client,
        headerImage {
          ...,
          asset->
        }
      },
      slug,
      title,
      hidden
    },
    "nextItem": coalesce(
      *[_type == "case" && hidden != true  && (^.documentOrder < documentOrder)] | order(documentOrder)[0],
      *[_type == "case" && hidden != true && (^.documentOrder > documentOrder)] | order(documentOrder)[0],
    ){
      header {
        client,
        headerImage {
          ...,
          asset->
        }
      },
      slug,
      title,
      hidden
    }
  }
`


Case.meta = {
  async fetchData(sanityClient, { isPreview = false, params: { slug } }) {
    const data = await sanityClient.fetch(query, { slug, isPreview })
    return { data, status: isEmpty(data) ? 404 : 200 }
  }
}

export function Case() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle)

  return (
    <article>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <Page {...{ data }} />}
    </article>
  )
}

function Page({ data }) {
  const { flexibleContent, title, header, previousItem, nextItem } = data ?? {}
  const { headerImage, headerVideo, link, client, logo, intro, results, expertises } = header ?? {}

  return (
    <div className={styles.componentPage}>
      <PageLayout>
        {header && (
          <Header video={headerVideo} image={headerImage}>
            {logo && <HeaderLogo alt={client ? `Logo van ${client}` : 'logo'} image={logo} />}
            <HeaderHeading> {title} </HeaderHeading>
            <HeaderVerticalSpaceBetween>
              {typeof intro === 'string'
                ? <HeaderParagraph>{intro}</HeaderParagraph>
                : <HeaderHighlightedParagraph content={intro} />
              }
              {results && <HeaderMeta result={results} expertise={expertises} />}
              {link && <LinkArrowBorderTransition to={link} target='_blank' color={themeColors.pink}>Bekijk het resultaat</LinkArrowBorderTransition>}
            </HeaderVerticalSpaceBetween>
          </Header>
        )}

        {flexibleContent && (
          <div className={styles.content}>
            <FlexibleContentGeneric {...{ flexibleContent }} />
          </div>
        )}

        <ContainerDark>
          <Section backgroundColor='black' color='white'>
            <NextItem
              {...{ previousItem, nextItem }}
              type='case'
            />
          </Section>
        </ContainerDark>
      </PageLayout>
    </div>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
