import { HeadingMd } from '/components/buildingBlocks/Heading'
import { LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { themeColors } from '/machinery/Colors.js'
import { routes } from '/routes'

export function TextTitle({ children }) {
  return <HeadingMd h={1}>{children}</HeadingMd>
}

export function TextHomeLink({ children }) {
  return <LinkArrowBorderTransition to={routes.home.path} color={themeColors.blue}>{children}</LinkArrowBorderTransition>
}
