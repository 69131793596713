import { PageLayoutDefault } from '/components/pageOnly/PageLayout'
import { SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { useViewport } from '/machinery/Viewport'
import { useRefRect } from '/machinery/useRefRect'
import { useSpring, animated } from 'react-spring'
import styles from './Page.css'

/* eslint-disable import/no-duplicates */
import image_0 from '/components/notFound/assets/404.jpg?max-width=768'
import image_1 from '/components/notFound/assets/404.jpg?max-width=1280'
import image_2 from '/components/notFound/assets/404.jpg'
/* eslint-enable import/no-duplicates */

const srcMap = { XS: image_0, SM: image_0, MD: image_1, LG: image_2, XL: image_2 }

export function PageLayout({ children }) {
  return <PageLayoutDefault><SetSiteHeaderColorToBlack>{children}</SetSiteHeaderColorToBlack></PageLayoutDefault>
}

export function PageContent({ children, texts }) {
  return (
    <div className={styles.componentContent}>
      <div className={styles.container}>
        <ImageWithTitle {...{ texts }} />
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

function ImageWithTitle({ texts }) {
  const { size, viewportWidth: width, viewportHeight, viewportMd } = useViewport()
  const height = viewportHeight * (viewportMd ? 1 : 0.6)
  const clipPathId = React.useRef(useId('clipPathId'))

  const { transform } = useSpring({
    transform: makeTransform({ rotate: 20 }),
    from: { transform: makeTransform({ rotate: 0 }) },
    config: { tension: 200, friction: 12 },
    delay: 800
  })

  return (
    <svg {...{ height, width }} viewBox={`0 0 ${width} ${height}`} patternUnits="userSpaceOnUse">
      <defs>
        <clipPath id={clipPathId.current}>
          <animated.rect {...{ height, width, transform }} />
        </clipPath>
      </defs>
      <animated.image
        xlinkHref={srcMap[size]}
        preserveAspectRatio="xMinYMax slice"
        {...{ height, width, transform }}
      />
      <Title {...{ height, texts }} clipPath={`url(#${clipPathId.current})`} />
    </svg>
  )

  function makeTransform({ rotate }) {
    return `translate(${width * 0.32}, ${height * 0.02}) rotate(${rotate}, ${width * 0.2}, ${height * 0.2})`
  }
}

function Title({ height, texts, clipPath }) {
  const titleRef = React.useRef(null)
  const { height: titleHeight } = useRefRect(titleRef)
  const y = Math.max((height - titleHeight) * 0.7, 90)

  return (
    <>
      <TitlePart {...{ height, texts, y }} style={{ fill: 'black' }} ref={titleRef} />
      <g aria-hidden>
        <TitlePart {...{ height, texts, y }} style={{ fill: 'white', clipPath }} />
      </g>
    </>
  )
}

const TitlePart = React.forwardRef(function TitlePart({ style, height, texts, y }, ref) {
  const { viewportWidth, viewportMd } = useViewport()
  const x = viewportMd ? 40 : 20
  const extraStyles = { lineHeight: 0.75, fontSize: Math.min(height / 6, viewportWidth / 6) }
  const dy = extraStyles.fontSize * extraStyles.lineHeight

  return (
    <text className={styles.componentTitlePart} style={{ ...style, ...extraStyles }} {...{ ref, y }}>
      {texts.map((text) => <tspan key={text} {...{ x, dy }}>{text}</tspan>)}
    </text>
  )
})

const cache = {}

function useId(prefix) {
  const counter = cache.hasOwnProperty(prefix) && cache[prefix] < Number.MAX_SAFE_INTEGER
    ? (cache[prefix]++)
    : (cache[prefix] = 0)
  return `${prefix}-${counter}`
}
