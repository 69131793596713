import styles from './Button.css'

export function ButtonGhost({ children, onClick }) {
  return (
    <ButtonBase className={styles.componentGhost} {...{ children, onClick }} />
  )
}

export function ButtonLink({ children, onClick }) {
  return (
    <ButtonBase className={styles.componentLink} {...{ children, onClick }} />
  )
}

function ButtonBase({ children, onClick, className }) {
  return (
    <button className={cx(styles.componentBase, className)} {...{ children, onClick }} />
  )
}
