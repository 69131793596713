import { PageLayout } from '/components/newsletterArchive/pageOnly/PageLayout'
import { PageHeader } from '/components/newsletterArchive/pageOnly/PageHeader'
import { ContentContainer } from '/components/newsletterArchive/pageOnly/ContentContainer'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { LinkButton } from '/components/buildingBlocks/Link'
import { Link, LinkCampaign } from '/components/newsletterArchive/buildingBlocks/Link'
import { reportError } from '/machinery/reportError'
import styles from './NewsletterArchive.css'

/* eslint-disable import/no-duplicates */
import header_0 from '/components/aboutUs/assets/header.jpg?max-height=620'
import header_1 from '/components/aboutUs/assets/header.jpg?max-height=725'
/* eslint-enable import/no-duplicates */

NewsletterArchive.meta = {
  title: 'Archief van onze nieuwsbrieven',
  description: '',
}

const archiveUrl = 'https://us1.campaign-archive.com/home/?u=309422c452d05f7b9faab86d5&id=68403c5575'
const subscribeUrl = 'https://mailchi.mp/kaliber/inschrijven-ontdekkingen-van-kaliber '

export function NewsletterArchive() {
  useSetDocumentTitle(NewsletterArchive.meta.title)
  return (
    <PageLayout>
      <PageHeader
        srcs={{ XS: header_0, SM: header_0, MD: header_0, LG: header_1, XL: header_1 }}
        alt='Alt text'
        imageIsLight={false}
        title={'Ontdekkingen van Kaliber'}
        description={<Description />}
      />
      <ContentContainer width='md'>
        <Archive />
      </ContentContainer>
    </PageLayout>
  )
}

function Description() {
  return (
    <div className={styles.componentDescription}>
      <p>
        In onze nieuwsbrief delen we elke drie weken onze ontdekkingen en nieuw werk. Nu alvast meer lezen? Hieronder vind je eerdere edities.
      </p>
      <LinkButton to={subscribeUrl}>Schrijf je in</LinkButton>
    </div>
  )
}

function Archive() {
  const [offset, setOffset] = React.useState(0)
  const [state, dispatch] = React.useReducer(fetchDataReducer, { data: null, isError: false, isLoading: false })

  React.useEffect(
    () => {
      let valid = true
      dispatch({ type: 'loading' })
      fetch(`/mailchimp-archive/?offset=${offset}`)
        .then(res => res.json())
        .then(res => {
          if (!valid) return
          dispatch({ type: 'setData', data: res })
        })
        .catch (err => {
          reportError(err)
          if (!valid) return
          dispatch({ type: 'error' })
        })

      return () => { valid = false }
    },
    [offset]
  )

  return (
    <article className={styles.componentArchive}>
      <h2 className={styles.archiveTitle}>Het archief van Kaliber</h2>
      {state.isError && <ErrorMessage />}
      {state.isLoading && <Loading />}
      {state.data && <Campaigns data={state.data} />}
      <div className={styles.actions}>
        <LinkButton to={subscribeUrl}>Schrijf je in</LinkButton>
        {state.data && <Pagination total={state.data.total_items} {...{ offset, setOffset }} />}
      </div>
    </article>
  )
}

function ErrorMessage() {
  return (
    <div className={styles.componentErrorMessage}>
      Er ging iets mis, <Link to={archiveUrl} target="_blank">bekijk ons archief hier</Link>.
    </div>
  )
}

function Loading() {
  return <div className={styles.componentLoading}>Loading...</div>
}

function Campaigns({ data }) {
  return (
    <ol className={styles.componentCampaigns}>
      {data.campaigns.map(({ long_archive_url, send_time, settings: { title, preview_text } }) => (
        <li key={title} className={styles.listItem}>
          <LinkCampaign to={long_archive_url}>
            <div className={styles.titleWrapper}>
              <strong>{title}</strong>
              <time dateTime={send_time}>{formatSendTime(send_time)}</time>
            </div>
            {preview_text}
          </LinkCampaign>
        </li>
      ))}
    </ol>
  )
}

function Pagination({ total, offset, setOffset }) {
  return (
    <div className={styles.componentPagination}>
      {offset !== 0
        ? <button className={styles.prev} onClick={() => { setOffset(offset - 10) }}>❮ Nieuwer</button>
        : <div />
      }
      {offset < (total - 10) &&
        <button className={styles.next} onClick={() => { setOffset(offset + 10) }}>Ouder ❯</button>
      }
    </div>
  )
}

function formatSendTime(str) {
  return new Intl.DateTimeFormat('nl-NL', { year: 'numeric', month: 'numeric', day: 'numeric' }).format(new Date(str))
}

function fetchDataReducer(state, action) {
  switch (action.type) {
    case 'error':
      return { ...state, isError: true, isLoading: false }
    case 'loading':
      return { ...state, isError: false, isLoading: true }
    case 'setData':
      return { data: action.data, isError: false, isLoading: false }
    default:
      reportError('Invalid action type for fetchDataReducer in NewsletterArchive.js, chose one of "setData", "loading" or "error"')
      throw new Error()
  }
}
