import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export function useBodyScrollLock({ enabled }) {
  const elementRef = React.useRef(null)
  const scrollRef = React.useCallback(
    node => {
      if (node && enabled) {
        disableBodyScroll(node)
        elementRef.current = node
      } else {
        if (elementRef.current) enableBodyScroll(elementRef.current)
        elementRef.current = null
      }
    },
    [enabled]
  )

  return { ref: scrollRef }
}
