const MenuStateContext = React.createContext(null)

export function useMenuState() {
  const context = React.useContext(MenuStateContext)
  if (!context)
    throw new Error('Please make sure MenuStateContextProvider is available')
  return context
}

export function MenuStateContextProvider({ children }) {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const value = React.useMemo(() => ({ menuOpen, setMenuOpen }), [menuOpen, setMenuOpen])

  return (
    <MenuStateContext.Provider {...{ value }}>
      {children}
    </MenuStateContext.Provider>
  )
}
