import { useViewport } from './Viewport'

export function useRefRect(ref) {
  const [refRect, setRefRect] = React.useState({ bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0 })
  const { viewportWidth, viewportHeight } = useViewport()

  const getBoundingClientRect = React.useCallback(
    () => {
      const { bottom, height, left, right, top, width } = ref.current.getBoundingClientRect()
      return { bottom, height, left, right, top, width }
    },
    [ref]
  )

  React.useEffect(
    () => { setRefRect(getBoundingClientRect()) },
    [viewportWidth, viewportHeight, getBoundingClientRect]
  )

  return refRect
}
