import styles from './Text.css'

export { LinkText as TextLink } from '/components/buildingBlocks/Link'

export function TextAddress({ children }) {
  return <address className={styles.componentAddress}>{children}</address>
}

export function TextKeyValuePairs({ keyValuePairs }) {
  return (
    <table className={styles.componentKeyValuePairs}>
      <tbody>
        {keyValuePairs.map(([ key, value ]) =>
          <tr {...{ key }}>
            <th className={styles.th}>{key}</th>
            <td>{value}</td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
