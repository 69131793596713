import { SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import styles from './Section.css'


export function Section({ backgroundColor, children }) {
  return <section style={{ backgroundColor }} className={styles.component}>{children}</section>
}

export function SectionWithOffset({ backgroundColor, color, children }) {
  return (
    <section style={{ backgroundColor, color }} className={styles.componentWithOffset}>
      <div className={styles.offset}><SetSiteHeaderColorToBlack /></div>
      {children}
    </section>
  )
}
