import { PageHeader } from '/components/article/pageOnly/PageHeader'
import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import { BackgroundVideo } from '/components/backgroundVideo/BackgroundVideo'

import styles from './Header.css'

export function HeaderFromSanity({ isLight = false, image, video, title, publicationDate, publication, contributors }) {
  const hasVideo = video
  const hasImage = image?.asset?.url

  return (
    <PageHeader
      componentMedia={() => (
        hasVideo ? <BackgroundVideo sources={{
          1080: video?.quality1080,
          720: video?.quality720,
          540: video?.quality540,
          360: video?.quality360
        }} /> :
        hasImage ? <Picture {...{ image }} /> :
        null
      )}
      {...{ title, isLight, contributors, publicationDate, publication  }}
    />
  )
}

function Picture({ image }) {
  const { imageBuilder } = useSanityClient()
  return (
    <div className={styles.componentPicture}>
      <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.image} />
    </div>
  )
}
