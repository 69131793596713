import { useSanityClient } from '/machinery/SanityClient'

import { Image } from '/components/Image'
import { Icon } from '/components/buildingBlocks/Icon'
import { TagsSm } from '/components/experiments/Tags'

import styles from './GridAndCards.css'

import tommyLogo from './assets/logo.raw.svg'

const colors = [ '#ff4dc1', '#1dcbff', '#ffe419', '#a8e536', '#ff6817' ]

export function GridAndCards({ items, onOpenChange }) {
  return (
    <ul className={styles.component}>
      {items.map((item, index) => (
        <li key={index}>
          <Card card={item} {...{ index, onOpenChange }} />
        </li>
      ))}
    </ul>
  )
}

function Card({ card, index, onOpenChange }) {
  const { imageBuilder } = useSanityClient()
  const bgColor = colors[ index % colors.length ]

  return (
    <div className={styles.componentCard}>
      <div className={styles.coverImage}>
        {card.media.src
          ? <Image fit='cover' builder={imageBuilder} image={card.media.src} layoutClassName={styles.imageLayout} />
          : !card.media.src && <div className={styles.placeholder}><Icon icon={tommyLogo} layoutClassName={styles.tommyLayout} /></div>
        }
      </div>
      <div className={styles.content} style={{ '--bg-color': bgColor }}>
        <div className={styles.contentInner}>
          <button
            className={styles.link}
            onClick={() => onOpenChange(card)}
          >
            <h2 className={styles.title}>{card.title}</h2>
          </button>
          {card?.tags && (
            <TagsSm tags={card?.tags} />
          )}
        </div>
      </div>
    </div>
  )
}
