import { GridAwards } from './Grid'
import styles from './Awards.css'

export function Awards({ children }) {
  return <GridAwards>{children}</GridAwards>
}

export function AwardsItem({ src, name }) {
  return <article className={styles.componentItem}><img alt={name} {...{ src }} loading="lazy" /></article>
}
