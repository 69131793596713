
import logoBol from '/components/home/assets/logo-bol.svg'
import imageBol from '/components/home/assets/image-bol.jpg?max-width=280'
import logoRituals from '/components/home/assets/logo-rituals.svg'
import imageRituals from '/components/home/assets/image-rituals.png?max-width=280'
import logoJumbo from '/components/home/assets/logo-jumbo.svg'
import imageJumbo from '/components/home/assets/image-jumbo.jpg?max-width=280'
import logoNNGroup from '/components/home/assets/logo-nn-group.svg'
import imageNNGroup from '/components/home/assets/image-nn-group.jpg?max-width=280'
import logoLaTrappe from '/components/home/assets/logo-la-trappe.svg'
import imageLaTrappe from '/components/home/assets/image-la-trappe.png?max-width=280'
import logoMovir from '/components/home/assets/logo-movir.svg'
import imageMovir from '/components/home/assets/image-movir.jpg?max-width=280'
import logoNijntjeMuseum from '/components/home/assets/logo-nijntje-museum.svg'
import imageNijntjeMuseum from '/components/home/assets/image-nijntje-museum.jpg?max-width=280'
import logoTedxUtrecht from '/components/home/assets/logo-tedxutrecht.svg'
import imageTedxUtrecht from '/components/home/assets/image-tedxutrecht.jpg?max-width=280'
import logoAlbron from '/components/home/assets/logo-albron.svg'
import imageAlbron from '/components/home/assets/image-albron.jpg'
import logoNLVI from '/components/home/assets/logo-nlvi.svg'
import imageNLVI from '/components/home/assets/image-nlvi.jpg?max-width=280'
import logoCentraalMuseum from '/components/home/assets/logo-centraal-museum.svg'
import imageCentraalMuseum from '/components/home/assets/image-centraal-museum.jpg?max-width=280'
import logoRabobank from '/components/home/assets/logo-rabobank.svg'
import imageRabobank from '/components/home/assets/image-rabobank.jpg?max-width=280'
import logoAlliander from '/components/home/assets/logo-alliander.svg'
import imageAlliander from '/components/home/assets/image-alliander.jpg?max-width=280'
import logoIcm from '/components/home/assets/logo-icm.svg'
import imageIcm from '/components/home/assets/image-icm.jpg?max-width=280'
import logoSpoorwegmuseum from '/components/home/assets/logo-spoorwegmuseum.svg'
import imageSpoorwegmuseum from '/components/home/assets/image-spoorwegmuseum.jpg?max-width=280'
import logoSan from '/components/home/assets/logo-san-accent.svg'
import imageSan from '/components/home/assets/image-san-accent.jpg?max-width=280'
import logoKNB from '/components/home/assets/logo-knb.svg'
import imageKNB from '/components/home/assets/image-knb.jpg?max-width=280'
import logoEiffel from '/components/home/assets/logo-eiffel.svg'
import imageEiffel from '/components/home/assets/image-eiffel.jpg'
import logoMollie from '/components/home/assets/logo-mollie.svg'
import imageMollie from '/components/home/assets/image-mollie.png'
import logoKLM from '/components/home/assets/logo-klm.svg'
import imageKLM from '/components/home/assets/image-klm.png'
import logoBavaria from '/components/home/assets/logo-bavaria.svg'
import imageBavaria from '/components/home/assets/image-bavaria.png'
import logoSlachtofferhulp from '/components/home/assets/logo-slachtofferhulp.svg'
import imageSlachtofferhulp from '/components/home/assets/image-slachtofferhulp.jpg'
import logoSwinckels from '/components/home/assets/logo-swinckels.svg'
import imageSwinckels from '/components/home/assets/image-swinckels.jpg'
import logoRodenbach from '/components/home/assets/logo-rodenbach.svg'
import imageRodenbach from '/components/home/assets/image-rodenbach.png'
import logoVZ from '/components/home/assets/logo-vz.svg'
import imageVZ from '/components/home/assets/image-vz.png'

export const clients = [
  { name: 'Bol', logoSrc: logoBol, imageSrc: imageBol },
  { name: 'Rituals', logoSrc: logoRituals, imageSrc: imageRituals },
  { name: 'Jumbo', logoSrc: logoJumbo, imageSrc: imageJumbo },
  { name: 'NNGroup', logoSrc: logoNNGroup, imageSrc: imageNNGroup },
  { name: 'La Trappe', logoSrc: logoLaTrappe, imageSrc: imageLaTrappe },
  { name: 'Movir', logoSrc: logoMovir, imageSrc: imageMovir },
  { name: 'Nijntje Museum', logoSrc: logoNijntjeMuseum, imageSrc: imageNijntjeMuseum },
  { name: 'TextxUtecht', logoSrc: logoTedxUtrecht, imageSrc: imageTedxUtrecht },
  { name: 'Albron', logoSrc: logoAlbron, imageSrc: imageAlbron },
  { name: 'Nederlandse Veteranen Instituut', logoSrc: logoNLVI, imageSrc: imageNLVI },
  { name: 'Centraal Museum', logoSrc: logoCentraalMuseum, imageSrc: imageCentraalMuseum },
  { name: 'Rabobank', logoSrc: logoRabobank, imageSrc: imageRabobank },
  { name: 'Alliander', logoSrc: logoAlliander, imageSrc: imageAlliander },
  { name: 'Icm', logoSrc: logoIcm, imageSrc: imageIcm },
  { name: 'Spoorwegmuseum', logoSrc: logoSpoorwegmuseum, imageSrc: imageSpoorwegmuseum },
  { name: 'San', logoSrc: logoSan, imageSrc: imageSan },
  { name: 'KNB', logoSrc: logoKNB, imageSrc: imageKNB },
  { name: 'Eiffel', logoSrc: logoEiffel, imageSrc: imageEiffel },
  { name: 'Mollie', logoSrc: logoMollie, imageSrc: imageMollie },
  { name: 'KLM', logoSrc: logoKLM, imageSrc: imageKLM },
  { name: 'Bavaria', logoSrc: logoBavaria, imageSrc: imageBavaria },
  { name: 'Slachtofferhulp', logoSrc: logoSlachtofferhulp, imageSrc: imageSlachtofferhulp },
  { name: 'Swickels', logoSrc: logoSwinckels, imageSrc: imageSwinckels },
  { name: 'Rodenbach', logoSrc: logoRodenbach, imageSrc: imageRodenbach },
  { name: 'VZ', logoSrc: logoVZ, imageSrc: imageVZ }
]
