import { ImageOnHoverClients } from './ImageOnHover'
import { Grid } from './Grid'
import styles from './Clients.css'

export function Clients({ entries }) {
  return (
    <Grid>
      {entries.map(({ logoSrc, imageSrc, name }) => <ClientsItem key={name} {...{ logoSrc, imageSrc, name }} />)}
    </Grid>
  )
}

function ClientsItem({ logoSrc, imageSrc, name }) {
  return (
    <ImageOnHoverClients src={imageSrc}>
      <div className={styles.componentItem}>
        <img src={logoSrc} alt={`Logo van ${name}`} loading="lazy" />
      </div>
    </ImageOnHoverClients>
  )
}
