import { createClient } from '@sanity/client'
import imageUrl from '@sanity/image-url'
import { useIsPreview } from './useIsPreview'

const SanityClientContext = React.createContext(null)

export function useSanityClient() {
  const context = React.useContext(SanityClientContext)
  if (!context)
    throw new Error('Please make sure SanityClientContextProvider is available')
  return context
}

export function SanityClientContextProvider({ children, config }) {
  const isPreview = useIsPreview()

  const value = React.useMemo(
    () => {
      const clientConfig = {
        ...config.clients.readOnly,
        ...(isPreview && {
          useCdn: false,
          withCredentials: true,
        })
      }
      const sanityClient = createClient(clientConfig)
      const imageBuilder = imageUrl(sanityClient)
      return { sanityClient, imageBuilder }
    },
    [config, isPreview]
  )

  return <SanityClientContext.Provider {...{ value, children }} />
}
