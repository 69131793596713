import differenceBy from 'lodash/differenceBy'
import ReactDOM from 'react-dom'

export default class Flickity extends Component {
  state = {
    flickityReady: false,
    flickityError: false
  }

  flickityRef = React.createRef()

  async componentDidMount() {
    try {
      this.mounted = true
      const Flickity = await import('flickity').then(x => x.default)
      if (this.mounted) this.setState({ flickityLoaded: true }, () => this.initFlickity(Flickity))
    } catch (err) {
      if (this.mounted) this.setState({ flickityError: true })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const flickityDidBecomeActive = !prevState.flickityReady && this.state.flickityReady
    const added = differenceBy(this.props.children, prevProps.children, x => x.key).length
    const removed = differenceBy(prevProps.children, this.props.children, x => x.key).length

    if (this.state.flickityReady && (flickityDidBecomeActive || added || removed))
      this.refreshFlickity()
    if (prevProps.index !== this.props.index) this.flickity.select(this.props.index)
  }

  componentWillUnmount() {
    this.cancelPreventVerticalScroll && this.cancelPreventVerticalScroll()
    this.mounted = false
    if (this.flickity) {
      this.flickity.off('select', this.handleSelect)
      this.flickity.off('dragStart', this.handleDragStart)
      this.flickity.off('dragEnd', this.handleDragEnd)
      // this timeout looks odd, but React cannot unmount children when Flickity already removed al DOM nodes.
      // In order to still destroy the Flickity instance, we'll destroy it in the next event loop
      setTimeout(() => this.flickity.destroy(), 0)
    }
  }

  initFlickity = Flickity => {
    this.flickity = new Flickity(this.flickityRef.current, { ...this.props.options, prevNextButtons: false })
    this.flickity.on('select', this.handleSelect)
    this.flickity.on('dragStart', this.handleDragStart)
    this.flickity.on('dragEnd', this.handleDragEnd)

    if (this.props.onClick) this.flickity.on('staticClick', this.props.onClick )
    this.setState({ flickityReady: true })
    this.cancelPreventVerticalScroll = preventVerticalScroll(this.flickity)
  }

  handleSelect = x => {
    this.props.onSelect && this.props.onSelect(x)
  }

  handleDragStart = () => {
    this.flickity.slider.style.pointerEvents = 'none'
  }

  handleDragEnd = () => {
    this.flickity.slider.style.pointerEvents = ''
  }

  refreshFlickity() {
    this.flickity.reloadCells()
    this.flickity.resize()
  }

  renderPortal() {
    if (!this.flickityRef.current) return null
    validateChildren(this.props.children)
    const mountNode = this.flickityRef.current.querySelector('.flickity-slider')
    if (mountNode) return ReactDOM.createPortal(this.props.children, mountNode)
  }

  render() {
    if (!this.state.flickityLoaded && !this.state.flickityError) return null
    const { prevNextButtons, className } = this.props
    const ErrorMessage = this.props.ErrorMessage || FlickityErrorMessage
    return this.state.flickityError
      ? <ErrorMessage />
      : (
        <React.Fragment>
          <div {...{ className }}>
            <div ref={this.flickityRef} />
            {prevNextButtons}
          </div>
          {this.renderPortal()}
        </React.Fragment>
      )
  }
}

function validateChildren(children) {
  const childrenList = [].concat(children).filter(Boolean)
  if (childrenList.find(x => x.key === null)) {
    throw new Error('Flickity children should have a key')
  }
}

function preventVerticalScroll(flickity) {
  let isDisabled = false

  flickity.on('dragStart', handleDragStart)
  flickity.on('dragEnd', handleDragEnd)
  flickity.element.addEventListener('touchmove', handleTouchMove)

  return () => {
    flickity.off('dragStart', handleDragStart)
    flickity.off('dragEnd', handleDragEnd)
    handleDragEnd()
  }

  function handleDragStart() {
    isDisabled = true
  }

  function handleDragEnd() {
    isDisabled = false
  }

  function handleTouchMove(e) {
    isDisabled && e.preventDefault()
  }
}

export function FlickityErrorMessage({ layoutClassName = null }) {
  return (
    <div className={layoutClassName}>
      Er ging iets mis waardoor we deze content niet kunnen laten zien. Probeer de pagina te verversen of probeer het later nog eens.
    </div>
  )
}
