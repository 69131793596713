import { Link as RouterLink } from '@reach/router'
import styles from './ButtonCircle.css'

export function ButtonCircle({ children, to = undefined, onClick = undefined, target = undefined, layoutClassName = undefined }) {
  return (
    <ButtonCircleBase
      className={cx(styles.component, layoutClassName)}
      {...{ to, onClick, children, target }}
    />
  )
}

export function ButtonCircleDark({ children, to = undefined, onClick = undefined, target = undefined, layoutClassName = undefined }) {
  return (
    <ButtonCircleBase
      className={cx(styles.componentDark, layoutClassName)}
      {...{ to, onClick, children, target }}
    />
  )
}

function ButtonCircleBase({ to, children, onClick, className, target }) {
  const Base =
    to ? LinkBase :
    onClick ? ButtonBase :
    PseudoBase

  return  <Base className={cx(styles.componentBase, className)} {...{ to, children, target, onClick }} />
}

function LinkBase({ className, to, children, target = undefined }) {
  const protocols = ['http', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => to.startsWith(x))
  const safeRel = target === '_blank' ? 'noopener' : '' // https://web.dev/external-anchors-use-rel-noopener/
  const Base = hasProtocol ? ABase : RouterLink

  return (
    <Base
      rel={safeRel}
      {...{ className, to, children, target }}
    />
  )
}

function ABase({ to, children, className, target, rel }) {
  return (
    <a href={to} {...{ className, target, rel }}>{children}</a>
  )
}

function ButtonBase({ children, onClick, className }) {
  return (
    <button {...{ className, children, onClick }} />
  )
}

function PseudoBase({ children, className }) {
  return (
    <button {...{ className, children }} />
  )
}
