import { useTransition, animated } from 'react-spring'
import { Carousel as CarouselBase } from '/components/buildingBlocks/Carousel'
import { FixedRatioContainer } from '/components/buildingBlocks/FixedRatioContainer'
import { useViewport } from '/machinery/Viewport'
import { validateSrcs } from '/machinery/validateSrcs'
import styles from './Carousel.css'

export function Carousel({ entries }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const captions = entries.map(x => x.caption)

  return (
    <div className={styles.component}>
      <CarouselBase onSelect={setCurrentSlide}>
        {entries.map(({ srcs, alt }, i) => <CarouselImage {...{ srcs, alt }} key={i} />)}
      </CarouselBase>
      <div className={styles.caption}>
        <Caption>{captions[currentSlide]}</Caption>
      </div>
    </div>
  )
}

function CarouselImage({ srcs: { XS, MD }, alt }) {
  /*
    srcs expects 2 image sources with different sizes:
    XS: max-width=768,
    MD: max-width=1280
  */
  validateSrcs({ XS, MD }, { XS: { maxWidth: 768 }, MD: { maxWidth: 1280 } })
  const { viewportMd } = useViewport()
  return (
    <FixedRatioContainer ratio={2 / 3}>
      <img src={viewportMd ? MD : XS} {...{ alt }} />
    </FixedRatioContainer>
  )
}


function Caption({ children }) {
  const transitions = useTransition(children, {
    from: { opacity: 0, transform: 'translateY(30px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateY(-30px)' },
  })

  return (
    <div className={styles.componentCaption}>
      <div style={{ opacity: 0 }}>{children}</div>
      {transitions(( style, item ) =>
        item &&
          <animated.div {...{ style }} className={styles.captionAnimation}>
            {item}
          </animated.div>
      )}
    </div>
  )
}
