import  { animated, useSpring } from 'react-spring'
import styles from './ImageOnHover.css'
import { useHasMouse } from '/machinery/HasMouse'
import { useViewport } from '/machinery/Viewport'

const IMAGE_WIDTH = 280
const IMAGE_HEIGHT = 168

export function ImageOnHoverClients({ src, children }) {
  const [isVisible, setIsVisible] = React.useState(false)

  return (
    <WithMouse fallback={children}>
      <span
        className={styles.componentClients}
        onMouseOver={() => { setIsVisible(true) }}
        onMouseOut={() => { setIsVisible(false) }}
      >
        <span className={styles.imageWrapperClients} style={{ opacity: isVisible ? 1 : 0 }}>
          <AnimatedImageClients {...{ isVisible, src }} />
        </span>
        {children}
      </span>
    </WithMouse>
  )
}

export function ImageOnHoverIntro({ src, children, fallback, imageZIndex }) {
  const [isVisible, setIsVisible] = React.useState(false)
  const [[translateX, translateY], setTranslate] = React.useState(['100%', '100%'])
  const [transformOrigin, setTransformOrigin] = React.useState(['top', 'left'])
  const { viewportWidth, viewportHeight } = useViewport()
  return (
    <WithMouse {...{ fallback }}>
      <span
        className={styles.componentIntro}
        onMouseOver={handleMouseOver}
        onMouseOut={() => { setIsVisible(false) }}
      >
        <span
          className={styles.imageWrapperIntro}
          style={{
            zIndex: imageZIndex,
            opacity: isVisible ? 1 : 0, // preload image
            transformOrigin: transformOrigin.join(' '),
            transform: `translate(${translateX}, ${translateY})`
          }}
        >
          <AnimatedImageIntro {...{ isVisible, src }} />
        </span>
        {children}
      </span>
    </WithMouse>
  )

  function handleMouseOver({ clientX, clientY }) {
    const moveToRight = (clientX + IMAGE_WIDTH * 1.2) > viewportWidth
    const moveToBottom = (clientY + IMAGE_HEIGHT * 1.2) > viewportHeight
    setTranslate([moveToRight ? '0%' : '100%', moveToBottom ? '0%' : '100%'])
    setTransformOrigin([moveToRight ? 'right' : 'left', moveToBottom ? 'bottom' : 'top'])
    setIsVisible(true)
  }
}

function WithMouse({ children, fallback }) {
  const { hasMouse } = useHasMouse()
  return hasMouse ? children : fallback
}

function AnimatedImageClients({ isVisible, src }) {
  const animateProps = useSpring({
    transformOrigin: '50% 50%',
    transform: `
      translate(${isVisible ? '0%, 0%' : '0%, 0%'})
      scale(${isVisible ? 1 : 0})
    `,
    config: {
      mass: 0.8,
      tension: 250,
      friction: 20
    }
  })

  return <AnimatedImageBase {...{ animateProps, src }} />
}

function AnimatedImageIntro({ isVisible, src }) {
  const animateProps = useSpring({
    transformOrigin: '80% 80%',
    transform: `
      translate(${isVisible ? '0%, 0%' : '20%, 20%'})
      scale(${isVisible ? 1 : 0})
    `,
    config: {
      mass: 0.8,
      tension: 250,
      friction: 20
    }
  })

  return <AnimatedImageBase {...{ animateProps, src }} />
}

function AnimatedImageBase({ animateProps, src }) {
  return (
    <animated.span
      className={styles.componentAnimatedImageBase}
      style={{
        ...animateProps,
        width: IMAGE_WIDTH,
        height: IMAGE_HEIGHT,
        backgroundImage: `url(${src})`
      }}
    />
  )
}
