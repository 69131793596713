import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { animated, useSpring } from 'react-spring'
import { useMediaQuery } from '@kaliber/use-media-query'
import { lerp } from '@kaliber/math'

import { HeadingCompactXl } from '/components/buildingBlocks/Heading'

import mediaStyles from '/cssGlobal/media.css'

import styles from './Marketingmix.css'

const items = [
  {
    title: 'Promise',
    description: 'De belofte die merken een breinpositie oplevert',
    color: '#a8e536'
  },
  {
    title: 'Proof',
    description: 'De bewijslast van de belofte in inspirerende content en activaties',
    color: '#ffe419'
  },
  {
    title: 'Platform',
    description: 'Het online moederschip voor verdieping en actie',
    color: '#ff4dc1'
  },
  {
    title: 'Performance',
    description: 'Het begrijpen en verleiden van doelgroepen in campagnes en platformen',
    color: '#1dcbff'
  },
]

export function Marketingmix() {
  const { ref: scrollProgressionRef, progression } = useAnimatedScrollProgression()
  const circleValues = [-150, -50, 50, 150, 0]

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingCompactXl>Wat we doen</HeadingCompactXl>
        <p className={styles.description}>
          We zorgen ervoor dat merken kunnen groeien door mensen iets te laten voelen, iets laten denken en ze in beweging te brengen. Eenvoudig gezegd haken we Promise, Proof, Platform en Performance in elkaar door Intensieve Interactie.
        </p>
      </div>
      <div ref={scrollProgressionRef} className={styles.circles}>
        {items.map((x, i) => (
          <Circle
            key={i}
            title={x.title}
            description={x.description}
            bgFront={x.color || '#ddd'}
            colorFront='#000'
            bgBack='#000'
            colorBack='#fff'
            layoutClassName={styles.circleLayout}
            {...{ progression }}
            value={circleValues[i]}
          />
        ))}
        <animated.div
          className={styles.initialCircle}
          style={{ opacity: progression.to(x =>
            lerp({ start: 1, end: 0, input: x }))
          }}
        >
          P.P.P.P.
        </animated.div>
      </div>
    </div>
  )
}

function Circle({ progression, value, title, description = undefined, layoutClassName = undefined, bgFront, colorFront, bgBack = undefined, colorBack = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <animated.div
      className={cx(styles.componentCircle, layoutClassName)}
      style={{
        opacity: progression.to(x =>
          lerp({ start: isViewportMd ? 0 : 1, end: 1, input: x })),
        transform: progression.to(x =>
          `translateX(${lerp({ start: 0, end: isViewportMd ? value : 0, input: x })}%)`)
      }}
    >
      <div className={cx(styles.inner, description && styles.hasDescription)}>
        <div className={cx(styles.side, styles.front)} style={{ backgroundColor: `${bgFront}`, color: `${colorFront}` }}>
          {title}
        </div>
        {description && (
          <div className={cx(styles.side, styles.back)} style={{ backgroundColor: `${bgBack}`, color: `${colorBack}` }}>
            <p>{description}</p>
          </div>
        )}
      </div>
    </animated.div>
  )
}

function useAnimatedScrollProgression() {
  const [{ progression }, spring] = useSpring(() => ({
    progression: 0,
    config: { tension: 500, friction: 35 }
  }))

  const ref = useScrollProgression({
    start: { element: triggers.bottom(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom(-100) },
    onChange(progression) { spring.start({ progression }) }
  })

  return { ref, progression }
}
