import adcn from '/components/home/assets/award-adcn.svg'
import dia from '/components/home/assets/award-dia.svg'
import epica from '/components/home/assets/award-epica-awards.svg'
import eurobest from '/components/home/assets/award-eurobest.svg'
import lovie from '/components/home/assets/award-lovie-awards.svg'
import pixel from '/components/home/assets/award-pixel-awards.svg'
import san from '/components/home/assets/award-san.svg'
import sjp from '/components/home/assets/award-sjp-magneet.svg'
import spin from '/components/home/assets/award-spin-awards.svg'
import webby from '/components/home/assets/award-webby-awards.svg'

/** @type {{ [key: string]: string }} */
export const awards = [
  ['ADCN', adcn],
  ['DIA', dia],
  ['Epica', epica],
  ['Eurobest', eurobest],
  ['Lovie', lovie],
  ['Pixel', pixel],
  ['SAN', san],
  ['SJP', sjp],
  ['Spin', spin],
  ['Webby', webby],
].reduce(
  (res, [name, image]) => ({ ...res, [name]: image }),
  {}
)
