import { useSiteHeaderIntersect } from '/components/pageOnly/SiteHeaderIntersect'

export function SetSiteHeaderColorToWhite({ children, layoutClassName = undefined }) {
  return SetSiteHeaderColorTo({ children, color: 'white', layoutClassName })
}

export function SetSiteHeaderColorToBlack({ children, layoutClassName = undefined }) {
  return SetSiteHeaderColorTo({ children, color: 'black', layoutClassName })
}

export function SetSiteHeaderColorToWhiteRef({ children, childRef }) {
  return SetSiteHeaderColorToRef({ children, childRef, color: 'white' })
}

export function SetSiteHeaderColorToBlackRef({ children, childRef }) {
  return SetSiteHeaderColorToRef({ children, childRef, color: 'black' })
}

function SetSiteHeaderColorToRef({ children, color, childRef }) {
  useSiteHeaderIntersect({ ref: childRef, color })
  return children
}

function SetSiteHeaderColorTo({ children, layoutClassName, color }) {
  const ref = React.useRef(null)
  useSiteHeaderIntersect({ ref, color })

  return <div className={layoutClassName} {...{ ref, children }} />
}
