import styles from './FixedRatioContainer.css'

export function FixedRatioContainer({ ratio, children }) {
  return (
    <div
      className={styles.component}
      style={{
        paddingTop: `${ratio * 100}%`,
        width: '100%',
        height: 0
      }}
    >
      {children}
    </div>
  )
}
