import debounce from 'lodash/debounce'
import capitalize from 'lodash/capitalize'

const ViewportContext = React.createContext(null)

export function useViewport() {
  const viewport = React.useContext(ViewportContext)
  if (!viewport)
    throw new Error('Please make sure ViewportContextProvider is available')
  return viewport
}

export function ViewportContextProvider({ children, breakpoints = [] }) {
  const viewport = useRawViewport(breakpoints)
  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  )
}

function useRawViewport(breakpoints) {
  const [viewport, setViewport] = React.useState({
    breakpoints,
    viewportWidth: 0,
    viewportHeight: 0,
    ...extra(breakpoints, 0)
  })

  React.useEffect(
    () => {
      let cancelled = false
      const handleResize = debounce(
        () => { if (!cancelled) updateViewport(setViewport, breakpoints) },
        160
      )

      updateViewport(setViewport, breakpoints)
      window.addEventListener('resize', handleResize)
      return () => {
        cancelled = true
        handleResize.cancel()
        window.removeEventListener('resize', handleResize)
      }
    },
    [breakpoints]
  )

  return viewport
}

function updateViewport(setViewport, breakpoints) {
  setViewport((prev) => {
    const viewportWidth = document.body.clientWidth
    const viewportHeight = window.innerHeight

    if (
      prev.viewportWidth === viewportWidth &&
      prev.viewportHeight === viewportHeight &&
      prev.breakpoints === breakpoints
    ) return prev

    return { breakpoints, viewportWidth, viewportHeight: window.innerHeight, ...extra(breakpoints, viewportWidth) }
  })
}

function extra(breakpoints, viewportWidth) {
  if (!breakpoints.length) return {}

  return {
    ...breakpoints.reduce((res, { name, minWidth }) => {
      res.size = (viewportWidth >= minWidth ? name : res.size)
      res[`viewport${capitalize(name)}`] = viewportWidth >= minWidth
      return res
    },
    { size: breakpoints[0].name }
    )
  }
}
