export function Spotify({ title, src }) {
  return (
    <iframe
      src={getPlaylistUrl(src)}
      allow='encrypted-media'
      height='352'
      width='100%'
      frameBorder='0'
      sandbox='allow-scripts allow-same-origin'
      referrerPolicy='no-referrer'
      {... { title }}
    />
  )
}

function getPlaylistUrl(src) {
  const id = src.split('/').pop().split('?')[0]

  if (src.includes('spotify') && src.includes('show')) {
    return id ? `https://open.spotify.com/embed/show/${id}` : null
  }

  if (src.includes('spotify') && src.includes('episode')) {
    return id ? `https://open.spotify.com/embed/episode/${id}` : null
  }

  if (src.includes('spotify') && src.includes('playlist')) {
    return id ? `https://open.spotify.com/embed/playlist/${id}` : null
  }

  return null
}
