import { SrOnly } from '/components/buildingBlocks/SrOnly'
import { IconMenu } from '/components/buildingBlocks/Icon'
import styles from './MenuToggle.css'
import cx from 'classnames'

export const MenuToggle = React.forwardRef(MenuToggleImpl)

function MenuToggleImpl({ color, onClick, layoutClassName = undefined, isOpen }, ref) {
  return (
    <button
      className={cx(styles.component, layoutClassName)}
      style={{ color }}
      aria-expanded={isOpen}
      {...{ onClick, ref }}
    >
      <SrOnly>Menu</SrOnly>
      <IconMenu />
    </button>
  )
}
