import { LinkBlock } from '/components/buildingBlocks/Link'
import { SrOnly } from '/components/buildingBlocks/SrOnly'
import cx from 'classnames'

import styles from './PitchCasesLink.css'

export const PitchCasesLink = React.forwardRef(PitchCasesLinkImpl)
function PitchCasesLinkImpl({ layoutClassName }, ref) {
  return (
    <div className={cx(styles.componentImpl, layoutClassName)} {...{ ref }}>
      <LinkBlock rel="noreferrer" to="http://kaliber.net/STLCases/" target='_self'>
        <div className={styles.inner}>
          <SrOnly>Alle STLcases</SrOnly>
          <span className={styles.text}>Alle STL cases</span>
        </div>
      </LinkBlock>
    </div>
  )
}
