import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import { animated, useSpring, config } from 'react-spring'
import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import { LinkButton } from '/components/buildingBlocks/Link'
import { routes } from '/routes'

import mediaStyles from '/cssGlobal/media.css'

import styles from './EmployeesCta.css'

export function EmployeesCta({ entries }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl)
  const wrapperRef = React.useRef(null)
  const cursorRef = React.useRef(null)
  const [progressProps, progressApi] = useSpring(() => ({ progress: 0 }))
  const [cursorProps, cursorSpringApi] = useSpring(() => ({ opacity: 0, scale: 0, x: 50, y: 50, config: config.gentle }))

  const [carouselRef] = useKeenSlider({
    slides: {
      perView: isViewportXl ? 4.2 : isViewportLg ? 3.2 : 2.2,
      spacing: 20,
    },
    selector: x => x.childNodes,
    detailsChanged: (x) => {
      progressApi.start({ progress: x.track.details.progress })
    }
  })

  return (
    <div className={styles.component}>
      <div
        className={styles.layout}
        ref={wrapperRef}
        onPointerMove={handleMove}
        onPointerCancel={handleMouseLeave}
        onMouseLeave={handleMouseLeave}
      >
        <animated.div className={styles.cursorWrapper} ref={cursorRef} style={cursorProps}>
          <Cursor layoutClassName={styles.cursorLayout} />
        </animated.div>
        <div className={styles.headingWrapper}>
          <animated.h2
            className={styles.heading}
            style={{ transform: progressProps.progress.to(value => isViewportMd && `translateX(${value * -100}%)`) }}
          >
            Team van Kaliber
          </animated.h2>
        </div>
        <div
          className={styles.carouselWrapper}
        >
          <ul className={styles.carousel} ref={carouselRef}>
            {entries.map((item, index) => {
              return (
                <div key={index} className={styles.item}>
                  <Employee
                    image={item.image}
                    firstName={item.firstName}
                    lastName={item.lastName}
                    role={item.role}
                    note={item.personalNote}
                  />
                </div>
              )
            })}
          </ul>
        </div>
      </div>
      <LinkButton to={routes.team.path}>Bekijk alle Tommy's</LinkButton>
    </div>
  )

  function handleMove(e) {
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const cursorRect = cursorRef.current.getBoundingClientRect()
    const x = e.clientX - wrapperRect.x - cursorRect.width / 2
    const y = e.clientY - wrapperRect.y - cursorRect.height / 2
    cursorSpringApi.start({  opacity: 1, scale: 1, x, y })
  }

  function handleMouseLeave() {
    cursorSpringApi.start({ opacity: 0 })
  }
}

function Cursor({ layoutClassName }) {
  return (
    <div className={cx(styles.componentCursor, layoutClassName)}>
      Drag
    </div>
  )
}

function Employee({ image, firstName, lastName, role, note }) {
  const { imageBuilder } = useSanityClient()

  return (
    <div className={styles.componentEmployee}>
      <div className={styles.coverImage}>
        <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.imageLayout} />
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{firstName} {lastName}</div>
        <div className={styles.roleAndNote}>
          {role} {note && 'én ' + note}
        </div>
      </div>
    </div>
  )
}
