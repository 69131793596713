import { SetSiteHeaderColorToWhite, SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { useViewport } from '/machinery/Viewport'
import { validateSrcs } from '/machinery/validateSrcs'
import styles from './PageHeader.css'

const imageHeight = parseInt(styles['imageHeight'], 10)
const imageHeightMd = parseInt(styles['imageHeightMd'], 10)
const imageWidth = parseInt(styles['imageWidth'], 10)
const imageWidthMd = parseInt(styles['imageWidthMd'], 10)

const check = {
  XS: { maxHeight: imageHeight, maxWidth: imageWidth },
  SM: { maxHeight: imageHeight, maxWidth: imageWidth },
  MD: { maxHeight: imageHeightMd, maxWidth: imageWidthMd },
  LG: { maxHeight: imageHeightMd, maxWidth: imageWidthMd },
  XL: { maxHeight: imageHeightMd, maxWidth: imageWidthMd }
}

export function PageHeader({ srcs, alt, imageIsLight, title, description }) {
  return (
    <SetSiteHeaderColorToWhite>
      <header className={styles.component}>
        <BorderBottom layoutClassName={styles.borderBottom} />
        <div className={styles.layout}>
          <Image layoutClassName={styles.imageLayout} {...{ imageIsLight, srcs, alt  }} />
          <Title layoutClassName={styles.titleLayout} {...{ title }} />
          <Content layoutClassName={styles.contentLayout} {...{ description }} />
          <div className={styles.spacer} />
        </div>
      </header>
    </SetSiteHeaderColorToWhite>
  )
}

function Title({ layoutClassName, title }) {
  return <div className={cx(styles.componentTitle, layoutClassName)}><h1 className={styles.title}>{title}</h1></div>
}

function Image({ layoutClassName, imageIsLight, srcs, alt }) {
  const { size } = useViewport()
  const El = imageIsLight ? SetSiteHeaderColorToBlack : SetSiteHeaderColorToWhite
  validateSrcs(srcs, check)

  return (
    <El {...{ imageIsLight, layoutClassName }}>
      <img src={srcs[size]} className={styles.componentImage} {...{ alt }} />
    </El>
  )
}

function Content({ layoutClassName, description }) {
  return (
    <SetSiteHeaderColorToBlack {...{ layoutClassName }}>
      <div className={styles.componentContent}>{description}</div>
    </SetSiteHeaderColorToBlack>
  )
}

function BorderBottom({ layoutClassName }) {
  return (
    <SetSiteHeaderColorToBlack {...{ layoutClassName }} />
  )
}
