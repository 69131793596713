export function validateSrcs(srcs, check) {
  if (process.env.NODE_ENV === 'production' || typeof window === 'undefined') return
  Object.keys(check).map(x => loadAndCheckImageSize(srcs[x], check[x]))
}

function loadAndCheckImageSize(src, check) {
  if (!src) {
    console.error('no src is given')
    return
  }
  // check: maxWidth, maxHeight, width, height
  const img = new Image()
  img.addEventListener('load', ({ srcElement }) => {
    const { naturalWidth, naturalHeight } = srcElement

    if (
      (check.maxWidth && naturalWidth > check.maxWidth) ||
      (check.maxHeight && naturalHeight > check.maxHeight) ||
      (check.width && naturalWidth !== check.width) ||
      (check.height && naturalHeight !== check.height)
    ) console.error(
      `${src} - Incorrect image size (${naturalWidth} x ${naturalHeight}), expected: ${JSON.stringify(check)}`
    )
  })
  img.addEventListener('error', x => console.error(`Error loading ${src}`))
  img.src = src
}
