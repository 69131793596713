import BlockContentBase from '@sanity/block-content-to-react'
import styles from './BlockContent.css'

export function BlockContent({ blocks }) {
  return (
    <BlockContentBase className={styles.component} {... { blocks, serializers }} />
  )
}

export function BlockContentMark({ children, mark}) {
  return (
    <mark className={cx(styles.componentMark, styles[mark.color])}>
      {children}
    </mark>
  )
}

const serializers = {
  types: {
    block: ( props ) => {
      const { style = 'normal' } = props.node

      if (style === 'h1') return <h2 className={styles.heading}>{props.children}</h2>
      if (style === 'h2') return <h3 className={styles.subheading}>{props.children}</h3>
      if (style === 'normal') return <p className={styles.paragraph}>{props.children}</p>

      return BlockContentBase.defaultSerializers.types.block(props)
    }
  },
  list: (props) => {
    if (props.type === 'bullet') return <ul className={cx(styles.list, styles.listUnordered)}>{props.children}</ul>
    if (props.type === 'number') return <ol className={cx(styles.list, styles.listOrdered)}>{props.children}</ol>
    return BlockContentBase.defaultSerializers.list(props)
  },
  marks: {
    highlight: ({ mark, children }) =>
      <BlockContentMark {... { mark }}>{children}</BlockContentMark>,
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    link: ({ mark, children }) => (
      <a href={mark.href} className={styles.link}>
        {children}
      </a>
    )
  }
}
