import Component from './App.js'
import { hydrateRoot } from 'react-dom/client'

const elements = Array.from(document.querySelectorAll('*[data-componentid="App_js"]'))
const renderResults = elements.map(element => {
  const props = JSON.parse(element.dataset.props)
  return { props, root: hydrateRoot(element, <Component {...props} />) }
})

if (module.hot) {
  require('@kaliber/build/lib/hot-module-replacement-client')
  module.hot.accept('./App.js', () => {
    renderResults.forEach(({ props, root }) => root.render(<Component {...props} />))
  })
}
