import strategie from '/components/home/assets/intro-strategie.gif'
import creativiteit from '/components/home/assets/intro-creativiteit.gif'
import technologie from '/components/home/assets/intro-technologie.gif'
import activeren from '/components/home/assets/intro-activeren.gif'
import kan from '/components/home/assets/intro-kan.gif'
import werkt from '/components/home/assets/intro-werkt.gif'
import interactie from '/components/home/assets/intro-interactie.gif'
import verliefd from '/components/home/assets/intro-verliefd.gif'
import begrijpen from '/components/home/assets/intro-begrijpen.gif'
import voelen from '/components/home/assets/intro-voelen.gif'
import doen from '/components/home/assets/intro-doen.gif'
import velux from '/components/home/assets/intro-velux.gif'
import pom from '/components/home/assets/intro-pom.gif'
import breinreis from '/components/home/assets/intro-breinreis.gif'
import dji from '/components/home/assets/intro-dji.gif'
import vz from '/components/home/assets/intro-vz.gif'
import klm from '/components/home/assets/intro-klm.gif'

export const introGifAssets = {
  strategie,
  creativiteit,
  technologie,
  activeren,
  kan,
  werkt,
  interactie,
  verliefd,
  begrijpen,
  voelen,
  doen,
  velux,
  pom,
  breinreis,
  dji,
  vz,
  klm
}


