import styles from './Tags.css'

export function TagsSm({ tags }) {
  return (
    <TagsBase className={styles.componentSm} >
      {tags.map((tag, i) => <TagBase  key={i} className={styles.tagSm} {...{ tag }} />)}
    </TagsBase>
  )
}

export function TagsMd({ tags }) {
  return (
    <TagsBase className={styles.componentMd} >
      {tags.map((tag, i) => <TagBase  key={i} className={styles.tagMd} {...{ tag }} />)}
    </TagsBase>
  )
}

function TagsBase({ className, children }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
    </div>
  )
}

function TagBase({ className, tag }) {
  return (
    <span className={cx(className, styles.componentTagBase)}>{tag}</span>
  )
}
