import groq from 'groq'

/**
 * Gets all latest entries, with drafts overwriting published entries.
 * @param {string} type - of overview you want to obtain
 * @returns {string} GROQ string
 */
export function getPreviewForOverviewType(type) {
  return groq`
    *[
      _type == "${type}"
      && hidden != true
      && ${useDraftAsPreviewDocument}
    ]
  `
}

/**
 * Gets latest entry, with draft overwriting published entry.
 * @param {string} type - of item you want to obtain
 * @returns {string} GROQ string
 */
export function getSingletonTypeBySlug(type) {
  return groq`
    *[
      _type == "${type}"
      && slug.current == $slug
      && ${useDraftAsPreviewDocument}
    ] | order(_updatedAt desc) [0]
  `
}

/**
 * Gets latest entry, with draft overwriting published entry.
 * @param {string} id - of item you want to obtain.
 * @returns {string} GROQ string
 */
export function getSingletonTypeById(id) {
  return groq`
    *[
      _id == "${id}"
      && ${useDraftAsPreviewDocument}
    ] | order(_updatedAt desc) [0]
  `
}

const useDraftAsPreviewDocument = groq`(
  // id exists in a draft
  _id in path("drafts.**") == $isPreview
  // ...or not defined in above array of drafts.
  || !defined(*[_id == "drafts." + ^._id][0])
)`
