export function useUserIsTabbing() {
  const [userIsTabbing, setUserIsTabbing] = React.useState(false)

  React.useEffect(
    () => {
      if (userIsTabbing) return
      window.addEventListener('keydown', handleFirstTab)
      return () => { window.removeEventListener('keydown', handleFirstTab) }
      function handleFirstTab({ key }) { if (key === 'Tab') { setUserIsTabbing(true) } }
    },
    [userIsTabbing]
  )
  return userIsTabbing
}
