import cx from 'classnames'
import { node, oneOf } from 'prop-types'
import styles from './Section.css'

export function Section({ children, backgroundColor, color = undefined }) {
  return <SectionBase {...{ backgroundColor, color, children }} />
}

export function SectionImageOnHover({ children, backgroundColor, color = undefined }) {
  return <SectionBase {...{ backgroundColor, color, children }} className={styles.componentImageOnHover} />
}

export function SectionAwards({ children }) {
  return <SectionBase {...{ backgroundColor: '#ffe419', children }} className={styles.componentAwards} />
}

function SectionBase({ children, backgroundColor, className, color = undefined }) {
  return (
    <section style={{ backgroundColor, color }} className={cx(styles.componentBase, className)}>
      {children}
    </section>
  )
}

SectionTitle.propTypes = {
  children: node,
  position: oneOf(['left', 'right']).isRequired
}

export function SectionTitle({ children, position }) {
  return (
    <div className={cx(styles.componentTitle, styles[position])}>
      <div className={styles.title}>{children}</div>
    </div>
  )
}
