import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/en'
import 'dayjs/locale/nl'

dayjs.extend(utc)
dayjs.extend(localeData)

export { dayjs }
