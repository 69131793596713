import { Image } from '/components/Image'
import { useSanityClient } from '/machinery/SanityClient'

import styles from './Team.css'

export function Team({ employees }) {
  return (
    <div className={styles.component}>
      <div className={styles.layout}>
        {employees.map((item, index) => {
          return (
            <div key={index} className={styles.item}>
              <Employee
                image={item.image}
                firstName={item.firstName}
                lastName={item.lastName}
                role={item.role}
                note={item.personalNote}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

function Employee({ image, firstName, lastName, role, note }) {
  const { imageBuilder } = useSanityClient()

  return (
    <div className={styles.componentEmployee}>
      <div className={styles.coverImage}>
        <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.imageLayout} />
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{firstName} {lastName}</div>
        <div className={styles.roleAndNote}>
          {role} {note && 'én ' + note}
        </div>
      </div>
    </div>
  )
}
