import { Heading } from './Heading'
import { VideoTommy } from '/components/buildingBlocks/Video'
import { LinkButtonWhite } from '/components/buildingBlocks/Link'

import styles from './OntdekkingsDrift.css'

export function OntdekkingsDrift() {
  return (
    <section className={styles.component}>
      <article className={styles.content}>
        <Heading  title='De wereld door de bril van een 7-jarige' />
        <p className={styles.text}>
          De zevenjarige Tommy is onze gids en raadgever. Nieuwsgierig en onbevangen reikt hij naar iets waar hij net niet bij kan. Want dat onbekende is er om ontdekt te worden. We noemen dat Ontdekkingsdrift.        </p>
        <p className={styles.text}>
          Met zijn ontdekkingsdrift zoeken we altijd naar ongebruikelijke oplossingen en experimenteren met creatieve gedachten en nieuwe technologieën. Dat levert een serie op het oog zinloze uitvindingen op zoals een interactieve bierkoelkast, een pratende vis, een mini-disco of een led-muur van 1200 pingpong-ballen. Maar juist door die vreemde experimenten ontdekken we nieuwe mogelijkheden voor intensieve interactie tussen mensen en merken.
        </p>
        <LinkButtonWhite to='/experimenten'>Ontdek de experimenten</LinkButtonWhite>
      </article>
      <figure className={styles.video}>
        <VideoTommy />
      </figure>
    </section>
  )
}
