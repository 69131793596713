import styles from './Label.css'

export function LabelTheme({ children, customColor }) {
  return (
    <LabelBase className={styles.componentTheme} style={{ backgroundColor: customColor }}>
      {children}
    </LabelBase>
  )
}

function LabelBase({ children, className, style }) {
  return (
    <span
      className={cx(styles.componentBase, styles._rootBase, className)}
      {...{ style }}
    >
      {children}
    </span>
  )
}
