import screenfull from 'screenfull'
import { animated, useSpring, useSprings } from 'react-spring'
import { useElementSize } from '@kaliber/use-element-size'
import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { mergeRefs } from '/machinery/mergeRefs'
import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useEvent } from '/machinery/useEvent'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

export function Hero({ title, sources }) {
  const hasVideos = sources
  const [showTitle, setShowTitle] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)
  const elementRef = React.useRef(null)
  const onMouseMoveEvent = useEvent(handleMouseMove)
  const onMouseLeaveEvent = useEvent(handleMouseLeave)
  const urlMobileVideo = Object.values(sources)[2]
  const [showCursor, setShowCursor] = React.useState(false)
  const [isFullscreen, setIsFullscreen] = React.useState(false)

  const [cursorStyle, api] = useSpring(() => ({
    x: null,
    y: null,
    opacity: isHovering && showCursor ? 1 : 0,
    config: { mass: 0.5, tension: 800, friction: 50 }
  }), [isHovering, showCursor])

  const cursorMobilestyles = useSpring({
    from: { opacity: '0' },
    to: { opacity: '1' },
    config: { duration: 200 }
  })

  React.useEffect(
    () => {
      window.addEventListener('mousemove', handleMouseMove)

      return () => window.removeEventListener('mousemove', handleMouseMove)

      function handleMouseMove(e) {
        const { height } = elementRef.current.getBoundingClientRect()
        const scrollYOffset = isFullscreen ? 0 : window.scrollY

        api.start({
          x: e.clientX - 75,
          y: e.clientY - height - 75 + scrollYOffset
        })
      }
    },
    [api, isFullscreen]
  )

  React.useEffect(() => {
    if (screenfull.isEnabled) screenfull.on('change', handleFullscreenChange)

    return () => screenfull.off('change', handleFullscreenChange)

    function handleFullscreenChange() {
      setIsFullscreen(screenfull.isFullscreen)
    }
  },
  []
  )

  React.useEffect(
    () => {
      window.addEventListener('mousemove', handleMouseMove)

      return () => window.removeEventListener('mousemove', handleMouseMove)

      function handleMouseMove(e) {
        if (e.clientY > 150) setShowCursor(true)
        else setShowCursor(false)
      }
    },
    []
  )

  return (
    <header
      ref={elementRef}
      onMouseMove={onMouseMoveEvent}
      onMouseLeave={onMouseLeaveEvent}
      className={styles.component}
    >
      <div className={styles.wrapper}>
        <animated.div style={cursorMobilestyles} className={styles.cursorMobileWrapper}>
          <a
            href={urlMobileVideo}
            target='_blank'
            className={cx(styles.cursor, styles.cursorMobile)}
            rel="noreferrer"
          >
            Bekijk <br /> video
          </a>
        </animated.div>
      </div>

      {hasVideos && (
        <BackgroundVideoWithFullscreenToggle
          onCanPlay={handleVideoStart}
          layoutClassName={styles.videoLayout}
          {...{ sources, cursorStyle, isFullscreen, title, showTitle }}
        />
      )}
    </header>
  )

  function handleVideoStart() {
    setShowTitle(true)
    setTimeout(() => {
      setShowTitle(false)
    }, 4000)
  }

  function handleMouseMove() {
    setIsHovering(true)
  }

  function handleMouseLeave() {
    setIsHovering(false)
  }
}

function BackgroundVideoWithFullscreenToggle({ sources, onCanPlay, cursorStyle, isFullscreen, layoutClassName = undefined, showTitle, title }) {
  const containerRef = React.useRef(null)
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <div ref={containerRef} className={cx(styles.componentBackgroundVideoWithFullscreenToggle, layoutClassName)}>
      {(!isFullscreen && title) && (
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>
            <AnimatedLineSplit revealed={showTitle} text={title} />
          </h1>
        </div>
      )}
      <BackgroundVideo layoutClassName={styles.videoLayout} {...{ sources, onCanPlay, isFullscreen }} />
      {isViewportMd && (
        <button
          onClick={handleFullScreen}
          aria-label='fullscreen-button'
          className={cx(styles.fullscreenButton, isFullscreen && styles.isFullscreen)}
        />
      )}
      {isViewportMd && (
        <animated.div style={cursorStyle} className={cx(styles.cursor, styles.cursorDesktop)}>
          {!isFullscreen ? <>Bekijk <br /> video</> : <>Sluit <br /> video</>}
        </animated.div>
      )}
    </div>
  )

  function handleFullScreen() {
    screenfull.toggle(containerRef.current)
  }
}

function BackgroundVideo({ sources, onCanPlay, isFullscreen = undefined, layoutClassName }) {
  const videoRef = React.useRef(null)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { src, ref: qualityRef } = useVideoQualityLevel({ sources })

  React.useEffect(
    () => {
      if (isInViewport) videoRef.current.play()?.catch(() => {})
      else videoRef.current.pause()
    },
    [isInViewport]
  )

  React.useEffect(
    () => {
      if (isFullscreen) videoRef.current.currentTime = 0
    },
    [isFullscreen]
  )

  return (
    <div className={cx(styles.componentBackgroundVideo, layoutClassName)}>
      <video
        loop
        ref={mergeRefs(videoRef, qualityRef, visibilityRef)}
        controlsList="nodownload"
        muted={!isFullscreen}
        playsInline
        autoPlay
        preload="metadata"
        className={styles.video}
        {...{ src, onCanPlay }}
      />
    </div>
  )
}


function AnimatedLineSplit({ revealed, text }) {
  const words = text.split(/\s/g)
  const componentRef = React.useRef(null)
  const { ref: sizeRef, size: { width, height } } = useElementSize()
  const [springs, setSprings] = useSprings(words.length, _ => ({
    transform: revealed ? 'translate3d(0, calc(0% + 0em), 0)' : 'translate3d(0, calc(100% + 0.1em), 0)'
  }))

  React.useEffect(
    () => {
      const tops = words.map((_, i) => componentRef.current.children[i].offsetTop)
      const offsets = Array.from(new Set(words.map((_, i) => tops[i])))

      setSprings.start(i => ({
        transform: revealed ? 'translate3d(0, calc(0% + 0em), 0)' : 'translate3d(0, calc(100% + 0.1em), 0)',
        delay: 150 + offsets.indexOf(tops[i]) * 200
      }))
    },
    [revealed, words, width, height, setSprings]
  )

  return (
    <div ref={mergeRefs(componentRef, sizeRef)} className={styles.componentAnimatedLineSplit}>
      {words.map((word, i) => (
        <div key={i} className={styles.word}>
          <animated.div style={springs[i]}>{word}</animated.div>
          <Space />
        </div>
      ))}
    </div>
  )
}

function Space() {
  return <div dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
}
