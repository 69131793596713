import groq from 'groq'
import isEmpty from 'lodash/isEmpty'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import {
  HeaderFromSanity as Header,
  HeaderHeading,
  HeaderParagraph,
  HeaderLogo,
  HeaderMeta,
  HeaderVerticalSpaceBetween,
  HeaderHighlightedParagraph
} from '/components/case/pageOnly/Header'
import { PageLayoutAwardEntry } from '/components/pageOnly/PageLayout'
import { FlexibleContentGeneric } from '/components/pageOnly/FlexibleContentGeneric'
import { LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { themeColors } from '/machinery/Colors.js'
import styles from './Case.css'

const query = groq`
  *[_type == 'case' && slug.current == $slug && awardEntry] | order(_updatedAt desc) [0] {
    "id": _id,
    title,
    header {
      ...,
      logo {
        ...,
        asset->
      },
      headerImage {
        ...,
        asset->
      }
    },
    body,
    meta,
    contact,
    documentOrder,
    flexibleContent[]{
      ...,
      _type == 'caseVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'mobileVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'textWithMedia' => {
        ...,
        mediaType == 'video' => {
          "videoSrc": videoSrc.asset->url
        }
      }
    }
  }
`

CaseAwardEntry.meta = {
  async fetchData(sanityClient, { params: { slug } }) {
    const data = await sanityClient.fetch(query, { slug })
    return { data, status: isEmpty(data) ? 404 : 200 }
  }
}

export function CaseAwardEntry() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle)

  return (
    <article>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <Page {...{ data }} />}
    </article>
  )
}

function Page({ data }) {
  const { flexibleContent, title, header } = data ?? {}
  const { headerImage, headerVideo, link, client, logo, intro, results, expertises } = header ?? {}

  return (
    <div className={styles.componentPage}>
      <PageLayoutAwardEntry>
        {header && (
          <Header video={headerVideo} image={headerImage}>
            {logo && <HeaderLogo alt={client ? `Logo van ${client}` : 'logo'} image={logo} />}
            <HeaderHeading>{title}</HeaderHeading>
            <HeaderVerticalSpaceBetween>
              {typeof intro === 'string'
                ? <HeaderParagraph>{intro}</HeaderParagraph>
                : <HeaderHighlightedParagraph content={intro} />
              }
              {results && <HeaderMeta result={results} expertise={expertises} />}
              {link && <LinkArrowBorderTransition to={link} target='_blank' color={themeColors.pink}>View online</LinkArrowBorderTransition>}
            </HeaderVerticalSpaceBetween>
          </Header>
        )}

        {flexibleContent && (
          <FlexibleContentGeneric {...{ flexibleContent }} />
        )}
      </PageLayoutAwardEntry>
    </div>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
