import isEmpty from 'lodash/isEmpty'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import {
  HeaderFromSanity as Header,
  HeaderHeading,
  HeaderParagraph,
  HeaderMeta,
  HeaderVerticalSpaceBetween
} from '/components/case/pageOnly/Header'
import { PageLayout } from '/components/case/pageOnly/PageLayout'
import { FlexibleContentGeneric } from '/components/pageOnly/FlexibleContentGeneric'
import { LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { getSingletonTypeBySlug } from '/machinery/groqSnippets'
import { themeColors } from '/machinery/Colors.js'
import groq from 'groq'

import styles from './Page.css'

const query = groq`
  ${getSingletonTypeBySlug('page')} {
    "id": _id,
    title,
    header {
      ...,
      logo {
        ...,
        asset->
      },
      headerImage {
        ...,
        asset->
      }
    },
    body,
    meta,
    flexibleContent[]{
      ...,
      _type == 'caseVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'mobileVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'textWithMedia' => {
        ...,
        mediaType == 'video' => {
          "videoSrc": videoSrc.asset->url
        }
      }
    }
  }
`

Page.meta = {
  async fetchData(sanityClient, { isPreview = false, params: { slug } }) {
    const data = await sanityClient.fetch(query, { isPreview, slug })
    return { data, status: isEmpty(data) ? 404 : 200 }
  }
}

export function Page() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle)

  return (
    <article>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <PageSection {...{ data }} />}
    </article>
  )
}

function PageSection({ data }) {
  const { flexibleContent, title, header } = data ?? {}
  const { headerImage, headerVideo, link, intro, results, expertises } = header ?? {}

  return (
    <div className={styles.componentPage}>
      <PageLayout>
        {header && (
          <Header video={headerVideo} image={headerImage}>
            <div className={styles.heading}>
              <HeaderHeading>{title}</HeaderHeading>
            </div>
            <HeaderVerticalSpaceBetween>
              <HeaderParagraph>{intro}</HeaderParagraph>
              {results && <HeaderMeta result={results} expertise={expertises} />}
              {link && <LinkArrowBorderTransition to={link} target='_blank' color={themeColors.pink}>Bekijk het resultaat</LinkArrowBorderTransition>}
            </HeaderVerticalSpaceBetween>
          </Header>
        )}

        {flexibleContent && (
          <FlexibleContentGeneric {...{ flexibleContent }} />
        )}

      </PageLayout>
    </div>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
