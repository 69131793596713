import { Location } from '@reach/router'
import { useWindowScroll } from '/machinery/WindowScroll'

export function ManageScrollPosition() {
  return (
    <Location>{({ location }) => <ManageScrollPositionImplementation {...{ location }} />}</Location>
  )
}

function ManageScrollPositionImplementation({ location }) {
  const scrollPositionsRef = React.useRef({})
  const { scrollY } = useWindowScroll()


  React.useEffect(
    () => {
      const currentPosition = scrollPositionsRef.current[location.key]
      if (currentPosition) window.scrollTo(0, currentPosition)
      else window.scrollTo(0, 0)
    },
    [location]
  )

  React.useEffect(
    () => { scrollPositionsRef.current[location.key] = scrollY },
    [location, scrollY]
  )

  return null
}
