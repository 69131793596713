const TrackingSettings = React.createContext(null)

export function useTrackingSettings() {
  const context = React.useContext(TrackingSettings)
  if (!context)
    throw new Error('Please make sure TrackingSettingsProvider is available')
  return context
}

export function TrackingSettingsContextProvider({ children, trackingSettings: initialTrackingSettings }) {
  const [trackingSettings, setTrackingSettings] = React.useState(initialTrackingSettings)
  const value = React.useMemo(
    () => ([ trackingSettings, setTrackingSettings ]), [trackingSettings, setTrackingSettings]
  )

  return (
    <TrackingSettings.Provider {...{ value }}>
      {children}
    </TrackingSettings.Provider>
  )
}
