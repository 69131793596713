import styles from './Heading.css'

export function HeadingMd({ children, h = 3, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children }} className={cx(styles.componentMd, layoutClassName)} />
}

export function HeadingLg({ children, h = 2 }) {
  return <HeadingBase {...{ h, children }} className={styles.componentLg} />
}

export function HeadingCompactLg({ children, h = 2 }) {
  return <HeadingBase {...{ h, children }} className={cx(styles.componentCompactLg, styles.compact)} />
}

export function HeadingXl({ children, h = 1 }) {
  return <HeadingBase {...{ h, children }} className={styles.componentXl} />
}

export function HeadingCompactXl({ children, h = 1, layoutClassName = undefined }) {
  return <HeadingBase {...{ h, children }} className={cx(styles.componentCompactXl, styles.compactXl, layoutClassName)} />
}

export function HeadingCompactXxl({ children }) {
  return <HeadingBase h={1} className={cx(styles.componentCompactXxl, styles.compactXxl)} {...{ children }} />
}

function HeadingBase({ children, className, h }) {
  const H = h === null ? 'strong' : `h${h}`
  const classNames = cx(
    styles.componentBase,
    className,
    { [styles.componentBaseStrong]: H === 'strong' }
  )
  // eslint-disable-next-line @kaliber/layout-class-name
  return <H className={classNames}>{children}</H>
}
