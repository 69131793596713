import { PageLayout, PageContent } from '/components/notFound/pageOnly/Page'
import { TextTitle, TextHomeLink } from '/components/notFound/Text'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'

NotFound.meta = {
  title: '404 - Pagina niet gevonden',
  description: 'Whoops! We hebben de pagina die je hebt opgevraagd niet kunnen vinden.',
}

export function NotFound() {
  useSetDocumentTitle(NotFound.meta.title)
  return (
    <PageLayout>
      <PageContent texts={['Woooops', 'deze pagina', 'is er niet', 'meer.']}>
        <TextTitle>Deze pagina konden we helaas niet vinden</TextTitle>
        <p>Geeft niks, we beginnen gewoon bij het begin.</p>
        <TextHomeLink>Naar Home</TextHomeLink>
      </PageContent>
    </PageLayout>
  )
}
