import styles from './Cases.css'
import FlickityBase, { FlickityErrorMessage } from '/machinery/Flickity'
import { useViewport } from '/machinery/Viewport'
import { ButtonCircle } from '/components/buildingBlocks/ButtonCircle'
import { SrOnly } from '/components/buildingBlocks/SrOnly'
import { IconLeftArrow, IconRightArrow } from '/components/buildingBlocks/Icon'
import { LinkBlock, LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'
import { useSpring, animated } from 'react-spring'
import { routes } from '/routes'
import { useSanityClient } from '/machinery/SanityClient'
import { themeColors } from '/machinery/Colors.js'

export function Cases({ cases }) {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const { viewportLg } = useViewport()
  const flickityRef = React.useRef(null)
  const options = { wrapAround: true, draggable: true, pageDots: false }
  const totalSlides = cases.length

  const prevNextButtons = (
    <div className={styles._rootPrevNextButtons}>
      <PrevNextButtons {...{ flickityRef }} />
    </div>
  )

  return (
    <div className={styles.component}>
      <div className={styles.currentSlideIndicator}>
        <CurrentSlideIndicator {...{ totalSlides, currentSlide }} />
      </div>
      <FlickityBase
        className={styles.carousel}
        ref={flickityRef}
        onSelect={setCurrentSlide}
        {...{ options, prevNextButtons, ErrorMessage }}
      >
        {cases.filter(x => x.slug).map(({ _id, slug, title, header }, i) => (
          <FlickitySlideWrapper key={_id}>
            <Slide
              active={currentSlide === i}
              image={header?.headerImage}
              name={header?.client}
              description={title}
              {...{ slug }}
            />
          </FlickitySlideWrapper>
        ))}
      </FlickityBase>
    </div>
  )
}

function ErrorMessage() {
  return (
    <div className={styles.componentErrorMessage}>
      <FlickityErrorMessage layoutClassName={styles.error} />
    </div>
  )
}

function FlickitySlideWrapper({ children }) {
  return <div className={styles.component_rootFlickitySlideWrapper}>{children}</div>
}

function Slide({ slug, active, name, image, description }) {
  const { imageBuilder } = useSanityClient()
  const src = image ? imageBuilder.image(image).height(724).url() : null
  const alt = image?.alt

  return (
    <LinkBlock target='_self' to={routes.case.path.replace(':slug', slug)} layoutClassName={styles.test}>
      <article className={styles.componentSlide}>
        <div className={styles.image}>
          <SlideImage {...{ alt, src, active }} />
        </div>
        <div className={styles.content}>
          <SlideContent {...{ name, description, active }} />
        </div>
      </article>
    </LinkBlock>
  )
}

function SlideContent({ name, description, active }) {
  const fade = useSpring({
    opacity: active ? 1 : 0,
    config: { tension: 280, friction: 90 }
  })
  return (
    <animated.div className={styles.componentSlideContent} style={fade}>
      <div className={styles.name}>{name}</div>
      <strong className={styles.description}>{description}</strong>
      <div className={styles.pseudoLink}>
        <LinkArrowBorderTransition layoutClassName={styles.linkTextArrow} color={themeColors.pink}>Bekijk</LinkArrowBorderTransition>
      </div>
    </animated.div>
  )
}

function SlideImage({ alt, src, active }) {
  const { viewportMd } = useViewport()
  const imageHeightPercentage = viewportMd ? 60 : 70
  const componentRef = React.useRef(null)
  const [{ width, height }, setSize] = React.useState({ width: 0, height: 0 })

  React.useEffect(
    () => { setSize({ width: componentRef.current.clientWidth, height: componentRef.current.clientHeight }) },
    [componentRef]
  )

  const offset = (height - width * (imageHeightPercentage / 100)) / 2
  const config = { tension: 280, friction: 100  }
  const imageProps = useSpring({ transform: `translateY(${active ? offset : 0}px)`, config })

  return (
    <div className={styles.componentSlideImage} ref={componentRef}>
      <animated.div className={styles.realImage} style={imageProps}>
        <ImageObjectFit layoutClassName={styles.realImageLayout} {...{ src, alt }} />
      </animated.div>
    </div>
  )
}

function CurrentSlideIndicator({ currentSlide, totalSlides }) {
  return (
    <div className={styles.componentCurrentSlideIndicator}>
      {formatNumber(currentSlide + 1)} / {formatNumber(totalSlides)}
    </div>
  )
}

function PrevNextButtons({ flickityRef }) {
  return (
    <div className={styles.componentPrevNextButtons}>
      <ButtonCircle onClick={prev}>
        <span className={styles.buttonIcon}><IconLeftArrow /></span>
        <SrOnly>Vorige case</SrOnly>
      </ButtonCircle>
      <ButtonCircle onClick={next}>
        <span className={styles.buttonIcon}><IconRightArrow /></span>
        <SrOnly>Volgende case</SrOnly>
      </ButtonCircle>
    </div>
  )

  function prev() { flickityRef.current && flickityRef.current.flickity && flickityRef.current.flickity.previous() }
  function next() { flickityRef.current && flickityRef.current.flickity && flickityRef.current.flickity.next() }
}

function formatNumber(number) {
  return number.toString().padStart(2, '0')
}
