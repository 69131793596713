import BlockContentBase from '@sanity/block-content-to-react'
import { ContainerLight, ContainerDark } from '/components/pageOnly/buildingBlocks/Container'
import { VideoVimeo, VideoWithFollowCursor } from '/components/buildingBlocks/Video'
import { CarouselFromSanity } from '/components/pageOnly/Carousel'
import { useSanityClient } from '/machinery/SanityClient'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { HeadingMd } from '/components/buildingBlocks/Heading'
import { Section, SectionWithOffset } from '/components/case/pageOnly/Section'
import { Prism as ReactSyntaxHighlighter } from 'react-syntax-highlighter'
import { tomorrow } from 'react-syntax-highlighter/dist/cjs/styles/prism'
import { Quote } from '/components//pageOnly/Quote'
import { Box } from '/components/pageOnly/Box'
import { useViewport } from '/machinery/Viewport'
import { BlockContent } from '/components/buildingBlocks/BlockContent'
import { TextWithMediaVideo, TextWithMediaImage } from '/components/buildingBlocks/TextWithMedia'
import { Image } from '/components/Image'
import YouTube from 'react-youtube'
import getYouTubeId from 'get-youtube-id'
import { Spotify } from '/components/pageOnly/Spotify'

import styles from './FlexibleContentGeneric.css'

export function FlexibleContentGeneric({ flexibleContent }) {
  return (
    <div>
      {flexibleContent.map(x => {
        switch (x._type) {
          case 'caseVideo': return (
            <CaseVideo
              key={x._key}
              description={x.description}
              backgroundColor={x.backgroundColor}
              sources={x.sources}
              poster={x.poster}
              autoplay={x.autoplay}
            />
          )
          case 'videoWithoutFixedRatio': return (
            <VideoWithoutFixedRatio
              key={x._key}
              backgroundColor={x.backgroundColor}
              source={x.source}
              poster={x.poster}
            />
          )
          case 'textSection': return (
            <TextSection
              key={x._key}
              title={x.title}
              text={x.text}
              backgroundColor={x.backgroundColor}
            />
          )
          case 'aside': return (
            <Aside
              key={x._key}
              title={x.title}
              text={x.text}
            />
          )
          case 'quote': return (
            <Quote
              key={x._key}
              quote={x.quote}
              cite={x.cite}
              image={x.image}
              quatationColor={x.quatationColor}
            />
          )
          case 'carousel':
            return x.images ? (
              <Carousel
                key={x._key}
                images={x.images}
                description={x.description}
                backgroundColor={x.backgroundColor}
              />
            ) : null
          case 'mobileVideo': return (
            <MobileVideo
              key={x._key}
              description={x.description}
              backgroundColor={x.backgroundColor}
              sources={x.sources}
              poster={x.poster}
              isReversed={x.isReversed}
            />
          )
          case 'largeImage': return (
            <LargeImage
              key={x._key}
              image={x.image}
              backgroundColor={x.backgroundColor}
              width={x.width}
              alignment={x.alignment}
              imageIsLight={x.imageIsLight}
            />
          )
          case 'textWithMedia': return (
            <TextWithMedia
              key={x._key}
              mediaIsLight={x.mediaIsLight}
              mediaType={x.mediaType}
              alignment={x.alignment}
              sources={x.sources}
              content={x.content}
              poster={x.poster}
              image={x.image}
            />
          )
          case 'codeBlock': return (
            <CodeBlock
              key={x._key}
              code={x.code}
              language={x.language}
            />
          )
          case 'youtube':
            const id = getYouTubeId(x.url) // TODO: React-player integreren in website https://kaliber.atlassian.net/browse/KALW-133
            return (
              <CenteredContainer width='lg'>
                <YouTube videoId={id} className={styles.videoContainer} />
              </CenteredContainer>
            )
          case 'spotify': return (
            <CenteredContainer width='sm'>
              <Section height='sm'>
                <Spotify title={x.title} src={x.src}  />
              </Section>
            </CenteredContainer>
          )
          default: {
            throw new Error(`Invalid FlexibleContent item type for FlexibleContentGeneric (${x._type})`)
          }
        }
      })}
    </div>
  )
}

export function TextWithMedia({ mediaType, alignment, mediaIsLight, image, poster, content, sources }) {
  const Container = mediaIsLight ? ContainerLight : ContainerDark
  const videoSources = {
    1080: sources?.quality1080,
    720: sources?.quality720,
    540: sources?.quality540,
    360: sources?.quality360
  }
  return (
    <Container>
      <Section height='md'>
        <div className={styles.componentTextWithMedia}>
          {mediaType === 'video' && <TextWithMediaVideo caption={false} sources={videoSources} {...{ alignment, content, poster }} />}
          {mediaType === 'image' && <TextWithMediaImage caption={false} {...{ alignment, content, image }} />}
        </div>
      </Section>
    </Container>
  )
}

export function CaseVideo({ description, backgroundColor: background, sources, poster: posterImage, autoplay }) {
  const { isDark, color, backgroundColor } = extractBackgroundValues(background)
  const Container = isDark ? ContainerDark : ContainerLight
  const { imageBuilder } = useSanityClient()
  const { title, text } = description || {}
  const videoSources = {
    1080: sources?.quality1080,
    720: sources?.quality720,
    540: sources?.quality540,
    360: sources?.quality360
  }
  const poster = posterImage ? imageBuilder.image(posterImage).width(1280).height(720).url() : null
  const ratio = 720 / 1280
  return (
    <Container>
      <Section height='md' {...{ backgroundColor, color }}>
        <div className={styles.componentCaseVideo}>
          <CenteredContainer width='lg'>
            {autoplay
              ? (
                <VideoVimeo
                  sources={videoSources}
                  loop
                  autoPlay={autoplay}
                  {...{ poster, ratio }}
                />
              )
              : (
                <VideoWithFollowCursor
                  src={videoSources['1080']}
                  muted={false}
                  {...{ poster, ratio }} />
              )}
          </CenteredContainer>
          <TextBlock {...{ title, text }} />
        </div>
      </Section>
    </Container>
  )
}

function VideoWithoutFixedRatio({ backgroundColor: background, source, poster: posterImage }) {
  const { imageBuilder } = useSanityClient()

  const poster = posterImage ? imageBuilder.image(posterImage).width(1280).url() : null
  const { isDark, color, backgroundColor } = extractBackgroundValues(background)
  const Container = isDark ? ContainerDark : ContainerLight

  return (
    <Container>
      <Section height='md' {...{ backgroundColor, color }}>
        <CenteredContainer width='lg'>
          <VideoWithFollowCursor src={source} muted={false} {...{ poster }} />
        </CenteredContainer>
      </Section>
    </Container>
  )
}

export function TextSection({ title, text, backgroundColor: background }) {
  const { isDark, color, backgroundColor } = extractBackgroundValues(background)
  const Container = isDark ? ContainerDark : ContainerLight
  return (
    <Container>
      <Section height='md' {...{ backgroundColor, color }}>
        <TextBlock {...{ title, text }} />
      </Section>
    </Container>
  )
}

export function Aside({ title, text }) {
  return (
    <ContainerLight>
      <Section height='md'>
        <TextBlockAside {...{ title, text }} />
      </Section>
    </ContainerLight>
  )
}

export function MobileVideo({ description, backgroundColor: background, poster, isReversed, sources }) {
  const { isDark, color, backgroundColor } = extractBackgroundValues(background)
  const Container = isDark ? ContainerDark : ContainerLight
  const { imageBuilder } = useSanityClient()
  const { title, text } = description || {}
  const videoSources = {
    1080: sources?.quality1080,
    720: sources?.quality720,
    540: sources?.quality540,
    360: sources?.quality360
  }
  return (
    <Container>
      <Section height='md' {...{ backgroundColor, color }}>
        <CenteredContainer width='lg'>
          <div className={cx(styles.componentMobileVideo, isReversed && styles.isReversed)}>
            <VideoVimeo
              ratio={700 / 350}
              sources={videoSources}
              autoPlay
              loop
              poster={poster ? imageBuilder.image(poster).width(350).height(700).url() : null}
            />
            <TextBlock {...{ title, text }} />
          </div>
        </CenteredContainer>
      </Section>
    </Container>
  )
}

export function Carousel({ images, description, backgroundColor: background }) {
  const { imageBuilder } = useSanityClient()
  const { viewportMd } = useViewport()
  const { isDark, color, backgroundColor } = extractBackgroundValues(background)
  const Container = isDark ? ContainerDark : ContainerLight
  const [width, height] = viewportMd ? [1280, 720] : [768, 432]
  const entries = images.map((image) => ({
    src: imageBuilder.image(image).width(width).height(height).auto('format').quality(80).url(),
    alt: image.alt
  }))

  // Ideally we fix sectionWithOffset, it doesn't work with container
  return (
    <Container>
      <SectionWithOffset height='md' {...{ color, backgroundColor }}>
        <div className={styles.componentCarousel}>
          <CenteredContainer width='lg'>
            <CarouselFromSanity {...{ entries }} />
          </CenteredContainer>
          {description && <TextBlock title={description.title} text={description.text} />}
        </div>
      </SectionWithOffset>
    </Container>
  )
}

export function LargeImage({ image, backgroundColor, width, alignment, imageIsLight }) {
  const { imageBuilder } = useSanityClient()
  const Container = imageIsLight ? ContainerLight : ContainerDark
  return (
    <Section height={backgroundColor ? 'md' : 'sm'} {...{ backgroundColor }}>
      <CenteredContainer width={width === 'xl' ? 'xl' : 'lg'}>
        <Container>
          <div className={cx(
            styles.componentLargeImage,
            width === 'sm' && styles.sm,
            width === 'md' && styles.md,
            width === 'lg' && styles.lg,
            width === 'xl' && styles.xl,
            alignment === 'left' && styles.left,
            alignment === 'center' && styles.center,
            alignment === 'right' && styles.right,
          )}>
            <Image layoutClassName={styles.image} builder={imageBuilder} {...{ image }} />
          </div>
        </Container>
      </CenteredContainer>
    </Section>
  )
}

export function CodeBlock({ code, language = undefined }) {
  const { viewportMd } = useViewport()

  return (
    <Section height="md">
      <CenteredContainer width='md'>
        <div className={styles.componentCodeBlock}>
          <ReactSyntaxHighlighter
            language={language ?? 'text'}
            customStyle={{ background: '#2a2a2a', margin: '0', fontSize: !viewportMd && '12px' }}
            lineProps={{ style: { whiteSpace: 'pre-wrap' } }}
            style={tomorrow}
            wrapLines
            showLineNumbers
          >
            {code}
          </ReactSyntaxHighlighter>
        </div>
      </CenteredContainer>
    </Section>
  )
}

function TextBlock({ title = undefined, text = undefined }) {
  return (
    <CenteredContainer width='md'>
      <div className={styles.componentTextBlock}>
        {title && <HeadingMd layoutClassName={styles.headingLayout}>{title}</HeadingMd>}
        {text && <BlockContent blocks={text} />}
      </div>
    </CenteredContainer>
  )
}

function TextBlockAside({ title, text }) {
  return (
    <CenteredContainer width='md'>
      <Box>
        {title && <HeadingMd>{title}</HeadingMd>}
        {text && <BlockContentBase blocks={text} />}
      </Box>
    </CenteredContainer>
  )
}

function extractBackgroundValues(background) {
  if (!background) return { backgroundColor: 'transparent', isDark: false }
  const { hex: backgroundColor, hsl } = background
  const isDark = hsl && hsl.l < 0.53 // based on current usage, may need to tweak this number later
  return { isDark, backgroundColor, color: isDark ? 'white' : 'inherit' }
}
