import { Carousel } from '/components/aboutUs/Carousel'
import { carouselEntries } from '/components/aboutUs/assets/carouselEntries'
import { ContainerDark, ContainerLight, ContainerColored } from '/components/aboutUs/pageOnly/Container'
import { ContentContainer } from '/components/aboutUs/pageOnly/ContentContainer'
import { EmployeesCta } from '/components/aboutUs/pageOnly/EmployeesCta'
import { Header, HeaderTitle, HeaderParagraph } from '/components/aboutUs/pageOnly/Header'
import { PageLayout } from '/components/aboutUs/pageOnly/PageLayout'
import { Section, SectionWithOffset } from '/components/aboutUs/pageOnly/Section'
import { Tommy } from '/components/aboutUs/pageOnly/Tommy'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { VideoWithFollowCursor } from '/components/buildingBlocks/Video'
import { Disciplines } from '/components/aboutUs/pageOnly/Disciplines'
import { Marketingmix } from '/components/aboutUs/pageOnly/Marketingmix'

import { usePageRouteData } from '/machinery/PageRouteData'
import { getPreviewForOverviewType } from '/machinery/groqSnippets'
import groq from 'groq'

/* eslint-disable import/no-duplicates */
import header_0 from '/components/aboutUs/assets/header.jpg?max-height=350'
import header_1 from '/components/aboutUs/assets/header.jpg?max-height=600'
import header_2 from '/components/aboutUs/assets/header.jpg?max-width=1440'
/* eslint-enable import/no-duplicates */

const query = groq`
  ${getPreviewForOverviewType('employee')} | order(_updatedAt desc) [0] {
    "employees": *[_type == "employee" && hidden != true] | order(orderRank) [0...6] {
      ...,
      asset->
    }
  }
`

AboutUs.meta = {
  title: 'Wij zijn Kaliber',
  description:
    'Een digitaal bureau met een ijzersterke combinatie van strategie, creativiteit en technologie. In onze snel veranderende wereld maken we merken wegwijs.',
  async fetchData(sanityClient, { isPreview = false }) {
    const about = await sanityClient.fetch(query, { isPreview })
    return {
      data: { about },
      status: 200
    }
  }
}

export function AboutUs() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle)

  return (
    <article>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <Page {...{ data }} />}
    </article>
  )
}

function Page({ data }) {
  const employees = data?.about?.employees ?? {}

  return (
    <PageLayout>
      <Header
        alt='Speelgoed robot met een soldeerbout.'
        srcs={{ XS: header_0, SM: header_0, MD: header_1, LG: header_1, XL: header_2 }}
      >
        <HeaderTitle>Wij zijn Kaliber</HeaderTitle>
        <HeaderParagraph>
          Sommigen noemen ons een reclamebureau, anderen een design- en innovatiebureau. Of een technologisch bureau. Het maakt ons niet uit: we zijn het allemaal. We geloven niet dat we ons moeten beperken tot 1 specialisme.
        </HeaderParagraph>
        <HeaderParagraph>
          Want wij zijn er niet om alleen een middel te maken, maar om vraagstukken te kraken. En dankzij een mix van generalisten, specialisten en duizendpoten weten we altijd met een verrassend en effectief antwoord te komen.
        </HeaderParagraph>
      </Header>
      <Section backgroundColor='#fff'>
        <Disciplines />
      </Section>
      <Section backgroundColor='#fff'>
        <ContentContainer width='lg'>
          <Marketingmix />
        </ContentContainer>
      </Section>
      <ContainerColored>
        <Section backgroundColor='#ffffff'>
          <ContentContainer width='lg'>
            <Tommy>
              <p>
                De 7-jarige Tommy is onze gids en mentor. Hij staat op zijn tenen en reikt naar iets waar hij nét niet bij kan – want het onbekende heeft een magische aantrekkingskracht. Voor die combinatie van nieuwsgierigheid en onbezonnenheid hebben we een woord bedacht: ontdekkingsdrift.<br /><br />
                Tommy vertelt ons dat we die ontdekkingsdrift moeten gebruiken om de nieuwe wereld te begrijpen. En hoe we alle nieuwe inzichten en ontwikkelingen toepassen om opmerkelijk en effectief werk te maken.
              </p>
            </Tommy>
          </ContentContainer>
        </Section>
        <Section backgroundColor='#ffffff'>
          <ContentContainer width='lg'>
            <ContainerDark>
              <VideoWithFollowCursor
                ratio={718 / 1280}
                muted={false}
                src='/components/aboutUs/assets/video-kaliber.mp4'
                poster='/components/aboutUs/assets/poster-kaliber.jpg'
              />
            </ContainerDark>
          </ContentContainer>
        </Section>
      </ContainerColored>

      <ContainerDark>
        <SectionWithOffset backgroundColor='#000' color='white'>
          <ContentContainer width='lg'>
            <Carousel entries={carouselEntries} />
          </ContentContainer>
        </SectionWithOffset>
      </ContainerDark>

      <ContainerLight>
        <Section backgroundColor='#fff'>
          <EmployeesCta entries={employees} />
        </Section>
      </ContainerLight>

    </PageLayout>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
