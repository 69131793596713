import { SetSiteHeaderColorToWhite, SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { Image } from '/components/Image'
import { useSanityClient } from '/machinery/SanityClient'
import { dayjs } from '/machinery/dayjs'
import { LinkText } from '/components/buildingBlocks/Link'

import styles from './PageHeader.css'

export function PageHeader({ title, isLight = false, componentMedia: Media, contributors, publicationDate = undefined, publication = undefined }) {
  return (
    <SetSiteHeaderColorToBlack>
      <header className={styles.component}>
        {title && (
          <CenteredContainer width='md' layoutClassName={styles.container}>
            <h1 className={styles.title}>{title}</h1>
          </CenteredContainer>
        )}
        <div className={styles.media}>
          <MediaWrapper layoutClassName={styles.mediaWrapper} {...{ isLight }}>
            <Media />
          </MediaWrapper>
        </div>
      </header>
      {contributors &&  (<Information {...{ contributors, publicationDate, publication }} />)}
    </SetSiteHeaderColorToBlack>
  )
}

function MediaWrapper({ isLight, layoutClassName, children }) {
  const El = isLight ? SetSiteHeaderColorToBlack : SetSiteHeaderColorToWhite
  return <El {...{ layoutClassName, children }} />
}


function Information({ contributors, publicationDate, publication }) {
  const { imageBuilder } = useSanityClient()

  const formattedPublicationDate = publicationDate
    ? dayjs(publicationDate).locale('nl').format('D MMMM YYYY')
    : null

  return (
    <div className={styles.componentInformation}>
      <CenteredContainer width='md' >
        <div className={styles.layout}>
          {contributors && (
            <div className={styles.images}>
              {contributors.map((x, i) => <div key={i} className={styles.coverImage}><Image layoutClassName={styles.imageLayout} builder={imageBuilder} image={x.image} /></div>)}
            </div>
          )}
          {(contributors || publicationDate) && (
            <div className={styles.content}>
              {contributors && (
                <div className={styles.people}>
                  {contributors.map((x, i) => {
                    return (
                      <span key={i}>
                        {x.firstName}, {x.role} {i < contributors.length - 1 ? '• ' : ''}
                      </span>
                    )
                  })}
                </div>
              )}
              {(publicationDate || publication) && (
                <div className={styles.extraInformation}>
                  {formattedPublicationDate}
                  {publication && (
                    <span>{publicationDate && ' · '} Gepubliceerd in <LinkText to={publication.href} target='_blank'>{publication.label}</LinkText></span>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </CenteredContainer>
    </div>
  )
}
