// @ts-nocheck
/* eslint-disable import/no-duplicates */
import placeholder1_0 from './placeholder1.jpg?max-width=300'
import placeholder1_1 from './placeholder1.jpg?max-width=370'
import placeholder2_0 from './placeholder2.jpg?max-width=300'
import placeholder2_1 from './placeholder2.jpg?max-width=370'
import placeholder3_0 from './employees/placebot.jpg?max-width=300'
import placeholder3_1 from './employees/placebot.jpg?max-width=370'
import caspar_0 from './employees/csteinebach.jpg?max-width=300'
import caspar_1 from './employees/csteinebach.jpg?max-width=370'
import charon_0 from './employees/Charon.jpg?max-width=300'
import charon_1 from './employees/Charon.jpg?max-width=370'
import davey_0 from './employees/Davey.jpg?max-width=300'
import davey_1 from './employees/Davey.jpg?max-width=370'
import dennis_0 from './employees/Dennis.jpg?max-width=300'
import dennis_1 from './employees/Dennis.jpg?max-width=370'
import edwin_0 from './employees/ekoster.jpg?max-width=300'
import edwin_1 from './employees/ekoster.jpg?max-width=370'
import erwin_0 from './employees/ehaers.jpg?max-width=300'
import erwin_1 from './employees/ehaers.jpg?max-width=370'
import erik_0 from './employees/Erik.jpg?max-width=300'
import erik_1 from './employees/Erik.jpg?max-width=370'
import fabian_0 from './employees/Fabian.jpg?max-width=300'
import fabian_1 from './employees/Fabian.jpg?max-width=370'
import frank_0 from './employees/Frank.jpg?max-width=300'
import frank_1 from './employees/Frank.jpg?max-width=370'
import gert_jan_0 from './employees/Gert-Jan.jpg?max-width=300'
import gert_jan_1 from './employees/Gert-Jan.jpg?max-width=370'
import hajar_0 from './employees/hothmani.jpg?max-width=300'
import hajar_1 from './employees/hothmani.jpg?max-width=370'
import harmen_0 from './employees/hsielcken.jpg?max-width=300'
import harmen_1 from './employees/hsielcken.jpg?max-width=370'
import hugo_0 from './employees/Hugo.jpg?max-width=300'
import hugo_1 from './employees/Hugo.jpg?max-width=370'
import jan_0 from './employees/Jan.jpg?max-width=300'
import jan_1 from './employees/Jan.jpg?max-width=370'
import josette_0 from './employees/jbijl.jpg?max-width=370'
import josette_1 from './employees/jbijl.jpg?max-width=300'
import iris_0 from './employees/iholtkuile.jpg?max-width=300'
import iris_1 from './employees/iholtkuile.jpg?max-width=370'
import janinke_0 from './employees/jstegerman.jpg?max-width=300'
import janinke_1 from './employees/jstegerman.jpg?max-width=370'
import jerry_0 from './employees/Jerry.jpg?max-width=300'
import jerry_1 from './employees/Jerry.jpg?max-width=370'
import jurgen_0 from './employees/Jurgen.jpg?max-width=300'
import jurgen_1 from './employees/Jurgen.jpg?max-width=370'
import justus_0 from './employees/Justus.jpg?max-width=300'
import justus_1 from './employees/Justus.jpg?max-width=370'
import lars_0 from './employees/Lars.jpg?max-width=300'
import lars_1 from './employees/Lars.jpg?max-width=370'
import laura_0 from './employees/lvanderplas.jpg?max-width=300'
import laura_1 from './employees/lvanderplas.jpg?max-width=370'
import laurens_0 from './employees/lmooiweer.jpg?max-width=300'
import laurens_1 from './employees/lmooiweer.jpg?max-width=370'
import lieke_0 from './employees/Lieke.jpg?max-width=300'
import lieke_1 from './employees/Lieke.jpg?max-width=370'
import maarten_0 from './employees/Maarten.jpg?max-width=300'
import maarten_1 from './employees/Maarten.jpg?max-width=370'
import maria_0 from './employees/mbarmentlo.jpg?max-width=300'
import maria_1 from './employees/mbarmentlo.jpg?max-width=370'
import maris_0 from './employees/mjacobs.jpg?max-width=300'
import maris_1 from './employees/mjacobs.jpg?max-width=370'
import martijn_0 from './employees/Martijn.jpg?max-width=300'
import martijn_1 from './employees/Martijn.jpg?max-width=370'
import martin_0 from './employees/Martin.jpg?max-width=300'
import martin_1 from './employees/Martin.jpg?max-width=370'
import melissa_0 from './employees/mbos.jpg?max-width=300'
import melissa_1 from './employees/mbos.jpg?max-width=370'
import michiel_0 from './employees/Michiel.jpg?max-width=300'
import michiel_1 from './employees/Michiel.jpg?max-width=370'
import milly_0 from './employees/Milly.jpg?max-width=300'
import milly_1 from './employees/Milly.jpg?max-width=370'
import nicoline_0 from './employees/Nicoline.jpg?max-width=300'
import nicoline_1 from './employees/Nicoline.jpg?max-width=370'
import nienke_0 from './employees/Nienke.jpg?max-width=300'
import nienke_1 from './employees/Nienke.jpg?max-width=370'
import paul_0 from './employees/Paul.jpg?max-width=300'
import paul_1 from './employees/Paul.jpg?max-width=370'
import peeke_0 from './employees/Peeke.jpg?max-width=300'
import peeke_1 from './employees/Peeke.jpg?max-width=370'
import peter_0 from './employees/pgoddijn.jpg?max-width=300'
import peter_1 from './employees/pgoddijn.jpg?max-width=370'
import roos_0 from './employees/rkoene.jpg?max-width=300'
import ronald_0 from './employees/Ronald.jpg?max-width=300'
import ronald_1 from './employees/Ronald.jpg?max-width=370'
import roos_1 from './employees/rkoene.jpg?max-width=370'
import sanne_0 from './employees/Sanne.jpg?max-width=300'
import sanne_1 from './employees/Sanne.jpg?max-width=370'
import saskia_0 from './employees/sbraakman.jpg?max-width=300'
import saskia_1 from './employees/sbraakman.jpg?max-width=370'
import sytse_0 from './employees/skooistra.jpg?max-width=300'
import sytse_1 from './employees/skooistra.jpg?max-width=370'
import bianca_0 from './employees/bianca.jpg?max-width=300'
import bianca_1 from './employees/bianca.jpg?max-width=370'
import albert_0 from './employees/albert.jpg?max-width=300'
import albert_1 from './employees/albert.jpg?max-width=370'
import sebastiaan_0 from './employees/sebastiaan.jpg?max-width=300'
import sebastiaan_1 from './employees/sebastiaan.jpg?max-width=370'
import luke_0 from './employees/luke.jpg?max-width=300'
import luke_1 from './employees/luke.jpg?max-width=370'
/* eslint-enable import/no-duplicates */

export const employees = [
  {
    isEmployee: true,
    name: 'Ronald van Schaik',
    emailAddress: 'ronald@kaliber.net',
    srcs: { XS: ronald_0, MD: ronald_1 },
    functionTitle: 'Eigenaar / Directeur',
  },
  {
    isEmployee: true,
    name: 'Nienke van Heusden',
    emailAddress: 'nienke@kaliber.net',
    srcs: { XS: nienke_0, MD: nienke_1 },
    functionTitle: 'Managing Director',
  },
  {
    isEmployee: true,
    name: 'Martin Stiemer',
    emailAddress: 'martin@kaliber.net',
    srcs: { XS: martin_0, MD: martin_1 },
    functionTitle: 'Business Director',
  },
  {
    isEmployee: true,
    name: 'Gert-Jan Noordanus',
    emailAddress: 'gert-jan@kaliber.net',
    srcs: { XS: gert_jan_0, MD: gert_jan_1 },
    functionTitle: 'Client Services Manager',
  },
  {
    isEmployee: true,
    name: 'Dennis Suichies',
    emailAddress: 'dennis@kaliber.net',
    srcs: { XS: dennis_0, MD: dennis_1 },
    functionTitle: 'Interactive Producer',
  },
  {
    isEmployee: true,
    name: 'Lieke Ongering',
    emailAddress: 'lieke@kaliber.net',
    srcs: { XS: lieke_0, MD: lieke_1 },
    functionTitle: 'Copywriter',
  },
  {
    isEmployee: true,
    name: 'Jurgen van Kralingen',
    emailAddress: 'jurgen@kaliber.net',
    srcs: { XS: jurgen_0, MD: jurgen_1 },
    functionTitle: 'UX Director',
  },
  {
    isEmployee: false,
    host: 'werkenbij.kaliber.net',
    srcs: { XS: placeholder3_0, MD: placeholder3_1 },
    description: 'Check onze vacatures',
    title: 'Jij?',
    alt: 'Foto van speelgoed robot',
  },
  {
    isEmployee: true,
    name: 'Davey Kropf',
    emailAddress: 'davey@kaliber.net',
    srcs: { XS: davey_0, MD: davey_1 },
    functionTitle: 'Foto en videoproductie',
  },
  {
    isEmployee: true,
    name: 'Sytse Kooistra',
    emailAddress: 'sytse@kaliber.net',
    srcs: { XS: sytse_0, MD: sytse_1 },
    functionTitle: 'Strategy Director',
  },
  {
    isEmployee: true,
    name: 'Erik Westra',
    emailAddress: 'erik+from-website@kaliber.net',
    srcs: { XS: erik_0, MD: erik_1 },
    functionTitle: 'Technical Director',
  },
  {
    isEmployee: true,
    name: 'Hugo van de Hoef',
    emailAddress: 'hugo@kaliber.net',
    srcs: { XS: hugo_0, MD: hugo_1 },
    functionTitle: 'Creative Director',
  },
  {
    isEmployee: true,
    name: 'Edwin Koster',
    emailAddress: ' mens@kaliber.net',
    srcs: { XS: edwin_0, MD: edwin_1 },
    functionTitle: 'System Administrator',
  },
  {
    isEmployee: true,
    name: 'Frank Meeuwsen',
    emailAddress: 'frank@kaliber.net',
    srcs: { XS: frank_0, MD: frank_1 },
    functionTitle: 'Content Lead',
  },
  {
    isEmployee: true,
    name: 'Jan Brünemann',
    emailAddress: 'jan@kaliber.net',
    srcs: { XS: jan_0, MD: jan_1 },
    functionTitle: 'Back-end Developer',
  },
  {
    isEmployee: true,
    name: 'Jerry Duijm',
    emailAddress: 'jerry@kaliber.net',
    srcs: { XS: jerry_0, MD: jerry_1 },
    functionTitle: 'Developer',
  },
  {
    isEmployee: true,
    name: 'Lars van Kleef',
    emailAddress: 'lars@kaliber.net',
    srcs: { XS: lars_0, MD: lars_1 },
    functionTitle: 'Creative Developer',
  },
  {
    isEmployee: true,
    name: 'Justus Kamerbeek',
    emailAddress: 'justus@kaliber.net',
    srcs: { XS: justus_0, MD: justus_1 },
    functionTitle: 'Online Marketeer',
  },
  {
    isEmployee: true,
    name: 'Maarten Gentenaar',
    emailAddress: 'maarten@kaliber.net',
    srcs: { XS: maarten_0, MD: maarten_1 },
    functionTitle: 'Digital Designer',
  },
  {
    isEmployee: false,
    host: 'werkenbij.kaliber.net',
    srcs: { XS: placeholder3_0, MD: placeholder3_1 },
    description: 'Check onze vacatures',
    title: 'Jij?',
    alt: 'Foto van speelgoed robot',
  },
  {
    isEmployee: true,
    name: 'Michiel Koorstra',
    emailAddress: 'michiel@kaliber.net',
    srcs: { XS: michiel_0, MD: michiel_1 },
    functionTitle: 'Digital Designer',
  },
  {
    isEmployee: true,
    name: 'Nicoline Wouterlood',
    emailAddress: 'nicoline@kaliber.net',
    srcs: { XS: nicoline_0, MD: nicoline_1 },
    functionTitle: 'Copywriter',
  },
  {
    isEmployee: true,
    name: 'Sanne Marlet',
    emailAddress: 'sanne@kaliber.net',
    srcs: { XS: sanne_0, MD: sanne_1 },
    functionTitle: 'Office manager',
  },
  {
    isEmployee: true,
    name: 'Paul Brekelmans',
    emailAddress: 'paul@kaliber.net',
    srcs: { XS: paul_0, MD: paul_1 },
    functionTitle: 'Online Marketeer',
  },
  {
    isEmployee: true,
    name: 'Peeke Kuepers',
    emailAddress: 'peeke@kaliber.net',
    srcs: { XS: peeke_0, MD: peeke_1 },
    functionTitle: 'Lead UX / UI developer',
  },
  {
    isEmployee: true,
    name: 'Martijn de Valk',
    emailAddress: 'martijn@kaliber.net',
    srcs: { XS: martijn_0, MD: martijn_1 },
    functionTitle: 'Creative Developer',
  },
  {
    isEmployee: false,
    host: 'werkenbij.kaliber.net',
    srcs: { XS: placeholder3_0, MD: placeholder3_1 },
    description: 'Check onze vacatures',
    title: 'Jij?',
    alt: 'Foto van speelgoed robot',
  },
  {
    isEmployee: true,
    name: 'Caspar Steinebach',
    emailAddress: 'csteinebach@kaliber.net',
    srcs: { XS: caspar_0, MD: caspar_1 },
    functionTitle: 'Backend Developer',
  },
  {
    isEmployee: true,
    name: 'Charon Geling',
    emailAddress: 'cgeling@kaliber.net',
    srcs: { XS: charon_0, MD: charon_1 },
    functionTitle: 'Online Marketeer',
  },
  {
    isEmployee: true,
    name: 'Erwin Haers',
    emailAddress: 'ehaers@kaliber.net',
    srcs: { XS: erwin_0, MD: erwin_1 },
    functionTitle: 'Creative Front end Developer',
  },
  {
    isEmployee: true,
    name: 'Fabian Schulting',
    emailAddress: 'fschulting@kaliber.net',
    srcs: { XS: fabian_0, MD: fabian_1 },
    functionTitle: 'Senior Art Director',
  },
  {
    isEmployee: true,
    name: 'Hajar Othmani',
    emailAddress: 'hothmani@kaliber.net',
    srcs: { XS: hajar_0, MD: hajar_1 },
    functionTitle: 'Creative Front end Developer',
  },
  {
    isEmployee: true,
    name: 'Harmen Sielcken',
    emailAddress: 'hsielcken@kaliber.net',
    srcs: { XS: harmen_0, MD: harmen_1 },
    functionTitle: 'Backend Developer',
  },
  {
    isEmployee: true,
    name: 'Iris Holtkuile',
    emailAddress: 'iholtkuile@kaliber.net',
    srcs: { XS: iris_0, MD: iris_1 },
    functionTitle: 'Copywriter',
  },
  {
    isEmployee: true,
    name: 'Janinke Stegerman',
    emailAddress: 'jstegerman@kaliber.net',
    srcs: { XS: janinke_0, MD: janinke_1 },
    functionTitle: 'Interactive Producer',
  },
  {
    isEmployee: true,
    name: 'Josette Bijl',
    emailAddress: 'jbijl@kaliber.net',
    srcs: { XS: josette_0, MD: josette_1 },
    functionTitle: 'Interactive Producer',
  },
  {
    isEmployee: true,
    name: 'Laura van der Plas',
    emailAddress: 'lvanderplas@kaliber.net',
    srcs: { XS: laura_0, MD: laura_1 },
    functionTitle: 'UX Designer',
  },
  {
    isEmployee: true,
    name: 'Laurens Mooiweer',
    emailAddress: 'lmooiweer@kaliber.net',
    srcs: { XS: laurens_0, MD: laurens_1 },
    functionTitle: 'Creatief Strateeg',
  },
  {
    isEmployee: true,
    name: 'Maria Barmentlo',
    emailAddress: 'mbarmentlo@kaliber.net',
    srcs: { XS: maria_0, MD: maria_1 },
    functionTitle: 'Digital Designer',
  },
  {
    isEmployee: true,
    name: 'Maris Jacobs',
    emailAddress: 'mjacobs@kaliber.net',
    srcs: { XS: maris_0, MD: maris_1 },
    functionTitle: 'HR Manager',
  },
  {
    isEmployee: true,
    name: 'Melissa Bos',
    emailAddress: 'mbos@kaliber.net',
    srcs: { XS: melissa_0, MD: melissa_1 },
    functionTitle: 'Creative Front end Developer',
  },
  {
    isEmployee: true,
    name: 'Milly Held',
    emailAddress: 'mheld@kaliber.net',
    srcs: { XS: milly_0, MD: milly_1 },
    functionTitle: 'Creative Front end Developer',
  },
  {
    isEmployee: true,
    name: 'Peter Goddijn',
    emailAddress: 'pgoddijn@kaliber.net',
    srcs: { XS: peter_0, MD: peter_1 },
    functionTitle: 'DevOps Engineer',
  },
  {
    isEmployee: true,
    name: 'Roos Koene',
    emailAddress: 'rkoene@kaliber.net',
    srcs: { XS: roos_0, MD: roos_1 },
    functionTitle: 'Interactive Producer',
  },
  {
    isEmployee: true,
    name: 'Saskia Braakman',
    emailAddress: 'sbraakman@kaliber.net',
    srcs: { XS: saskia_0, MD: saskia_1 },
    functionTitle: 'UX Designer',
  },
  {
    isEmployee: true,
    name: 'Luke Murphy',
    emailAddress: 'lmurphy@kaliber.net',
    srcs: { XS: luke_0, MD: luke_1 },
    functionTitle: 'Client Service Manager',
  },
  {
    isEmployee: true,
    name: 'Bianca Kamermans',
    emailAddress: 'bkamermans@kaliber.net',
    srcs: { XS: bianca_0, MD: bianca_1 },
    functionTitle: 'Interactive Producer',
  },
  {
    isEmployee: true,
    name: 'Albert Smit',
    emailAddress: 'asmit@kaliber.net',
    srcs: { XS: albert_0, MD: albert_1 },
    functionTitle: 'Creative Front end Developer',
  },
  {
    isEmployee: true,
    name: 'Sebastiaan Laurentius',
    emailAddress: 'slaurentius@kaliber.net',
    srcs: { XS: sebastiaan_0, MD: sebastiaan_1 },
    functionTitle: 'Traffic Manager',
  },
]
