import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { LinkButton, LinkBlock } from '/components/buildingBlocks/Link'
import { routes, determineDocumentPath } from '/routes'

import styles from './FeaturedArticle.css'

export function FeaturedArticle({ title, slug, contributors, image }) {
  const link = determineDocumentPath({ document: { _type: 'article', slug: { current: slug } }, routes })

  return (
    <div className={styles.component}>
      <CenteredContainer width='lg'>
        <div className={styles.layout}>
          <div className={styles.header}>
            <h2 className={styles.heading}>
              Inspiratie
            </h2>
            <div className={styles.introduction}>
              Elke dag verkennen we de veranderende wereld om ons heen. Wat we van die ontdekkingsdrift leren? We delen het hier.
            </div>
          </div>
          <div className={styles.card}>
            <Card {...{ title, link, contributors, image }} />
          </div>
          <div className={styles.linkButton}>
            <LinkButton to={routes.articles.path}>Alle inspiratie posts</LinkButton>
          </div>
        </div>
      </CenteredContainer>
    </div>
  )
}

function Card({ title, link, contributors, image }) {
  const { imageBuilder } = useSanityClient()

  return (
    <LinkBlock to={link} layoutClassName={styles.componentCard}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div> {/* TODO: Replace with new (redesign) heading compponent https://kaliber.atlassian.net/browse/KALM-81 */}
        {contributors && (
          <div className={styles.contributors}>
            {contributors.map((x, index) => (
              <span key={x._id}>
                {x.firstName}, {x.role} {index < contributors.length - 1 ? '• ' : ''}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className={styles.coverImage}>
        <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.image} />
      </div>
    </LinkBlock>
  )
}
