import { Carousel as CarouselBase } from '/components/buildingBlocks/Carousel'
import { FixedRatioContainer } from '/components/buildingBlocks/FixedRatioContainer'
import { useViewport } from '/machinery/Viewport'
import { validateSrcs } from '/machinery/validateSrcs'

export function CarouselFromSanity({ entries }) {
  return (
    <CarouselBase>
      {entries.map(({ src, alt }, i) => (
        <FixedRatioContainer ratio={9 / 16} key={i}>
          <img {...{ src, alt }} />
        </FixedRatioContainer>
      ))}
    </CarouselBase>
  )
}

// Candidate for deletion
export function Carousel({ entries }) {
  return (
    <CarouselBase>
      {entries.map(({ srcs, alt }, i) => <CarouselImage {...{ srcs, alt }} key={i} />)}
    </CarouselBase>
  )
}

function CarouselImage({ srcs: { XS, MD }, alt }) {
  /*
    srcs expects 2 image sources with different sizes:
    XS: max-width=768,
    MD: max-width=1280
  */
  validateSrcs({ XS, MD }, { XS: { maxWidth: 768 }, MD: { maxWidth: 1280 } })
  const { viewportMd } = useViewport()
  return (
    <FixedRatioContainer ratio={9 / 16}>
      <img src={viewportMd ? MD : XS} {...{ alt }} />
    </FixedRatioContainer>
  )
}
