import { PageLayout } from '/components/aboutUs/pageOnly/PageLayout'
import { ContainerDark, ContainerColored } from '/components/aboutUs/pageOnly/Container'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { Section } from '/components/aboutUs/pageOnly/Section'
import { VideoWithFollowCursor } from '/components/buildingBlocks/Video'

import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'

WordMiljonair.meta = {
  title: 'Word Miljonair',
  description: '',
}

export function WordMiljonair() {
  useSetDocumentTitle(WordMiljonair.meta.title)
  return (
    <PageLayout>
      <ContainerColored>
        <Section backgroundColor='#000'>
          <CenteredContainer width='md'>
            <ContainerDark>
              <VideoWithFollowCursor
                muted={false}
                ratio={718 / 1280}
                src='/pages/assets/rick-astley.mp4'
                poster='/pages/assets/wordmiljonair.png'
              />
            </ContainerDark>
          </CenteredContainer>
        </Section>
      </ContainerColored>
    </PageLayout>
  )
}
