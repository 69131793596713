import { useIsInViewport } from '@kaliber/use-is-in-viewport'
import { mergeRefs } from '/machinery/mergeRefs'
import { useVideoQualityLevel } from '/machinery/useVideoQualityLevel'
import styles from './BackgroundVideo.css'

export function BackgroundVideo({ sources, onCanPlay = undefined, layoutClassName = undefined }) {
  const videoRef = React.useRef(null)
  const { ref: visibilityRef, isInViewport } = useIsInViewport()
  const { src, ref: qualityRef } = useVideoQualityLevel({ sources })

  React.useEffect(
    () => {
      if (isInViewport) videoRef.current.play()?.catch(() => {})
      else videoRef.current.pause()
    },
    [isInViewport]
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <video
        className={styles.video}
        controlsList="nodownload"
        loop
        muted
        playsInline
        autoPlay
        preload="metadata"
        ref={mergeRefs(videoRef, qualityRef, visibilityRef)}
        {...{ src, onCanPlay }}
      />
    </div>
  )
}
