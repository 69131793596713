import { useTransition, animated } from 'react-spring'
import { SrOnly } from '/components/buildingBlocks/SrOnly'
import { IconCross } from '/components/buildingBlocks/Icon'
import { VideoTommy } from '/components/buildingBlocks/Video'
import { LinkExternal } from '/components/buildingBlocks/Link'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'
import { useMenuState } from '/machinery/MenuState'
import { Link as LinkBase } from '@reach/router'
import FocusTrap from 'focus-trap-react'
import { routes } from '/routes'
import cx from 'classnames'

import styles from './Menu.css'

export function Menu({ layoutClassName, items }) {
  const { menuOpen } = useMenuState()
  const transition = useTransition(menuOpen, {
    from: { transform: `translateY(-100%)`, opacity: 0 },
    enter: [
      { transform: `translateY(0%)` },
      { opacity: 1 }
    ],
    leave: [
      { opacity: 0 },
      { transform: `translateY(-100%)` }
    ],
    config: { mass: 10, tension: 270, friction: 30, clamp: true },
  })

  return (
    transition((style, menuOpen ) => menuOpen && (
      <animated.div
        style={{ transform: style.transform }}
        className={cx(styles.component, layoutClassName)}
      >
        <animated.div
          className={styles.inner}
          style={{ transform: style.opacity }}
        >
          {items && ( <MenuContent {... { items }} /> )}
        </animated.div>
      </animated.div>
    ))
  )
}

function MenuContent({ items }) {
  const menuRef = React.useRef()
  useBodyScrollLock(menuRef)

  return (
    <FocusTrap>
      <div className={styles.component_rootContent} ref={menuRef}>
        <CloseButton layoutClassName={styles.closeButtonLayout} />
        <div className={styles.content}>
          <Video layoutClassName={styles.videoLayout} />
          <div className={styles.menus}>
            <MainMenu layoutClassName={styles.mainMenuLayout} {... { items }} />
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}

function CloseButton({ layoutClassName }) {
  const { setMenuOpen } = useMenuState()
  return (
    <button
      className={cx(styles.componentCloseButton, layoutClassName)}
      title='Sluit menu'
      onClick={() => { setMenuOpen(false) }}
    >
      <IconCross />
      <SrOnly>Sluit menu</SrOnly>
    </button>
  )
}

function Video({ layoutClassName }) {
  return <div className={cx(styles.componentVideo, layoutClassName)}><VideoTommy /></div>
}

function MainMenu({ layoutClassName, items }) {
  return (
    <nav className={cx(styles.componentMainMenu, layoutClassName)}>
      {items ? <DynamicMenu {... { items }} /> : <Fallback />}
    </nav>
  )
}

function DynamicMenu({ items }) {
  return items?.map((x) =>
    x._type === 'internalLink'
      ? <PageLink key={x._key} to={x.href}> {x.label} </PageLink>
      : <LinkExternal key={x._key} dataX="link" to={x.href}> {x.label} </LinkExternal>
  )
}

/**
 * Remove when menu has gone live.
 */
function Fallback() {
  return (
    <>
      <PageLink to={routes.home.path}>Home</PageLink>
      <PageLink to={routes.aboutUs.path}>Over ons</PageLink>
      <PageLink to={routes.cases.path}>Cases</PageLink>
      <LinkExternal to='https://werkenbij.kaliber.net/' target='_blank' >
        Werken bij
      </LinkExternal>
    </>
  )
}

function PageLink({ to, children }) {
  return <PageLinkBase {...{ to, children }} />
}

function PageLinkBase({ to, children }) {
  const { setMenuOpen } = useMenuState()

  return (
    <LinkBase {...{ to, onClick }} className={styles.componentPageLinkBase}>
      {children}
    </LinkBase>
  )

  function onClick() {
    setMenuOpen(false)
  }
}
