import { ButtonGhost, ButtonLink } from '/components/buildingBlocks/Button'
import { LinkText } from '/components/buildingBlocks/Link'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { CenteredContainer } from './buildingBlocks/CenteredContainer'
import { routes } from '/routes'
import { reportError } from '/machinery/reportError'
import { useTrackingSettings } from '/machinery/TrackingSettings'
import styles from './CookieBar.css'

export function CookieBar() {
  const [trackingSettings, setTrackingSettings] = useTrackingSettings()
  const originalTrackingSettingsRef = React.useRef(trackingSettings)
  const isMounted = useRenderOnMount()
  const { isVisible, isLoading } = useStoreHttpOnlyCookie({ trackingSettings, originalTrackingSettingsRef })

  React.useEffect(() => { if (trackingSettings) { emitDataLayerEvent(trackingSettings) } }, [trackingSettings] )

  return (
    (!isMounted || !isVisible) ? null :
    <aside className={cx(styles.component, isLoading && styles.isLoading)}>
      <CenteredContainer width='xl'>
        <div className={styles.contentContainer}>
          <div>
            Wij maken op deze website gebruik van cookies en vergelijkbare technieken om bezoek te analyseren en om je relevante boodschappen te tonen op social media. Door op ‘OK’ te klikken geef je toestemming voor de plaatsing ervan en het verwerken van op deze wijze verkregen persoonsgegevens, zoals in ons privacy-& cookiestatement wordt vermeld. <LinkText to={routes.legalPrivacy.path}>Lees meer</LinkText>.
          </div>
          <div className={styles.buttons}>
            <ButtonGhost onClick={() => setTrackingSettings('accepted')}>Ok</ButtonGhost>
            <ButtonLink onClick={() => setTrackingSettings('rejected')}>Liever niet</ButtonLink>
          </div>
        </div>
      </CenteredContainer>
    </aside>
  )
}

function emitDataLayerEvent(settings) {
  window.dataLayer && window.dataLayer.push({
    event: 'tracking_cookies',
    state: settings,
    anonymizeIp: settings === 'accepted' ? 'false' : 'true',
    allowAdFeatures: settings === 'accepted' ? 'true' : 'false'
  })
}

function storeHttpOnlyReducer(_, { type }) {
  switch (type) {
    case 'loading':
      return { isVisible: true, isLoading: true }
    case 'done':
      return { isVisible: false, isLoading: false }
    default:
      reportError('Invalid action type')
      throw new Error()
  }
}

function useStoreHttpOnlyCookie({ trackingSettings, originalTrackingSettingsRef }) {
  const [state, dispatch] = React.useReducer(storeHttpOnlyReducer, { isVisible: !trackingSettings, isLoading: false })

  React.useEffect(
    () => {
      let valid = true

      if (originalTrackingSettingsRef.current !== trackingSettings) storeCookie()

      return () => { valid = false }

      async function storeCookie() {
        try {
          dispatch({ type: 'loading' })
          await fetch(`/save-tracking-settings/?allow-tracking=${trackingSettings}`)
          if (!valid) return
          dispatch({ type: 'done' })
        } catch (err) {
          reportError(err)
          dispatch({ type: 'done' })
        }
      }
    },
    [trackingSettings, originalTrackingSettingsRef]
  )

  return state
}
