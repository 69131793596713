import { PageLayout, PageTitle } from '/components/legal/pageOnly/Page'
import { Section } from '/components/legal/pageOnly/Section'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'

LegalCopyright.meta = {
  title: 'Copyright',
  description: 'Deze website en onderdelen daarvan mogen niet worden gewijzigd, doorgestuurd, verspreid, verveelvoudigd of openbaar gemaakt, tenzij voor persoonlijk gebruik of met toestemming van Kaliber.',
}

export function LegalCopyright() {
  useSetDocumentTitle(LegalCopyright.meta.title)
  return (
    <PageLayout>
      <PageTitle>Copyright</PageTitle>
      <Section>
        <p>
          De auteursrechten en alle overige (intellectuele eigendoms-)rechten met betrekking tot de naam, het logo, de door Kaliber Interactive B.V. (‘Kaliber’) vervaardigde content (waaronder tekst en beeld), de look & feel en het format van deze website berusten uitsluitend bij Kaliber en/of bij haar licentiegevers. Kaliber behoudt zich deze rechten uitdrukkelijk voor.
        </p>
        <p>
          Deze website en onderdelen daarvan mogen niet worden gewijzigd, doorgestuurd, verspreid, verveelvoudigd of openbaar gemaakt, tenzij voor persoonlijk gebruik of met toestemming van Kaliber.
        </p>
        <p>
          Kaliber mag in voorkomende gevallen informatie van deze website verwijderen of (tijdelijk) ontoegankelijk maken als er sprake is van mogelijke inbreuk op rechten van derden. Kaliber heeft ook het recht om eventueel ingezonden tekst-, beeld- en/of geluidsmateriaal op welke manier dan ook aan te passen dan wel te verwijderen.
        </p>
      </Section>
    </PageLayout>
  )
}
