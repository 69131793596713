import styles from './JobsLink.css'
import { LinkBlock } from '/components/buildingBlocks/Link'
import { SrOnly } from '/components/buildingBlocks/SrOnly'
import { useWindowScroll } from '/machinery/WindowScroll'
import cx from 'classnames'

const JobsLinkFwd = React.forwardRef(JobsLink)

export { JobsLinkFwd as JobsLink }

function JobsLink({ layoutClassName, totalJobs }, ref)  {
  const { scrollY } = useWindowScroll()

  return (
    (!!totalJobs && scrollY < 500) &&
    <div className={cx(styles.component, layoutClassName)} {...{ ref }}>
      <LinkBlock rel="noreferrer" to='https://werkenbij.kaliber.net/' target='_blank'>
        <div className={styles.inner}>
          <SrOnly>Open vacatures bij Kaliber</SrOnly>
          <span className={styles.text}>Werken bij Kaliber</span>
        </div>
      </LinkBlock>
      {totalJobs && <span className={styles.count}>{totalJobs}</span>}
    </div>
  )
}
