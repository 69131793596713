import styles from './Marquee.css'

export function Marquee({ title = undefined, items, animationSpeed = '40s', layoutClassName = undefined }) {


  return (
    <div className={cx(styles.component, layoutClassName)} style={{ '--animation-speed': animationSpeed }} >
      {title && <h1 className={styles.heading}>{title}</h1>}
      <ul className={styles.list}>
        {items && items.map((item, index) => (
          <li className={styles.item} key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )
}
