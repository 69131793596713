import { CookieBar } from './CookieBar'
import { Logo } from './Logo'
import { MenuToggle } from './MenuToggle'
import { JobsLink } from './JobsLink'
import { PitchCasesLink } from './PitchCasesLink'
import { SiteFooter } from './SiteFooter'
import { SiteHeaderIntersectContextProvider } from './SiteHeaderIntersect'
import { useMenuState } from '/machinery/MenuState'
import cx from 'classnames'
import styles from './PageLayout.css'

export function PageLayoutHome({ totalJobs, children }) {
  return <PageLayoutBase withJobsLink {...{ totalJobs }}>{children}</PageLayoutBase>
}

export function PageLayoutAwardEntry({ children }) {
  return <PageLayoutBase awardEntry>{children}</PageLayoutBase>
}

export function PageLayoutPitchCases({ children }) {
  return <PageLayoutBase pitchCase>{children}</PageLayoutBase>
}

export function PageLayoutPitchCase({ children }) {
  return <PageLayoutBase pitchCase withPitchCasesLink>{children}</PageLayoutBase>
}

export function PageLayoutDefault({ children }) {
  return <PageLayoutBase>{children}</PageLayoutBase>
}

function PageLayoutBase({ children, withJobsLink = false, totalJobs = undefined, awardEntry = false, pitchCase = false, withPitchCasesLink = false }) {
  const logoRef = React.useRef()
  const menuToggleRef = React.useRef()
  const jobsLinkRef = React.useRef()
  const pitchCasesLinkRef = React.useRef()
  const { setMenuOpen, menuOpen } = useMenuState()
  const [logoColor, setLogoColor] = React.useState('transparent')
  const [menuToggleColor, setMenuToggleColor] = React.useState('transparent')

  const toggleMenu = React.useCallback(
    function toggleMenu() { setMenuOpen(x => !x) },
    [setMenuOpen]
  )

  React.useEffect(
    () => {
      menuOpen
        ? window.addEventListener('keyup', callback)
        : removeEventListener()
      return () => { removeEventListener() }
      function removeEventListener() { window.removeEventListener('keyup', callback) }
      function callback({ keyCode }) { if (keyCode === 27) setMenuOpen(false) }
    },
    [menuOpen, setMenuOpen]
  )

  return (
    <div className={styles.componentBase}>
      <div className={styles.inner}>
        <header className={cx(styles.header, withJobsLink && styles.withJobsLink)}>
          {awardEntry ? (
            <div className={styles.isHidden}>
              <Logo ref={logoRef} color={logoColor} />
            </div>
          ) : (
            <Logo ref={logoRef} color={logoColor} />
          )}
          {(withJobsLink && totalJobs) && <JobsLink ref={jobsLinkRef} layoutClassName={styles.jobsLinkLayout} {...{ totalJobs }} />}
          {withPitchCasesLink && <PitchCasesLink ref={pitchCasesLinkRef} layoutClassName={styles.jobsLinkLayout} />}
          <div className={cx(styles.menuToggle, (awardEntry || pitchCase) && styles.isHidden)}>
            <MenuToggle
              ref={menuToggleRef}
              color={menuToggleColor}
              onClick={toggleMenu}
              isOpen={menuOpen}
            />
          </div>
        </header>
        <div className={styles.main}>
          <SiteHeaderIntersectContextProvider
            {...{ logoRef, menuToggleRef, setLogoColor, setMenuToggleColor, children }}
          />
        </div>
        {!awardEntry && (<SiteFooter />)}
        <div className={styles.cookieBar}>
          <CookieBar />
        </div>
      </div>
    </div>
  )
}
