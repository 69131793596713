const HasMouseContext = React.createContext(null)

export function useHasMouse() {
  const context = React.useContext(HasMouseContext)
  if (!context)
    throw new Error('Please make sure HasMouseContextProvider is available')
  return context
}

export function HasMouseContextProvider({ children }) {
  const [hasMouse, setHasMouse] = React.useState(false)

  React.useEffect(
    () => { setHasMouse(window.matchMedia('(hover: hover)').matches) },
    []
  )

  const value = React.useMemo(() => ({ hasMouse }), [hasMouse])

  return (
    <HasMouseContext.Provider {...{ value }}>
      {children}
    </HasMouseContext.Provider>
  )
}
