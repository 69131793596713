import { Password } from '/components/buildingBlocks/Password'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { reportError } from '/machinery/reportError'
import { useConfig } from '/machinery/useConfig'
import Cookies from 'js-cookie'

export function PasswordProtectedPage({ children }) {
  const initialCookieValue = Cookies.get('isAuthorized') === 'ThisUserIsAuthorizedForPitchCases'
  const [isAuthorized, setIsAuthorized] = React.useState(initialCookieValue || false)
  const { stlCasesPassword } = useConfig()
  const isMounted = useRenderOnMount()

  if (!isMounted) return null

  return isAuthorized
    ? children
    : (
      <Password
        password={stlCasesPassword}
        onAuthorizeChange={handleAuthorizationChange}
        {...{ isAuthorized }}
      />
    )

  function handleAuthorizationChange() {
    try {
      Cookies.set('isAuthorized', 'ThisUserIsAuthorizedForPitchCases', { expires: 1 / 24 })
      setIsAuthorized(true)
    } catch (e) {
      console.log(e)
      reportError(e)
    }
  }
}
