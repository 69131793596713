import { LinkArrowBorderTransition } from '/components/buildingBlocks/Link'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'
import { SetSiteHeaderColorToBlack, SetSiteHeaderColorToBlackRef } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import image from './CallToAction.jpg?max-width=650'
import styles from './CallToAction.css'
import { themeColors } from '/machinery/Colors.js'

export function CallToAction() {
  return (
    <article className={styles.component}>
      <Header
        src={image}
        alt='Foto van een speelgoed robot'
        payoff='Making sense for future proof brands'
      />
      <SetSiteHeaderColorToBlack>
        <div className={styles.link}>
          <LinkArrowBorderTransition to='mailto:martin@kaliber.net' target='_blank' color={themeColors.blue}>
            Neem contact op met Martin voor een gesprek
          </LinkArrowBorderTransition>
        </div>
      </SetSiteHeaderColorToBlack>
    </article>
  )
}

function Header({ payoff, src, alt }) {
  const payoffWrapperRef = React.useRef(null)
  return (
    <header className={styles.componentHeader}>
      <div className={styles.imageWrapper}>
        <ImageObjectFit {...{ src, alt }} layoutClassName={styles.image} />
      </div>
      <SetSiteHeaderColorToBlackRef childRef={payoffWrapperRef}>
        <div className={styles.payoffWrapper} ref={payoffWrapperRef}>
          <h1 ref={payoffWrapperRef} className={styles.payoff}>{payoff}</h1>
        </div>
      </SetSiteHeaderColorToBlackRef>
    </header>
  )
}
