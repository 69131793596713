import { useViewport } from '/machinery/Viewport'
import { useIntersectObserver } from '/machinery/useIntersect'

const SiteHeaderContext = React.createContext(null)

export function useSiteHeaderIntersect({ ref, color }) {
  const context = React.useContext(SiteHeaderContext)
  if (!context)
    throw new Error('Please make sure SiteHeaderContextProvider is available')

  const { addTargetForLogo, setLogoColor, addTargetForMenuToggle, setMenuToggleColor } = context

  const logoColorCallback = React.useCallback(
    () => setLogoColor(color),
    [color, setLogoColor]
  )

  const menuToggleColorCallback = React.useCallback(
    () => setMenuToggleColor(color),
    [color, setMenuToggleColor]
  )

  React.useEffect(
    () => {
      const removeTarget = addTargetForLogo(ref.current, logoColorCallback)
      return () => { removeTarget() }
    },
    [ref, addTargetForLogo, logoColorCallback]
  )
  React.useEffect(
    () => {
      const removeTarget = addTargetForMenuToggle(ref.current, menuToggleColorCallback)
      return () => { removeTarget() }
    },
    [ref, addTargetForMenuToggle, menuToggleColorCallback]
  )
}

export function SiteHeaderIntersectContextProvider({ children, logoRef, setLogoColor, menuToggleRef, setMenuToggleColor }) {
  const addTargetForLogo = useIntersectObserver({ intersectionRect: useRefCenterRect(logoRef), debug: false })
  const addTargetForMenuToggle = useIntersectObserver({ intersectionRect: useRefCenterRect(menuToggleRef), debug: true })

  const value = React.useMemo(
    () => ({ setLogoColor, addTargetForLogo, setMenuToggleColor, addTargetForMenuToggle }),
    [setLogoColor, addTargetForLogo, setMenuToggleColor, addTargetForMenuToggle]
  )

  return <SiteHeaderContext.Provider {...{ value, children }} />
}

function useRefCenterRect(ref) {
  const { viewportWidth, viewportHeight } = useViewport()
  const [rect, setRect] = React.useState(
    { bottom: 0, height: 0, left: 0, right: 0, top: 0, width: 0 }
  )

  React.useEffect(
    () => {
      const { height, left, top, width } = ref.current.getBoundingClientRect()
      const halfHeightPosition = Math.round(top + height / 2)
      const halfWidthPosition = Math.round(left + width / 2)

      setRect({
        top: halfHeightPosition - 1,
        bottom: halfHeightPosition + 1,
        left: halfWidthPosition - 1,
        right: halfWidthPosition + 1,
        height: 2,
        width: 2,
      })
    },
    [ref, viewportWidth, viewportHeight]
  )

  return rect
}
