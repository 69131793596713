import ReactDOM from 'react-dom'
import FocusTrap from 'focus-trap-react'
import { SrOnly } from '/components/buildingBlocks/SrOnly'
import styles from './Modal.css'

const ModalContext = React.createContext(null)

function useModalRef() {
  const context = React.useContext(ModalContext)
  if (!context) throw new Error('Please make sure ModalContextProvider is available')
  return context
}

export function ModalContextProvider({ children, value }) {
  return <ModalContext.Provider {...{ value, children }} />
}

export function Modal({ children, label, onClose }) {
  const modalRef = React.useRef()

  React.useEffect(
    () => {
      window.addEventListener('keyup', callback)
      return () => { window.removeEventListener('keyup', callback) }
      function callback({ key }) { if (key === 'Escape') onClose() }
    },
    [onClose]
  )

  return (
    <Portal>
      <FocusTrap>
        <div role='dialog' aria-modal aria-labelledby='dialog-label' className={styles.component_root} ref={modalRef}>
          <SrOnly><h2 id='dialog-label'>{label}</h2></SrOnly>
          <div className={styles.content}>
            {children}
          </div>
        </div>
      </FocusTrap>
    </Portal>
  )
}

function Portal({ children }) {
  const { modalRef } = useModalRef()
  return ReactDOM.createPortal(children, modalRef.current)
}

export const ModalContainer = React.forwardRef(ActualModalContainer)

function ActualModalContainer({}, ref) {
  return <div ref={ref} className={styles._rootPortal} />
}
