import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { PageHeader } from '/components/pageOnly/buildingBlocks/PageHeader'
import chunk from 'lodash/chunk'
import { HeadingMd } from '/components/buildingBlocks/Heading'
import { UnorderedList } from '/components/buildingBlocks/UnorderedList'
import { useSanityClient } from '/machinery/SanityClient'
import { Image } from '/components/Image'
import { BackgroundVideo } from '/components/backgroundVideo/BackgroundVideo'
import { BlockContent } from '/components/buildingBlocks/BlockContent'

import styles from './Header.css'

export function HeaderFromSanity({ children, isLight = false, image, video }) {
  const hasVideo = video
  const hasImage = image?.asset?.url

  return (
    <Header
      componentMedia={() => (
        hasVideo ? <BackgroundVideo sources={{
          1080: video?.quality1080,
          720: video?.quality720,
          540: video?.quality540,
          360: video?.quality360
        }} /> :
        hasImage ? <Picture {...{ image }} /> :
        null
      )}
      {...{ children, isLight }}
    />
  )
}

function Picture({ image }) {
  const { imageBuilder } = useSanityClient()
  return (
    <div className={styles.componentPicture}>
      <Image fit='cover' builder={imageBuilder} {...{ image }} layoutClassName={styles.image} />
    </div>
  )
}

function Header({ children, componentMedia, isLight }) {
  return (
    <PageHeader {...{ isLight, componentMedia }}>
      <CenteredContainer width='md'>{children}</CenteredContainer>
    </PageHeader>
  )
}

export { HeadingCompactXl as HeaderHeading } from '/components/buildingBlocks/Heading'

export function HeaderHighlightedParagraph({ content, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentHighlightedParagraph, layoutClassName)}>
      <BlockContent blocks={content} />
    </div>
  )
}

export function HeaderParagraph({ children }) {
  return <div className={styles.componentParagraph}><p>{children}</p></div>
}

export function HeaderLogo({ image, alt, layoutClassName = undefined }) {
  const { imageBuilder } = useSanityClient()
  const { width, height } = image.asset.metadata.dimensions
  const size = Math.min(180, 120 * Math.max(width, height) / Math.min(width, height))
  return (
    <div className={cx(styles.componentLogo, layoutClassName)} style={{ width: size + 'px', height: size + 'px' }}>
      <img className={styles.logo} src={imageBuilder.image(image).height(200).url()} {...{ alt }} />
    </div>
  )
}

export function HeaderLogoStatic({ src, alt }) {
  return <img className={styles.componentLogoStatic} {...{ alt, src }} />
}

export function HeaderVerticalSpaceBetween({ children }) {
  return <div className={styles.componentVerticalSpaceBetween}>{children}</div>
}

export function HeaderVerticalSpaceBetweenLg({ children }) {
  return <div className={styles.componentVerticalSpaceBetweenLg}>{children}</div>
}

export function HeaderMeta({ result = false, expertise, relevance = false }) {
  const expertiseColumns = expertise.length > 4 ? 2 : 1
  return (
    <div className={styles.componentMeta}>
      {
        result
          ? (
            <MetaGroup
              title='Resultaat'
              items={result}
              columns={1}
              layoutClassName={styles.metaGroup1}
            />
          )
          : null
      }
      {
        expertise
          ? (
            <MetaGroup
              title='Expertises'
              items={expertise}
              columns={result && expertiseColumns}
              layoutClassName={styles['metaGroup' + expertiseColumns]}
            />
          )
          : null
      }
      {
        relevance
          ? (
            <MetaGroup
              title='Relevantie Pitch STL'
              items={relevance}
              columns={1}
              layoutClassName={styles.metaGroup3}
            />
          )
          : null
      }
    </div>
  )
}


// @TODO: rename
function MetaGroup({ title, items, columns, layoutClassName }) {
  const listGroups = chunk(items, Math.ceil(items.length / columns))

  return (
    <div className={layoutClassName}>
      <div className={styles.listHeading}><HeadingMd h={2}>{title}</HeadingMd></div>
      <div className={styles[`listGroupCount${listGroups.length}`]}>
        {listGroups.map((items, index) =>
          <UnorderedList key={`list-${index}`}>
            {items.map((item, index) => <li key={`item-${index}`}>{item}</li>)}
          </UnorderedList>
        )}
      </div>
    </div>
  )
}
