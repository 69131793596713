import isEmpty from 'lodash/isEmpty'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { HeaderFromSanity as Header } from '/components/article/pageOnly/Header'
import { PageLayoutDefault } from '/components/pageOnly/PageLayout'
import { FlexibleContentGeneric } from '/components/pageOnly/FlexibleContentGeneric'
import { getSingletonTypeBySlug } from '/machinery/groqSnippets'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { NextItem } from '/components/pageOnly/NextItem'
import { Section } from '/components/home/pageOnly/Section'
import { ContainerDark } from '/components/home/Container'

import groq from 'groq'

import styles from './Article.css'

const query = groq`
  ${getSingletonTypeBySlug('article')} {
    "id": _id,
    title,
    header {
      ...,
      logo {
        ...,
        asset->
      },
      headerImage {
        ...,
        asset->
      }
    },
    contributors[]-> {
      _id,
      firstName,
      role,
      image
    },
    body,
    meta,
    documentOrder,
    publicationDate,
    publication,
    flexibleContent[] {
      ...,
      _type == 'caseVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'mobileVideo' => {
        ...,
        "videoSrc": videoSrc.asset->url
      },
      _type == 'textWithMedia' => {
        ...,
        mediaType == 'video' => {
          "videoSrc": videoSrc.asset->url
        }
      }
    },
    "previousItem": coalesce(
      *[_type == "article" && hidden != true && (^.documentOrder > documentOrder)] | order(documentOrder desc)[0],
      *[_type == "article" && hidden != true && (^.documentOrder < documentOrder)] | order(documentOrder desc)[0],
    ){
      header {
        client,
        headerImage {
          ...,
          asset->
        }
      },
      slug,
      title,
      hidden
    },
    "nextItem": coalesce(
      *[_type == "article" && hidden != true  && (^.documentOrder < documentOrder)] | order(documentOrder)[0],
      *[_type == "article" && hidden != true && (^.documentOrder > documentOrder)] | order(documentOrder)[0],
    ){
      header {
        client,
        headerImage {
          ...,
          asset->
        }
      },
      slug,
      title,
      hidden
    }
  }
`

Article.meta = {
  async fetchData(sanityClient, { isPreview = false, params: { slug } }) {
    const data = await sanityClient.fetch(query, { isPreview, slug })
    return { data, status: isEmpty(data) ? 404 : 200 }
  }
}

export function Article() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle || data?.title)

  return (
    <article>
      {isLoading && <LoadingStatus />}
      {isError && <ErrorStatus />}
      {data && <Page {...{ data }} />}
    </article>
  )
}

function Page({ data }) {
  const { flexibleContent, title, header, contributors, publicationDate, publication, previousItem, nextItem } = data ?? {}
  const { headerImage, headerVideo, intro } = header ?? {}

  return (
    <div className={styles.componentPage}>
      <PageLayoutDefault>
        {header && (
          <Header video={headerVideo} image={headerImage} {...{ intro, title, contributors, publicationDate, publication }} />
        )}

        {intro && (
          <CenteredContainer width='md'>
            <div className={styles.introduction}>
              {intro}
            </div>
          </CenteredContainer>
        )}

        {flexibleContent && (
          <FlexibleContentGeneric {...{ flexibleContent }} />
        )}

        {(nextItem || previousItem) && (
          <ContainerDark>
            <Section backgroundColor='black' color='white'>
              <NextItem
                {...{ previousItem, nextItem }}
                type='article'
              />
            </Section>
          </ContainerDark>
        )}
      </PageLayoutDefault>
    </div>
  )
}

function LoadingStatus() {
  return <p>Laden...</p>
}

function ErrorStatus() {
  return <p>Er is een fout opgetreden</p>
}
