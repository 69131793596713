import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { ContainerLight } from '/components/home/Container'
import { Section } from '/components/home/pageOnly/Section'
import { PageLayout } from '/components/articles/pageOnly/PageLayout'
import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { Header } from '/components/articles/pageOnly/Header'
import { getPreviewForOverviewType } from '/machinery/groqSnippets'
import { Team as TeamMembers } from '/components/team/pageOnly/Team'
import groq from 'groq'

const query = groq`
  ${getPreviewForOverviewType('employee')} | order(_updatedAt desc) [0] {
    "employees": *[_type == "employee" && formerEmployee != true] | order(orderRank) {
      ...,
      asset->
    },
    "jobs": *[_type == "jobs"][0] {
      totalJobs,
      highlightedJobs
    }
  }
`

Team.meta = {
  title: 'Ons Team',
  description:
    '',
  async fetchData(sanityClient, { isPreview = false }) {
    const team = await sanityClient.fetch(query, { isPreview })
    return {
      data: { team },
      status: 200
    }
  }
}

export function Team() {
  const { isLoading, isError, data } = usePageRouteData()
  useSetDocumentTitle(data?.meta?.pageTitle)

  return (
    <PageLayout>
      <Header
        title='Ons Team'
        intro='In iedereen die bij Kaliber werkt schuilt een Tommy vol ontdekkingsdrift. Ontdek wie ze zijn en wat hen beweegt.'
      />
      <ContainerLight>
        <Section backgroundColor='white' color='black'>
          <CenteredContainer width='xl'>
            {isLoading && <p>Laden...</p>}
            {isError && <p>Er is een fout opgetreden</p>}
            {data && <Page {...{ data }} />}
          </CenteredContainer>
        </Section>
      </ContainerLight>
    </PageLayout>
  )
}

function Page({ data }) {
  const employees = data?.team?.employees ?? {}
  const jobData = data?.team?.jobs // TODO KALM-291

  return (
    <TeamMembers {...{ employees }} />
  )
}
