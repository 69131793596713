import { LabelTheme } from "./Label"

import styles from './ImageObjectFit.css'

export const ImageObjectFit = React.forwardRef(ActualImageObjectFit)
function ActualImageObjectFit({ src, alt, objectFit = 'cover', layoutClassName = 'undefined', labelText = false, labelColors = {} }, ref) {
  const [supportsObjectFit, setSupportsObjectFit] = React.useState(true)
  React.useEffect(
    () => { if ('objectFit' in document.documentElement.style === false) setSupportsObjectFit(false) },
    []
  )

  return supportsObjectFit
    ? <Image {...{ ref, src, alt, objectFit, layoutClassName, labelText, labelColors }} />
    : <BackgroundImage {...{ ref, src, alt, objectFit, layoutClassName }} />
}

const Image = React.forwardRef(ActualImage)
function ActualImage({ src, alt, objectFit, layoutClassName, labelText, labelColors }, ref) {
  return (
    <div className={cx(layoutClassName, styles.imageContainer)}>
      <img {...{ ref, src, alt }} className={styles.image} style={{ objectFit }} />

      {Array.isArray(labelText) && (
        <div className={styles.labelsOverlay}>
          {labelText?.map((label, index) => (
            <LabelTheme key={index} customColor={labelColors[label]}>
              {label}
            </LabelTheme>
          ))}
        </div>
      )}
    </div>
  )
}

const BackgroundImage = React.forwardRef(ActualBackgroundImage)
function ActualBackgroundImage({ src, alt, objectFit, layoutClassName }, ref) {
  return (
    <div
      aria-label={alt}
      className={layoutClassName}
      role='img'
      style={{
        backgroundImage: `url(${src})`,
        backgroundPosition: 'center',
        backgroundSize: objectFit
      }}
      {...{ ref }}
    />
  )
}
