const WindowScrollContext = React.createContext(null)

export function useWindowScroll() {
  const scroll = React.useContext(WindowScrollContext)

  if (!scroll)
    throw new Error('Please make sure WindowScrollContextProvider is available')
  return scroll
}

export function WindowScrollContextProvider({ children }) {
  const scroll = useRawWindowScroll()
  return (
    <WindowScrollContext.Provider value={scroll}>{children}</WindowScrollContext.Provider>
  )
}

function useRawWindowScroll() {
  const [scroll, setScroll] = React.useState({ scrollX: 0, scrollY: 0 })

  /*
    use pageXOffset and pageYOffset instead of scrollX and scrollY for ie11 support
  */
  React.useEffect(
    () => setScroll({ scrollX: window.pageXOffset, scrollY: window.pageYOffset }),
    []
  )

  React.useEffect(
    () => {
      window.addEventListener('scroll', handleScroll)
      return () => { window.removeEventListener('scroll', handleScroll) }
    },
    []
  )

  function handleScroll() {
    setScroll({ scrollX: window.pageXOffset, scrollY: window.pageYOffset })
  }

  return scroll
}
