import cx from 'classnames'
import styles from './UnorderedList.css'

export function UnorderedList({ children }) {
  return <UnorderedListBase className={styles.component}>{children}</UnorderedListBase>
}

function UnorderedListBase({ children, className }) {
  return <ul className={cx(styles.componentBase, className)}>{children}</ul>
}
