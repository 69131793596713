import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { useViewport } from '/machinery/Viewport'
import { FixedRatioContainer } from '/components/buildingBlocks/FixedRatioContainer'

import styles from './Dobbelsteen.css'

Dobbelsteen.meta = {
  title: 'Caspars Kaliber Dobbelsteen!',
  description: '',
}

export function Dobbelsteen() {
  useSetDocumentTitle(Dobbelsteen.meta.title)
  const { viewportLg } = useViewport()

  return (
    <div className={styles.page}>
      {viewportLg ? <Placeholder /> : <DobbelsteenPlayer />}
    </div>
  )
}

function Placeholder() {
  return (
    <div className={styles.componentPlaceholder}>
      <img className={styles.space} src="/pages/assets/dobbelsteen/background-placeholder.jpg" alt="background" />
    </div>
  )
}

const SCENE_INTRO = 'intro'
const SCENE_PLAYER = 'player'
const SCENE_AGAIN = 'again'


function DobbelsteenPlayer() {
  const { size } = useViewport()
  const videoRef = React.useRef()
  const [scene, setScene] = React.useState(SCENE_INTRO)
  const [current, setCurrent] = React.useState({ src: null, label: null })
  const showPlayer = [SCENE_PLAYER, SCENE_AGAIN].includes(scene)

  const backgroundOverlay = React.useMemo(() => {
    const backgrounds = {
      [SCENE_INTRO]: 'overlay-button.png',
      [SCENE_PLAYER]: `overlay-player-${size.toLowerCase()}.png`,
      [SCENE_AGAIN]:  `overlay-player-${size.toLowerCase()}.png`,
    }

    return backgrounds[scene]
  }, [scene, size])

  React.useEffect(() => { throwDobbelsteen() }, [])

  return (
    <div className={styles.componentPlayer}>
      <img className={styles.background} src="/pages/assets/dobbelsteen/background.jpg" alt="background" />
      <img className={styles.paper} src={`/pages/assets/dobbelsteen/${backgroundOverlay}`} alt="paper" />

      <div className={styles.container}>
        {scene === SCENE_INTRO && <>
          <img className={styles.arrow} src="/pages/assets/dobbelsteen/arrow.png" alt="arrow" />
          <button onClick={handleStartPlayer} className={styles.content}>
            <img className={styles.button} src="/pages/assets/dobbelsteen/button.png" alt="button" />
            <h2 className={styles.title}>Druk hier om te dobbelen</h2>
          </button>
        </>}

        <div className={styles.player} style={{ display: showPlayer ? 'block' : 'none' }}>
          <img className={styles.frame} src="/pages/assets/dobbelsteen/frame.png" alt="frame" />
          <div className={styles.videoFrame}>
            <FixedRatioContainer ratio={1 / 1}>
              <video className={styles.video} playsInline ref={videoRef} src={current.src} onEnded={handleOnEnded} />
            </FixedRatioContainer>
          </div>

          {scene === SCENE_AGAIN && <button className={styles.againButton} onClick={throwAgain}>
            <img className={styles.againImage} src="/pages/assets/dobbelsteen/button.png" alt="button" />
          </button>}

          {scene === SCENE_PLAYER && <strong className={styles.number}>{current.label}!</strong>}
          {scene === SCENE_AGAIN && <strong className={styles.againTitle}>Gooi opnieuw</strong>}
        </div>
      </div>
    </div>
  )

  function throwDobbelsteen() {
    const index = generateRandomNumber({ min: 1, max: 6 })
    setCurrent({
      src: `/pages/assets/dobbelsteen/video/${index}.mp4`,
      label: ['Één', 'Twee', 'Drie', 'Vier', 'Vijf', 'Zes'][index - 1]
    })
  }

  function throwAgain() {
    throwDobbelsteen()
    setScene(SCENE_INTRO)
  }

  function handleOnEnded() {
    setScene(SCENE_AGAIN)
  }

  function handleStartPlayer() {
    setScene(SCENE_PLAYER)
    videoRef.current && videoRef.current.play()?.catch(() => {})
  }
}

function generateRandomNumber({ min, max }) {
  return Math.floor(Math.random() * (max - min)) + min
}
