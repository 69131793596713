/* eslint-disable import/no-duplicates */
import carousel1_0 from '/components/aboutUs/assets/carousel-1.jpg?max-width=768'
import carousel1_1 from '/components/aboutUs/assets/carousel-1.jpg?max-width=1280'
import carousel2_0 from '/components/aboutUs/assets/carousel-2.jpg?max-width=768'
import carousel2_1 from '/components/aboutUs/assets/carousel-2.jpg?max-width=1280'
import carousel3_0 from '/components/aboutUs/assets/carousel-3.jpg?max-width=768'
import carousel3_1 from '/components/aboutUs/assets/carousel-3.jpg?max-width=1280'
import carousel4_0 from '/components/aboutUs/assets/carousel-4.jpg?max-width=768'
import carousel4_1 from '/components/aboutUs/assets/carousel-4.jpg?max-width=1280'
import carousel5_0 from '/components/aboutUs/assets/carousel-5.jpg?max-width=768'
import carousel5_1 from '/components/aboutUs/assets/carousel-5.jpg?max-width=1280'
import carousel6_0 from '/components/aboutUs/assets/carousel-6.jpg?max-width=768'
import carousel6_1 from '/components/aboutUs/assets/carousel-6.jpg?max-width=1280'
import carousel7_0 from '/components/aboutUs/assets/carousel-7.jpg?max-width=768'
import carousel7_1 from '/components/aboutUs/assets/carousel-7.jpg?max-width=1280'
/* eslint-enable import/no-duplicates */

export const carouselEntries = [
  {
    alt: 'Foto van de buitenkant van ons clubhuis',
    srcs: { XS: carousel1_0, MD: carousel1_1 },
    caption: 'Ons clubhuis in hartje Utrecht: de pas gerenoveerde broodjesfabriek \'De Lubro\'.'
  },
  {
    alt: 'Foto van de binnenkant van ons clubhuis',
    srcs: { XS: carousel2_0, MD: carousel2_1 },
    caption: 'Een clubhuis met genoeg ruimte om geconcentreerd te rocken.'
  },
  {
    alt: 'Foto van ons Tommy logo aan de muur',
    srcs: { XS: carousel3_0, MD: carousel3_1 },
    caption: 'Doe wat je nog niet kan. Ontdek wat je nog niet wist. Reik naar waar je nét niet bij kan. Wees Tommy!'
  },
  {
    alt: 'Foto van de bovenverdieping van ons clubhuis',
    srcs: { XS: carousel4_0, MD: carousel4_1 },
    caption: 'Ons clubhuis in hartje Utrecht: de pas gerenoveerde broodjesfabriek \'De Lubro\''
  },
  {
    alt: 'Foto van grafiti op een muur',
    srcs: { XS: carousel5_0, MD: carousel5_1 },
    caption: 'Geen gebouw in Utrecht is compleet zonder Kbtr.'
  },
  {
    alt: 'Foto van onze lichtgevende ping pong ballen muur',
    srcs: { XS: carousel6_0, MD: carousel6_1 },
    caption: 'Kaliber is een interactieve speeltuin met genoeg plek voor digitale spielerei.'
  },
  {
    alt: 'Foto van een rubber eendje',
    srcs: { XS: carousel7_0, MD: carousel7_1 },
    caption: 'Neem je werk serieus, maar jezelf niet té.'
  }
]
