import { useViewport } from './Viewport'
import difference from 'lodash/difference'


export function useIntersectObserver({ intersectionRect, debug = false }) {
  const observerRef = React.useRef(null)
  const targetCallbacksRef = React.useRef(new Map())
  const viewport = useViewport()
  const rootMargin = React.useMemo(
    () => intersectionRectToRootMargin(intersectionRect, viewport),
    [viewport, intersectionRect]
  )
  const [lastTargetChange, setLastTargetChange] = React.useState(0)
  const lastTargetRef = React.useRef(null)

  const addTarget = React.useCallback(
    (target, callback) => {
      targetCallbacksRef.current.set(target, callback)
      setLastTargetChange(performance.now())

      return () => {
        setLastTargetChange(performance.now())
        targetCallbacksRef.current.delete(target)
        if (lastTargetRef.current === target) lastTargetRef.current = null
      }
    },
    []
  )

  React.useEffect(
    () => {
      observerRef.current = new window.IntersectionObserver(
        (entries) => {
          const intersectingTargets = entries.filter(x => x.isIntersecting).map(x => x.target)
          const potentialTargets = Array.from(targetCallbacksRef.current.keys())
          const target = intersectingTargets.length
            ? findFurthestFromRoot(intersectingTargets)
            : findParentTargetFurthestFromRoot(entries, potentialTargets)

          if (lastTargetRef.current !== target) callbackForTarget(target)
          lastTargetRef.current = target

          function callbackForTarget(target) {
            const callback = targetCallbacksRef.current.get(target)
            if (callback) callback()
          }
        },
        { rootMargin }
      )

      for (const target of targetCallbacksRef.current.keys()) observerRef.current.observe(target)
      return () => observerRef.current.disconnect()
    },
    [rootMargin, lastTargetChange] // the targets have changed so we need to check all targets (disconnect and observe on a new intersection observer)
  )

  return addTarget
}

function findFurthestFromRoot(targets) {
  return targets.reduce(
    (result, x) => (!result || result.contains(x)) ? x : result,
    null
  )
}

function findParentTargetFurthestFromRoot(entries, potentialTargets) {
  const entryTargets = entries.map(x => x.target)
  const closedToRootTarget = findClosestToRoot(entryTargets)
  const parentTargets = difference(potentialTargets, entryTargets).filter(x => x.contains(closedToRootTarget))
  return findFurthestFromRoot(parentTargets)
}

function findClosestToRoot(targets) {
  return targets.reduce(
    (result, x) => (!result || !result.contains(x)) ? x : result,
    null
  )
}

function intersectionRectToRootMargin(intersectionRect, viewport) {
  const { top, right, bottom, left } = intersectionRect
  const { viewportWidth, viewportHeight } = viewport

  return [
    top * -1 + 'px',
    (viewportWidth - right) * -1 + 'px',
    (viewportHeight - bottom) * -1 + 'px',
    left * -1 + 'px'
  ].join(' ')
}
