import styles from './Clients.css'

export function Clients({ clients }) {
  return (
    <section className={styles.component}>
      <div className={styles.layout}>
        <ul className={styles.grid}>
          {clients.map((x, i) => (
            <li key={i} className={styles.item}>
              <img src={x.logoSrc} className={styles.logo} alt={`Logo van ${x.name}`} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
