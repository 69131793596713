import styles from './Intro.css'
import { ImageOnHoverIntro } from '/components/home/ImageOnHover'
import { LinkButton } from '/components/buildingBlocks/Link'
import { routes } from '/routes'

export function Intro({ children }) {
  return (
    <IntroBase {...{ children }} />
  )
}

export function IntroWithButton({ children }) {
  return (
    <IntroBase {...{ children }} showButton />
  )
}

function IntroBase({ children, showButton = undefined }) {
  return (
    <article className={styles.componentBase}>
      <div className={styles.introText} style={{ position: 'relative', zIndex: 0 }}>{children}</div>
      {showButton && (<LinkButton to={routes.aboutUs.path}>Meer over ons</LinkButton>)}
    </article>
  )
}

export function IntroLink({ children, src, href = undefined }) {
  return (
    <ImageOnHoverIntro fallback={children} imageZIndex={1} {...{ src }}>
      {href
        ? <a {...{ href }} className={styles.componentLink}>{children}</a>
        : <span className={styles.componentLink}>{children}</span>
      }
    </ImageOnHoverIntro>
  )
}
