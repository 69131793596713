import { PageLayout, PageTitle } from '/components/legal/pageOnly/Page'
import { Section, SectionTitle } from '/components/legal/pageOnly/Section'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'

LegalDisclaimer.meta = {
  title: 'Disclaimer',
  description: '',
}

export function LegalDisclaimer() {
  useSetDocumentTitle(LegalDisclaimer.meta.title)
  return (
    <PageLayout>
      <PageTitle>Disclaimer</PageTitle>
      <Section>
        <p>
          Kaliber Interactive B.V. (‘Kaliber’), gevestigd te Utrecht, eigenaar van deze website, besteedt veel aandacht en zorg aan de inhoud en samenstelling van deze website. Desondanks kan Kaliber niet garanderen dat alle gegevens op deze website volledig en/of juist zijn. Kaliber is op geen enkele wijze aansprakelijk voor enigerlei directe of indirecte schade, van welke aard ook, die voortvloeit uit of in enig opzicht verband houdt met het gebruik en/of de al dan niet tijdelijke (on)bereikbaarheid van deze website. Kaliber is ook niet aansprakelijk voor de inhoud van artikelen, meningen of ander materiaal dat door derden op deze website is geplaatst of anderszins afkomstig is van derden.
        </p>
        <p>
          Kaliber staat er niet voor in dat de informatie op deze website geschikt is voor het doel waarvoor de informatie door jou wordt geraadpleegd. Alle informatie, producten en diensten worden aangeboden in de staat waarin deze zich feitelijk bevinden en zonder enige garantie of waarborg ten aanzien van hun deugdelijkheid, geschiktheid voor een bepaald doel of anderszins.
        </p>
        <p>
          De informatie op deze site wordt regelmatig aangevuld en/of aangepast. Wijzigingen kunnen altijd met onmiddellijke ingang en zonder enige kennisgeving worden aangebracht.
        </p>
        <p>
          Kaliber mag in voorkomende gevallen informatie van deze website of uit de community verwijderen of (tijdelijk) ontoegankelijk maken als er sprake is van mogelijke inbreuk op rechten van derden.
        </p>
      </Section>
      <Section>
        <SectionTitle>Links naar andere websites</SectionTitle>
        <p>
          Deze website bevat een aantal links naar andere websites. Kaliber is niet aansprakelijk voor de inhoud van die sites en/of de wijze waarop die sites omgaan met jouw (persoons)gegevens. Lees hiervoor het privacy beleid, de disclaimer, de copyright notice en algemene voorwaarden, indien aanwezig, van de site die je bezoekt.
        </p>
      </Section>
    </PageLayout>
  )
}
