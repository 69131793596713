import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { PageLayoutDefault } from '/components/pageOnly/PageLayout'
import { SetSiteHeaderColorToBlack } from '/components/buildingBlocks/SetSiteHeaderColorTo'
import styles from './Page.css'

export { HeadingXl as PageTitle } from '/components/buildingBlocks/Heading'

export function PageLayout({ children }) {
  return (
    <PageLayoutDefault>
      <SetSiteHeaderColorToBlack>
        <div className={styles.componentLayout}>
          <CenteredContainer width='md'>{children}</CenteredContainer>
        </div>
      </SetSiteHeaderColorToBlack>
    </PageLayoutDefault>
  )
}
