import mediaStyles from '/cssGlobal/media.css'

export const BREAKPOINT_MD = parseInt(mediaStyles['breakpoint-md'], 10)

export const BREAKPOINTS = [
  { name: 'XS', minWidth: 0 },
  { name: 'SM', minWidth: parseInt(mediaStyles['breakpoint-sm'], 10) },
  { name: 'MD', minWidth: BREAKPOINT_MD },
  { name: 'LG', minWidth: parseInt(mediaStyles['breakpoint-lg'], 10) },
  { name: 'XL', minWidth: parseInt(mediaStyles['breakpoint-xl'], 10) },
]
