import { Image } from '/components/Image'
import { useSanityClient } from '/machinery/SanityClient'
import { sequence } from '@kaliber/math'
import { VideoVimeo } from '/components/buildingBlocks/Video'
import { FlexibleContentGeneric } from '/components/pageOnly/FlexibleContentGeneric'
import { TagsMd } from '/components/experiments/Tags'
import { useBodyScrollLock } from '/machinery/useBodyScrollLock'

import styles from './ModalNew.css'

export function ModalNew({ onActiveChange, layoutClassName, onClose, item, visible }) {
  const { imageBuilder } = useSanityClient()
  const { ref: bodyScrollLockRef } = useBodyScrollLock({ enabled: visible })

  React.useEffect(
    () => {
      window.addEventListener('keyup', callback)
      return () => { window.removeEventListener('keyup', callback) }
      function callback({ key }) { if (key === 'Escape') onClose() }
    },
    [onClose]
  )

  return (
    <div className={cx(styles.component, layoutClassName)} ref={bodyScrollLockRef}>
      <div className={styles.content}>
        <div className={styles.text}>
          <h3 className={styles.title}>{item.title}</h3>
          {item.flexibleContent && (
            <FlexibleContentGeneric flexibleContent={item.flexibleContent} />
          )}
          <div className={styles.tags}>
            {item.tags && <TagsMd tags={item.tags} />}
          </div>
        </div>
      </div>
      <div className={styles.imageAndTitle}>
        <Marquee title={item.title} layoutClassName={styles.marqueeLayout} />
        <div className={styles.coverImage}>
          {item.media.src && (<Image fit='cover' builder={imageBuilder} image={item.media.src} layoutClassName={styles.imageLayout} />)}
          {(item.sources && item.showVideo) && (
            <VideoVimeo
              ratio={720 / 1280}
              sources={item.sources}
              poster={item.media.src ? imageBuilder.image(item.media.src).width(1280).height(720).url() : null}
              autoPlay
              loop
              layoutClassName={styles.videoLayout}
            />
          )}
        </div>
      </div>
      <div className={styles.buttonWrapper}><button className={styles.button} onClick={() => onActiveChange(null)}>Sluit</button></div>
    </div>
  )
}

function Marquee({ title, layoutClassName }) {
  const marqueeContainerRef = React.useRef(null)
  const marqueeAreaRef = React.useRef(null)
  const [copyTime, setCopyTime] = React.useState(null)

  // Source for base: https://codepen.io/jico/pen/LYbvJKd
  React.useLayoutEffect(() => {
    const containerWidth = Math.floor(marqueeContainerRef.current.offsetWidth)
    const areaWidth = Math.floor(marqueeContainerRef.current.firstElementChild.offsetWidth)
    const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth))
    setCopyTime(copyTimes)
  },
  []
  )

  return (
    <div className={cx(styles.componentMarquee, layoutClassName)} ref={marqueeContainerRef}>
      <div className={styles.marqueeContentLayout} ref={marqueeAreaRef}><MarqueeContent {...{ title, copyTime }}  /></div>
      <div className={styles.marqueeContentLayout} ref={marqueeAreaRef}><MarqueeContent {...{ title, copyTime }}  /></div>
    </div>
  )
}

function MarqueeContent({ title, copyTime }) {
  return (
    <div className={cx(styles.componentMarqueeContent)}>
      {sequence(copyTime).map(n => <span key={n} className={styles.marqueeItem}>{title}</span>)}
    </div>
  )
}
