import { useKeenSlider } from 'keen-slider/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import  { animated, useSpring, config } from 'react-spring'

import mediaStyles from '/cssGlobal/media.css'

import styles from './Disciplines.css'

import iconConcept from '/components/aboutUs/assets/disciplines/concept.svg'
import iconDesign from '/components/aboutUs/assets/disciplines/design.svg'
import iconInventors from '/components/aboutUs/assets/disciplines/inventors.svg'
import iconMarketing from '/components/aboutUs/assets/disciplines/marketing.svg'
import iconStudio from '/components/aboutUs/assets/disciplines/studio.svg'
import iconTechnologie from '/components/aboutUs/assets/disciplines/technologie.svg'
import iconStrategie from '/components/aboutUs/assets/disciplines/strategie.svg'

const items = [
  {
    _id: 1,
    icon: iconStrategie,
    alt: 'Icon',
    title: 'Strategie',
    description: 'De strategen van Kaliber pellen het vraagstuk af, komen met onderscheidende inzichten en leggen zo de basis voor effectief en impactvol werk.',
    color: '#1dcbff'
  },
  {
    _id: 2,
    icon: iconConcept,
    alt: 'Icon',
    title: 'Concept',
    description: 'Voor de creatieve teams van Kaliber kan hét gouden idee elke vorm hebben. Alles kan. Als het maar onderscheidend, prikkelend en effectief is.',
    color: '#a8e536'
  },
  {
    _id: 3,
    icon: iconDesign,
    alt: 'Icon',
    title: 'Design',
    description: '"Maar, hoe brengen we het tot léven?" Dat is waar ons designteam  zich mee bezighoudt. Of het nu gaat over UX-, visual- of motion design.',
    color: '#ffe419'
  },
  {
    _id: 4,
    icon: iconTechnologie,
    alt: 'Icon',
    title: 'Technologie',
    description: 'Alles wat we bedenken, kunnen we ook maken. Met de developmentteams kunnen we toveren. Al vinden zijzelf het de normaalste zaak van de wereld.',
    color: '#ff6817'
  },
  {
    _id: 5,
    icon: iconMarketing,
    alt: 'Icon',
    title: 'Online Marketing',
    description: 'Alles wat we doen, moet een probleem oplossen. Online marketing is betrokken om de doelgroepen te vinden, te begrijpen en te raken.',
    color: '#1dcbff'
  },
  {
    _id: 6,
    icon: iconStudio,
    alt: 'Icon',
    title: 'Kaliber Studio',
    description: 'Wat krijg je als je concept, copy, beeld en design combineert? Studio maakt inspirerende content die merken verdieping geeft en mensen in beweging zet.',
    color: '#a8e536'
  },
  {
    _id: 7,
    icon: iconInventors,
    alt: 'Icon',
    title: 'Kaliber Inventors',
    description: 'Sommige vraagstukken kraak je alleen met een totaal andere aanpak. Innovatiestrategen en developers combineren ontwerp, code en sensoren voor grensverleggende oplossingen.',
    color: '#ffe419'
  }
]

export function Disciplines() {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const isViewportXl = useMediaQuery(mediaStyles.viewportXl)
  const wrapperRef = React.useRef(null)
  const cursorRef = React.useRef(null)
  const [progressProps, progressApi] = useSpring(() => ({ progress: 0 }))
  const [cursorProps, cursorSpringApi] = useSpring(() => ({ opacity: 0, scale: 0, x: 50, y: 50, config: config.gentle }))

  const [carouselRef] = useKeenSlider({
    slides: {
      perView: isViewportXl ? 4.2 : isViewportLg ? 3.2 : isViewportMd ? 2.2 : 1.2,
      spacing: 20,
    },
    selector: x => x.childNodes,
    detailsChanged: (x) => {
      progressApi.start({ progress: x.track.details.progress })
    }
  })

  return (
    <div className={styles.component}
      ref={wrapperRef}
      onPointerMove={handleMove}
      onPointerCancel={handleMouseLeave}
      onMouseLeave={handleMouseLeave}
    >
      <animated.div className={styles.cursorWrapper} ref={cursorRef} style={cursorProps}>
        <Cursor layoutClassName={styles.cursorLayout} />
      </animated.div>
      <div className={styles.headingWrapper}>
        <animated.h2
          className={styles.heading}
          style={{ transform: progressProps.progress.to(value => isViewportMd && `translateX(${value * -100}%)`) }}
        >
          Diensten van Kaliber
        </animated.h2>
      </div>
      <div
        className={styles.carouselWrapper}
      >
        <ul className={styles.carousel} ref={carouselRef}>
          {items.map((x) => {
            return (
              <li className={styles.carouselItem} key={x._id} style={{ backgroundColor: x.color }}>
                <Card
                  title={x.title}
                  description={x.description}
                  icon={x.icon}
                  alt={x.alt}
                  layoutClassName={styles.cardLayout}
                />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )

  function handleMove(e) {
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const cursorRect = cursorRef.current.getBoundingClientRect()
    const x = e.clientX - wrapperRect.x - cursorRect.width / 2
    const y = e.clientY - wrapperRect.y - cursorRect.height / 2
    cursorSpringApi.start({  opacity: 1, scale: 1, x, y })
  }

  function handleMouseLeave() {
    cursorSpringApi.start({ opacity: 0 })
  }
}

function Cursor({ layoutClassName }) {
  return (
    <div className={cx(styles.componentCursor, layoutClassName)}>
      Drag
    </div>
  )
}

function Card({ title, description, icon, alt, layoutClassName }) {
  return (
    <div className={cx(styles.componentCard, layoutClassName)}>
      <div className={styles.content}>
        <strong className={styles.title}>{title}</strong>
        <div className={styles.description}>{description}</div>
      </div>
      <img className={styles.icon} src={icon} {...{ alt }} loading="lazy" />
    </div>
  )
}
