import { CenteredContainer } from '/components/pageOnly/buildingBlocks/CenteredContainer'
import { PageHeader, imageSizeMap } from '/components/pageOnly/buildingBlocks/PageHeader'
import { HeadingCompactXxl } from '/components/buildingBlocks/Heading'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'
import { useViewport } from '/machinery/Viewport'
import { validateSrcs } from '/machinery/validateSrcs'
import styles from './Header.css'

const check = Object.keys(imageSizeMap).reduce(
  (res, x) => {
    res[x] = { maxHeight: x }
    return res
  },
  {}
)

export function Header({ children, srcs, alt, imageIsLight = undefined }) {
  validateSrcs(srcs, check)
  const { size } = useViewport()
  return (
    <PageHeader componentMedia={() => <ImageObjectFit src={srcs[size]} {...{ alt }} />} isLight={imageIsLight}>
      <CenteredContainer width='md'>{children}</CenteredContainer>
    </PageHeader>
  )
}

export function HeaderTitle({ children }) {
  return <div className={styles.componentTitle}><HeadingCompactXxl>{children}</HeadingCompactXxl></div>
}

export function HeaderParagraph({ children }) {
  return <div className={styles.componentParagraph}><p>{children}</p></div>
}
