import { useSpring, animated } from '@react-spring/web'
import { routes, determineDocumentPath } from '/routes'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useSetDocumentTitle } from '/machinery/useSetDocumentTitle'
import { usePageRouteData } from '/machinery/PageRouteData'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'
import { Marquee } from '/components/buildingBlocks/marquee/Marquee'
import { CustomCursor } from '/components/buildingBlocks/CustomCursor'
import { useSanityClient } from '/machinery/SanityClient'
import { ContainerDark } from '/components/home/Container'
import { Section } from '/components/home/pageOnly/Section'
import { PageLayoutPitchCases } from '/components/pageOnly/PageLayout'
import { Header } from '/components/cases/pageOnly/Header'
import { PasswordProtectedPage } from '/components/pageOnly/PasswordProtectedPage'
import { getPreviewForOverviewType } from '/machinery/groqSnippets'
import { ImageObjectFit } from '/components/buildingBlocks/ImageObjectFit'
import { ContentContainer } from '/components/home/pageOnly/ContentContainer'
import groq from 'groq'

import mediaStyles from '/cssGlobal/media.css'

import styles from './PitchCases.css'

const query = groq`
  ${getPreviewForOverviewType('pitchCase')} | order(documentOrder) {
    'slug': slug.current,
    'id': _id,
    'client': header.client,
    'image': header.headerImage {..., asset->},
    'relevances': header.relevances,
    title,
  }
`

PitchCases.meta = {
  title: 'Cases',
  description: '',
  async fetchData(sanityClient, { isPreview = false }) {
    const cases = await sanityClient.fetch(query, { isPreview })
    return {
      data: { cases },
      status: 200,
    }
  },
}

export function PitchCases() {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { isLoading, isError, data } = usePageRouteData()

  useSetDocumentTitle(PitchCases.meta.title)

  const { cases = [] } = data || {}

  const CasesComponent = isViewportMd ? CasesDesktop : CasesMobile

  const items = ['overtuigen', 'prikkelen', 'inspireren', 'verrassen', 'raken']
  const doubledItems = [...items, ...items]

  return (
    <PasswordProtectedPage>
      <PageLayoutPitchCases>
        <ContainerDark>
          <Header>
            <Marquee
              title='cases die je'
              items={doubledItems}
              animationSpeed='80s'
            />
            <ContentContainer width='lg'>
              <p className={styles.introText}>
                Voor de STL-pitch hebben we cases geselecteerd die laten zien hoe we vergelijkbare uitdagingen aanpakken. Lees hoe we strategie, creativiteit en technologie combineren om mensen iets te laten begrijpen, voelen en doen.
              </p>
            </ContentContainer>
          </Header>
        </ContainerDark>
        <ContainerDark layoutClassName={styles.container}>
          <Section backgroundColor='black' color='white'>
            <div className={styles.casesContainer}>
              {isLoading && <p>Laden...</p>}
              {isError && <p>Er is een fout opgetreden</p>}
              {data && cases.length === 0 && <p>Geen resultaten</p>}
              {data && (
                <CasesComponent {...{ cases }} />
              )}
            </div>
            <article />
          </Section>
        </ContainerDark>
      </PageLayoutPitchCases>
    </PasswordProtectedPage>
  )
}

function CaseLandscape({ image, client, title, slug, relevances, layoutClassName = undefined }) {
  return (
    <CaseBase
      {...{ image, client, title, slug, relevances }}
      aspectRatio='landscape'
      className={cx(styles.componentCaseLandscape, layoutClassName)}
    />
  )
}

function CasePortrait({ image, client, title, slug, relevances, layoutClassName = undefined }) {
  return (
    <CaseBase
      {...{ image, client, relevances, title, slug }}
      aspectRatio='portrait'
      className={cx(styles.componentCasePortrait, layoutClassName)}
    />
  )
}

function CasesDesktop({ cases }) {
  const { firstList, secondList } = splitArrayByOrder(cases || [])

  const getDisplayType = (index) => {
    const positionInBlock = index % 4
    return positionInBlock < 3 ? 'landscape' : 'portrait'
  }

  return (
    <ul className={styles.componentDesktop}>
      {[...firstList, ...secondList].map(({ id, slug, title, client, image, relevances }, index) => {
        const displayType = getDisplayType(index)

        return (
          <li key={id} className={styles.case}>
            {displayType === 'landscape'
              ? <CaseLandscape {...{ slug, title, client, image, relevances }} />
              : <CasePortrait {...{ slug, title, client, image, relevances }} />
            }
          </li>
        )
      })}
    </ul>
  )
}

function CasesMobile({ cases }) {
  return (
    <ul className={styles.componentMobile}>
      {cases.map(({ id, slug, title, client, image }, index) => {
        return (
          <li key={id} className={styles.case}>
            <CaseLandscape key={id} {...{ slug, title, client, image }} />
          </li>
        )
      })}
    </ul>
  )
}

function CaseBase({ image, client, title, slug, className, aspectRatio, relevances }) {
  const { ref: elementRef, wasInViewport } = useWasInViewport({ threshold: [0.1] })
  const link = determineDocumentPath({ document: { _type: 'pitchCase', slug: { current: slug } }, routes })
  const hasImage = image?.asset?.url

  const contentSpring = useSpring({
    to: {
      opacity: wasInViewport ? 1 : 0,
    },
    delay: 400,
    config: { tension: 75, friction: 40 }
  })

  const imageSpring = useSpring({
    to: {
      transform: wasInViewport ? 'scale(1)' : 'scale(1.1)',
      opacity: wasInViewport ? 1 : 0,
      clipPath: wasInViewport ? 'inset(0% 0% 0% 0%)' : 'inset(50% 0% 0% 0%)'
    },
    config: { tension: 100, friction: 35 }
  })

  return (
    <CustomCursor>
      <a href={link} className={cx(styles.componentCaseBase, className, wasInViewport && styles.isInView)} ref={elementRef}>
        {hasImage && <animated.div style={imageSpring} className={styles.imageContainer}><CaseImage {...{ image, aspectRatio, relevances }} layoutClassName={styles.imageLayout} /></animated.div>}
        <div className={styles.content}>
          <animated.div style={contentSpring} className={styles.client}>{client}</animated.div>
          <animated.div style={contentSpring} className={styles.title}>
            {title}
          </animated.div>
        </div>
      </a>
    </CustomCursor>
  )
}

function CaseImage({ image, aspectRatio, relevances, layoutClassName = undefined }) {
  const { imageBuilder } = useSanityClient()

  const labelColors = {
    'Sectorgerelateerd': 'var(--color-green)',
    'Succesvolle wervingscampagne': 'var(--color-pink)',
    'Merkpositionering binnen sector': 'var(--color-blue)',
    'Omnichannel': 'var(--color-orange)',
    'Sterk visueel concept': 'var(--color-yellow)',
    'Contentmarketing': 'var(--color-soft-orange)',
    'Succesvolle conversies': 'var(--color-soft-blue)',
    'Digitale tools': 'var(--color-soft-green)'
  }

  const src = image
    ? aspectRatio === 'landscape'
      ? imageBuilder.image(image).width(700).height(500).url()
      : imageBuilder.image(image).width(700).height(1200).url()
    : null

  return (
    <div className={cx(styles.componentCaseImage, layoutClassName)}>
      <ImageObjectFit
        layoutClassName={styles.imageLayout}
        {...{ src, labelColors }}
        labelText={relevances}
        alt="Image with multiple labels"
      />
    </div>
  )
}

function splitArrayByOrder(items) {
  let firstList = []
  let secondList = []

  if (items.length > 0) {
    firstList = items.filter((_, index) => index % 2 === 0)
    secondList = items.filter((_, index) => index % 2 !== 0)
  }

  return { firstList, secondList }
}
