import { HeadingCompactXl } from '/components/buildingBlocks/Heading'
import { VideoTommy } from '/components/buildingBlocks/Video'
import styles from './Tommy.css'

export function Tommy({ children }) {
  return (
    <div className={styles.component}>
      <div className={styles.video}><VideoTommy /></div>
      <div className={styles.content}>
        <HeadingCompactXl>Tommy is <br />de baas</HeadingCompactXl>
        {children}
      </div>
    </div>
  )
}
