import { useSanityClient } from '/machinery/SanityClient'
import { VideoVimeo } from '/components/buildingBlocks/Video'
import { BlockContent } from '/components/buildingBlocks/BlockContent'
import { Image } from '/components/Image'

import styles from './TextWithMedia.css'

export function TextWithMediaVideo({ alignment, content, poster, caption, sources }) {
  const { imageBuilder } = useSanityClient()

  return (
    <TextWithMediaBase {...{ alignment, content, caption }}>
      <figure className={styles.componentVideo}>
        <VideoVimeo
          layoutClassName={styles.mediaTypeElement}
          loop
          autoPlay
          ratio={1 / 1}
          poster={poster ? imageBuilder.image(poster).width(800).height(800).url() : null}
          {... { sources }}
        />
        <figcaption className={styles.caption}>{caption}</figcaption>
      </figure>
    </TextWithMediaBase>
  )
}

export function TextWithMediaImage({ alignment, content, image, caption }) {
  const { imageBuilder } = useSanityClient()
  return (
    <TextWithMediaBase {...{ alignment, content, caption }}>
      <figure className={styles.componentImage}>
        <Image
          layoutClassName={styles.mediaTypeElement}
          builder={imageBuilder}
          aspectRatio={1 / 1}
          {...{ image }}
        />
        <figcaption className={styles.caption}>{caption}</figcaption>
      </figure>
    </TextWithMediaBase>
  )
}

function TextWithMediaBase({ alignment, content, children }) {
  return (
    <div className={cx(
      styles.componentBase,
      alignment === 'left' && styles.left,
      alignment === 'right' && styles.right
    )}>
      {children}
      <div className={styles.splitBlockContent}>
        <BlockContent blocks={content} />
      </div>
    </div>
  )
}
